import React, { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import AddIcon from "@material-ui/icons/Add";
import {
    Button,
    TextField,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Menu,
    List,
    ListItem,
    Typography,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TablePagination,
    Chip,
} from "@material-ui/core";
import { Add, Clear } from "@material-ui/icons";
import axios from "axios";
import { ocrTypes } from "../../constants";
import { useSnackbar } from "notistack";

const ColorButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(grey[900]),
        backgroundColor: grey[900],
        "&:hover": {
            backgroundColor: grey[900],
        },
        marginLeft: 30,
        marginTop: 20,
        height: 50,
    },
}))(Button);

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        marginBottom: 100,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginTop: 12,
    },
    cards: {
        marginTop: 50,
        marginLeft: 25
    },
});
function Home(props) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [groups, setGroups] = useState([]);
    const [name, setName] = useState("");
    const [type, setType] = useState("card");
    const [classificationType, setClassificationType] = useState("image");
    const [ocr, setOcr] = useState("hv");
    const [translate, setTranslate] = useState(false);
    const [sorting, setSorting] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [openNext, setOpenNext] = useState(false);
    const [projects, setProjects] = useState([]);
    const [selectedProjects, setSelectedProjects] = useState([]);
    const [search, setSearch] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [tags, setTags] = useState({
        Untagged: {
            selected: false,
            count: 0,
        },
    });

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [newTagName, setNewTagName] = useState("");
    const [selectedGroupssForTags, setSelectedGroupsForTags] = useState([]);
    const [searchForTags, setSearchForTags] = React.useState('');
    const [pageForTags, setPageForTags] = React.useState(0);
    const [rowsPerPageForTags, setRowsPerPageForTags] = React.useState(10);
    const [openNewTagDialogForTags, setOpenNewTagDialogForTags] = useState(false);


    const filteredGroupsForTags = groups.filter(groups =>
        groups.name.toLowerCase().includes(searchForTags.toLowerCase()) || groups._id.toLowerCase().includes(searchForTags.toLowerCase())
    );

    const handleProjectSelectForTags = (e, id) => {
        if (e.target.checked) {
            setSelectedGroupsForTags([...selectedGroupssForTags, id]);
        } else {
            setSelectedGroupsForTags(selectedGroupssForTags.filter((item) => item !== id));
        }
    };

    // Handle change page
    const handleChangePageForTags = (event, newPage) => {
        setPageForTags(newPage);
    };

    // Handle change rows per page
    const handleChangeRowsPerPageForTags = event => {
        setRowsPerPageForTags(parseInt(event.target.value, 10));
        setPageForTags(0);
    };


    // Filter the projects based on the search input
    const filteredProjects = projects.filter(project =>
        project.name.toLowerCase().includes(search.toLowerCase()) || project._id.toLowerCase().includes(search.toLowerCase())
    );

    // Handle change page
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Handle change rows per page
    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const processGroupData = (groups) => {
        if (!groups) return;
        if (groups.length === 0) return;

        const TAGS = {
            Untagged: {
                selected: false,
                count: 0,
            },
        };

        for (let i = 0; i < groups.length; i++) {
            const group = groups[i];
            if (!group.tags) continue;
            for (let j = 0; j < group.tags.length; j++) {
                if (TAGS[group.tags[j]]) {
                    TAGS[group.tags[j]].count += 1;
                } else {
                    TAGS[group.tags[j]] = {
                        selected: false,
                        count: 1,
                    }
                }
            }
            if (group.tags.length === 0) {
                TAGS["Untagged"].count += 1;
            }
        }
        setTags(TAGS);
    }

    const getGroups = () => {
        axios
            .get("/api/groups/list")
            .then((data) => {
                processGroupData(data.data);
                setGroups(data.data)
            })
            .catch((e) => {
                console.log(e);
            });
    };

    useEffect(() => {
        getGroups();
    }, []);

    const createGroup = () => {
        const data = {
            name,
            type,
            classificationType,
            status: "created",
            ocr,
            translate,
            sorting,
            projects: selectedProjects,
        };
        console.log('newGroup', data);
        axios
            .post("/api/groups/create", data)
            .then((res) => {
                enqueueSnackbar("Group created successfully", {
                    variant: "success",
                });
                setGroups([])
                setName('')
                setType('')
                setClassificationType('')
                setOcr('hv')
                setTranslate(false)
                setSorting(false)
                setOpenNext(false)
                getGroups();
            })
            .catch((e) => {
                enqueueSnackbar(e.response.data, {
                    variant: "error",
                });
            });
    };
    const handleName = (e) => {
        setName(e.target.value);
    };

    const handleType = (e) => {
        setType(e.target.value);
        setSelectedProjects([]);
    };

    const handleClassificationType = (e) => {
        setClassificationType(e.target.value);
    }

    const handleOcr = (e) => {
        setOcr(e.target.value);
    }

    const handleTranslate = (e) => {
        setTranslate(e.target.checked)
    }

    const handleSorting = (e) => {
        setSorting(e.target.checked)
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false)
        setOpenNext(false)
    };

    const handleBack = () => {
        setOpen(true);
        setOpenNext(false);
    };

    const handleNext = () => {
        getProjects();
        setOpen(false);
        setOpenNext(true);
    };

    const addTagToGroups = (groupIds, tag) => {
        if (groupIds.length === 0) {
            enqueueSnackbar("No groups selected", {
                variant: "error",
            });
            return;
        }
        if (!tag || tag === "") {
            enqueueSnackbar("No tag selected", {
                variant: "error",
            });
            return;
        }
        axios
            .post("/api/groups/addTagToGroups", {
                groupIds,
                tag,
            })
            .then((res) => {
                enqueueSnackbar(res.data, {
                    variant: "success",
                });
                getProjects();
            })
            .catch((e) => {
                enqueueSnackbar(e.response.data, {
                    variant: "error",
                });
            });
    };

    const getProjects = () => {
        axios
            .get("/api/projects/list?query=all")
            .then((data) => {
                const projects = data.data.filter((project) => project.type === type);
                setProjects(projects);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const handleProjectSelect = (e, id) => {
        if (e.target.checked) {
            setSelectedProjects([...selectedProjects, id]);
        } else {
            setSelectedProjects(selectedProjects.filter((item) => item !== id));
        }
    };

    const handleClick = (name, docType, groupName, tags) => {
        props.history.push({
            pathname: '/group',
            state: {
                group: name,
                type: docType,
                groupName,
                tags
            }
        })
    };

    return (
        <div>
            <ColorButton
                startIcon={<AddIcon />}
                onClick={handleClickOpen}
                variant="contained"
                color="primary"
            >
                Create New Group
            </ColorButton>
            <ColorButton
                startIcon={<Add />}
                onClick={() => setOpenNewTagDialogForTags(true)}
                variant="contained"
                color="primary"
            >
                Add tag to Groups
            </ColorButton>
            <div
                style={{
                    fontSize: 30,
                    fontWeight: "bold",
                    paddingLeft: 30,
                    paddingTop: 30,
                }}
            >
                Existing Groups
            </div>
            <div
                style={{
                    fontSize: 25,
                    fontWeight: "bold",
                    paddingLeft: 30,
                    paddingTop: 30,
                    justifyContent: 'left',
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <span style={
                    {
                        fontSize: 30,
                        fontWeight: "bold",
                    }
                }>All Tags</span>
                <Clear
                    onClick={() => {
                        const newTags = { ...tags };
                        Object.keys(newTags).forEach((tag) => {
                            newTags[tag].selected = false;
                        });
                        setTags(newTags);
                    }}
                    style={{ marginLeft: 20, cursor: 'pointer' }}
                />
            </div>

            <div style={{ paddingLeft: 30, marginTop: 15 }}>
                {Object.keys(tags).map((tag) => (
                    <Chip
                        label={`${tag} (${tags[tag].count})`}
                        clickable
                        color={tags[tag].selected ? "primary" : "default"}
                        onClick={() => {
                            const newTags = { ...tags };
                            newTags[tag].selected = !newTags[tag].selected;
                            setTags(newTags);
                        }}
                        style={{ margin: 5 }}
                    />
                ))}
            </div>

            <div
                style={{
                    fontSize: 25,
                    fontWeight: "bold",
                    paddingLeft: 30,
                    paddingTop: 30,
                }}
            >
                All Groups
            </div>
            <div style={{ paddingLeft: 15, paddingRight: 15, maxWidth: '100%', alignContent: 'center', alignItems: 'center' }}>
                <List className={classes.list}>
                    {groups.length === 0
                        ? "No Existing Groups"
                        : (
                            <Grid container spacing={2}>
                                {groups.map((group, index) => (
                                    (Object.keys(tags).filter((tag) => tags[tag].selected).length === 0 || group.tags.some((tag) => tags[tag].selected) || (group.tags.length === 0 && tags["Untagged"].selected)) &&
                                    <Grid item xs={12} sm={6} key={index}>

                                        <ListItem key={index} divider>
                                            <div className={classes.projectInfo}  style={{ width: '80%' }}>
                                                <Typography variant="h5" component="h2" style={{ cursor: 'pointer' }} onClick={() => handleClick(group._id, group.type, group.name, group.tags)}>
                                                    {group.name}
                                                </Typography>
                                                {(group.tags.length !== 0 && <Typography variant="body2" className={classes.secondaryText}>
                                                    Tags {group.tags && group.tags.map((tag) => (
                                                        <Chip
                                                            key={tag}
                                                            label={tag}
                                                            clickable
                                                            size="small"
                                                            onClick={() => {
                                                                const newTags = { ...tags };
                                                                newTags[tag].selected = !newTags[tag].selected;
                                                                setTags(newTags);
                                                            }}
                                                            style={{ margin: 3, padding: 0 }}
                                                        />
                                                    ))}
                                                </Typography>)}
                                                <div style={{ display: "flex", alignItems: 'flex-start' }}>
                                                    <Typography variant="body2" className={classes.secondaryText} color="textSecondary">
                                                        {group.type === 'card' ? "CARD" : group.type === 'passport' ? "PASSPORT" : "DOCUMENT"} | {group.classificationType} | {group.ocr}
                                                    </Typography>
                                                </div>

                                                <div style={{ display: "flex", alignItems: 'flex-start' }}>
                                                    <Typography variant="body2" className={classes.secondaryText} color="textSecondary" style={{ cursor: 'pointer' }} onClick={() => {
                                                        enqueueSnackbar(`${group._id} copied to clipboard`, {
                                                            variant: "success",
                                                            autoHideDuration: 800,
                                                        });
                                                        navigator.clipboard.writeText(group._id);
                                                    }}>
                                                        {group._id}
                                                    </Typography>
                                                </div>
                                            </div>
                                            <div className={classes.projectInfo} style={{ width: '20%' }}>
                                                <Button
                                                    size="small"
                                                    onCl
                                                    fullWidth
                                                    style={{ border: '1px solid #000', marginBottom: 10 }}
                                                    onClick={() => handleClick(group._id, group.type, group.name, group.tags)}
                                                >
                                                    view group
                                                </Button>
                                            </div>
                                        </ListItem>
                                    </Grid>
                                ))}
                            </Grid>
                        )
                    }
                </List>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="create-group"
                maxWidth='md'
            >
                <DialogTitle id="create-group">Create new group</DialogTitle>
                <DialogContent>
                    <TextField
                        required
                        autoFocus
                        margin="normal"
                        id="name"
                        label="Group Name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        onChange={handleName}
                        value={name}
                    />
                    <FormControl variant="outlined" style={{ marginTop: 8 }}>
                        <InputLabel id="select-type">Type</InputLabel>
                        <Select
                            labelId="select-type"
                            id="select-type-id"
                            value={type}
                            onChange={handleType}
                            label="Type"
                            style={{ height: 50, width: 150 }}
                        >
                            <MenuItem value="card">Card</MenuItem>
                            <MenuItem value="doc">Document</MenuItem>
                            <MenuItem value="passport">Passport</MenuItem>
                        </Select>
                    </FormControl>
                    <br />
                    <FormControl variant="outlined" style={{ marginTop: 8 }}>
                        <InputLabel id="select-classification">Classification</InputLabel>
                        <Select
                            labelId="select-classification"
                            id="select-classification-id"
                            value={classificationType}
                            onChange={handleClassificationType}
                            label="Classification"
                            style={{ height: 50, width: 150 }}
                        >
                            <MenuItem value="text">Text</MenuItem>
                            <MenuItem value="keyword">Keyword</MenuItem>
                            <MenuItem value="image">Image</MenuItem>
                        </Select>
                    </FormControl>
                    <br />
                    <FormControl variant="outlined" style={{ marginTop: 8 }}>
                        <InputLabel id="select-ocr">OCR Type</InputLabel>
                        <Select
                            labelId="select-ocr"
                            id="select-ocr-id"
                            value={ocr}
                            onChange={handleOcr}
                            label="OCR Type"
                            style={{ height: 50, width: 150 }}
                        >
                            {Object.keys(ocrTypes).map((ocrType) => (
                                <MenuItem value={ocrType}>{ocrTypes[ocrType]}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <br />
                    <div>
                        <Checkbox
                            checked={translate}
                            onChange={handleTranslate}
                            inputProps={{ "aria-label": "primary checkbox" }}
                            required
                        />
                        <span>Enable Translation</span>
                    </div>
                    <div>
                        <Checkbox
                            checked={sorting}
                            onChange={handleSorting}
                            inputProps={{ "aria-label": "primary checkbox" }}
                            required
                        />
                        <span>Enable Sorting</span>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleNext} variant="contained" color="default">
                        Next
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openNext}
                onClose={handleClose}
                aria-labelledby="create-group-projects"
                fullWidth={true}
                maxWidth='md'
            >
                <DialogTitle id="add-projects">Add projects</DialogTitle>
                <TextField
                    label="Search"
                    variant="outlined"
                    value={search}
                    style={{ marginLeft: 25, marginRight: 25 }}
                    onChange={e => setSearch(e.target.value)}
                />
                <div style={{ marginLeft: 25, marginTop: 20 }}>
                    {selectedProjects.map((project, index) => (
                        <Chip key={index} label={project} title={project} style={{ marginRight: 10, marginTop: 10 }} />
                    ))}
                </div>
                <DialogContent>
                    <Table className={classes.table} aria-label="simple table">
                        <TableBody>
                            {filteredProjects.length === 0
                                ? "No Projects Found"
                                : filteredProjects.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((project, index) => (
                                    <TableRow key={index}>
                                        <TableCell component="th" scope="row">
                                            <Typography>
                                                {project.name}
                                            </Typography>
                                            <Typography className={classes.pos} color="textSecondary">
                                                Id: {project._id}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Checkbox
                                                checked={selectedProjects.includes(project._id)}
                                                onChange={(e) => handleProjectSelect(e, project._id)}
                                                inputProps={{ "aria-label": "primary checkbox" }}
                                                required
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        component="div"
                        count={filteredProjects.length}
                        page={page}
                        onChangePage={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleBack} variant="contained" color="primary">
                        Back
                    </Button>
                    <Button onClick={createGroup} variant="contained" color="default">
                        Create
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openNewTagDialogForTags}
                onClose={() => setOpenNewTagDialogForTags(false)}
                aria-labelledby="create-group-projects"
                fullWidth={true}
                maxWidth='md'
            >
                <DialogTitle id="add-group-tag">Select groups</DialogTitle>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'start',
                    marginLeft: 25,
                    marginBottom: 20
                }}>
                    <Button aria-controls="simple-menu" aria-haspopup="true" onClick={(event) => setAnchorEl(event.currentTarget)}>
                        Select existing tag
                    </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={(event) => setAnchorEl(null)}
                    >
                        {Object.keys(tags).filter((tag) => tag !== "Untagged").map((tag) => (
                            <MenuItem
                                onClick={() => {
                                    setNewTagName(tag);
                                    setAnchorEl(null);
                                }}
                            >
                                {tag}
                            </MenuItem>
                        ))}
                    </Menu>
                    <TextField
                        required
                        margin="normal"
                        id="tag"
                        label="Enter a new Tag"
                        type="text"
                        variant="outlined"
                        value={newTagName}
                        onChange={(e) => setNewTagName(e.target.value)}
                        style={{ marginLeft: 25, marginRight: 25 }}
                    />
                    {
                        newTagName && !Object.keys(tags).includes(newTagName) ? "(New Tag)" : ""
                    }
                </div>
                <TextField
                    label="Search"
                    variant="outlined"
                    value={searchForTags}
                    style={{ marginLeft: 25, marginRight: 25 }}
                    onChange={e => setSearchForTags(e.target.value)}
                />
                <div style={{ marginLeft: 25, marginTop: 20 }}>
                    {selectedGroupssForTags.map((group, index) => (
                        <Chip key={index} label={group} title={group} style={{ marginRight: 10, marginTop: 10 }} />
                    ))}
                </div>
                <DialogContent>
                    <Table className={classes.table} aria-label="simple table">
                        <TableBody>
                            {filteredGroupsForTags.length === 0
                                ? "No Groups Found"
                                : filteredGroupsForTags.slice(pageForTags * rowsPerPageForTags, pageForTags * rowsPerPageForTags + rowsPerPageForTags).map((group, index) => (
                                    <TableRow key={index}>
                                        <TableCell component="th" scope="row">
                                            <Typography>
                                                {group.name}
                                            </Typography>
                                            <Typography className={classes.pos} color="textSecondary">
                                                Id: {group._id}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Checkbox
                                                checked={selectedGroupssForTags.includes(group._id)}
                                                onChange={(e) => handleProjectSelectForTags(e, group._id)}
                                                inputProps={{ "aria-label": "primary checkbox" }}
                                                required
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        component="div"
                        count={filteredGroupsForTags.length}
                        page={pageForTags}
                        onChangePage={handleChangePageForTags}
                        rowsPerPage={rowsPerPageForTags}
                        onChangeRowsPerPage={handleChangeRowsPerPageForTags}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOpenNewTagDialogForTags(false) }} variant="contained" color="secondary">
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            addTagToGroups(selectedGroupssForTags, newTagName);
                            setOpenNewTagDialogForTags(false);
                            setNewTagName("");
                            setSelectedGroupsForTags([]);
                            setSearchForTags("");
                        }}
                        className="btn btn-primary"
                    >Add</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default Home
