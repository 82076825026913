import React, { Fragment, useEffect, useState } from "react";
import { createBrowserHistory } from "history";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Button,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";

import { logoutUser } from "../../actions/authActions";

import Home from "../pages/Home";
import Group from "../pages/Group";
import Project from "../pages/Project";
import Management from "../pages/Management";
import Tasks from "../pages/Tasks";
import Label from "../pages/Annotate";
import ViewProjectAnnotation from "../pages/ViewProjectAnnotation";
import Test from "../pages/Test";

import OpenBenchmark from '../benchmark/OpenBenchmark';
import BenchmarkList from '../benchmark/BenchmarkList';
import Annotate from './../benchmark/Annotate';
import ViewAnnotation from '../benchmark/ViewAnnotation';
import QcList from '../benchmark/QcList';
import QcView from '../benchmark/QcView';
import ViewResponses from '../benchmark/ViewResponses';

import Classification from '../pages/Classification';
import ClassificationProject from '../pages/ClassificationProject';
import ClassificationTest from '../pages/ClassificationTest';
import { regions, getSelectedRegion } from "../../constants";

const history = createBrowserHistory();

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: "black",
    color: "white",
  },
  logout: {
    background: "white",
    borderRadius: 3,
    border: 0,
    color: "black",
    height: 35,
    padding: "0 30px",
    marginLeft: 20,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    minHeight: "100px",
  },
  formControl: {
    marginLeft: "auto",
    marginRight: 20,
    background: "white",
    borderRadius: 3,
    minWidth: 150,
    height: 35,
  },
  select: {
    height: "100%",
  },
  selectInput: {
    padding: "8px 14px",
  },
});

const MyToolbar = withStyles(styles)(({ classes, onLogoutClick, region, handleRegionChange }) => (
  <Fragment>
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <a href="/">
          <img src="/hv_logo.png" alt="logo" height="40" />
        </a>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            value={region}
            onChange={handleRegionChange}
            label="Region"
            className={classes.select}
            inputProps={{ className: classes.selectInput }}
          >
            {Object.keys(regions).map((key) => (
              <MenuItem key={key} value={key}>
                {regions[key]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          className={classes.logout}
          onClick={() => {
            history.push("/management");
            window.location.href = "/management";
          }}
        >
          Management
        </Button>
        <Button
          variant="contained"
          className={classes.logout}
          onClick={() => {
            history.push("/tasks");
            window.location.href = "/tasks";
          }}
        >
          Tasks
        </Button>
        <Button
          variant="contained"
          className={classes.logout}
          onClick={onLogoutClick}
        >
          Logout
        </Button>
      </Toolbar>
    </AppBar>
    <div className={classes.toolbar} />
  </Fragment>
));

function Dashboard(props) {
  const [region, setRegion] = useState("ind");

  useEffect(() => {
    setRegion(getSelectedRegion());
  }, []);

  useEffect(() => {
    if (!props.auth.isAuthenticated) {
      props.history.push("/login");
    }
  }, [props]);

  const handleRegionChange = (event) => {
    const newRegion = event.target.value;
    localStorage.setItem('REGION', newRegion);
    axios.defaults.baseURL = `https://${newRegion}-turing.hyperverge.co`;
    window.location.href = "/";
  };

  const onLogoutClick = (e) => {
    e.preventDefault();
    history.replace("/");
    props.logoutUser();
  };

  const classes = styles();

  return (
    <div className={classes.root}>
      <MyToolbar
        onLogoutClick={onLogoutClick}
        region={region}
        handleRegionChange={handleRegionChange}
      />
      <Switch>
        <Route exact path={`/`} component={Home} />
        <Route exact path={`/group`} component={Group} />
        <Route exact path={`/management`} component={Management} />
        <Route exact path={`/tasks`} component={Tasks} />
        <Route exact path={`/classification`} component={Classification} />
        <Route exact path={`/classification/project`} component={ClassificationProject} />
        <Route exact path={`/classification/test`} component={ClassificationTest} />

        <Route exact path={`/project`} component={Project} />
        <Route exact path={`/annotate`} component={Label} />
        <Route exact path={`/viewAnnotation`} component={ViewProjectAnnotation} />
        <Route exact path={`/test`} component={Test} />

        <Route exact path={`/project/benchmarks`} component={BenchmarkList} />
        <Route exact path={`/group/benchmarks`} component={BenchmarkList} />
        <Route exact path={`/benchmark`} component={OpenBenchmark} />
        <Route exact path={`/benchmark/viewResponse`} component={ViewResponses} />
        <Route exact path={`/benchmark/viewAnnotation`} component={ViewAnnotation} />
        <Route exact path={`/benchmark/annotate`} component={Annotate} />
        <Route exact path={`/benchmark/qc/list`} component={QcList} />
        <Route exact path={`/benchmark/qc/view`} component={QcView} />
      </Switch>
    </div>
  );
}

Dashboard.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(Dashboard);
