import React, { useState } from 'react'
import { Redirect } from "react-router-dom";
import axios from "axios";
import { useSnackbar } from "notistack";
import Grid from "@material-ui/core/Grid";
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { grey } from "@material-ui/core/colors";
import ImageZoom from 'react-image-zoom-rotate';
import ReactJson from 'react-json-view';
import FormData from 'form-data';

const ColorButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(grey[900]),
        backgroundColor: grey[900],
        "&:hover": {
            backgroundColor: grey[900],
        },
        height: 50,
        minWidth: 150,
        marginTop: 20
    },
}))(Button);

function TestClassifier(props) {
    const { enqueueSnackbar } = useSnackbar();
    const [files, setFile] = useState('');
    const [apiResponse, setApiResponse] = useState();
    const [processing, setProcessing] = useState(false)
    const [dataAvailable, setdataAvailable] = useState(false)
    const [fileURL, setFileURL] = useState('')

    const onChange = e => {
        setFile(e.target.files);
        setFileURL(URL.createObjectURL(e.target.files[0]))
    };

    const onSubmit = async e => {
        e.preventDefault();
        setProcessing(true)
        try {
            let data = new FormData();
            data.append('image', files[0])
            data.append('group', props.location.state.group);
            const response = await axios.post('/api/classification/test', data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (response.data) {
                console.log(response.data)
                setApiResponse(response.data)
                setProcessing(false)
                setdataAvailable(true)
            }
        } catch (error) {
            console.log(error)
            setApiResponse(error.response.data)
            setProcessing(false)
            setdataAvailable(false)
            enqueueSnackbar(error.response.data.error, {
                variant: "error",
            });
        }
    };

    const { state } = props.location
    if (state === undefined) {
        return <Redirect to="/" />
    }
    return (
        <div style={{ marginLeft: 50 }}>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <h1>Test Model</h1>
                    <br></br>
                    <ImageZoom
                        rotate={(clockwise) => {
                            return (
                                <div>
                                    <Button onClick={clockwise} disabled={files.length === 0} style={{ position: 'absolute', right: 0, zIndex: 1 }}>
                                        {files.length === 0 ? '' : '↻'}
                                    </Button>
                                </div>
                            );
                        }}
                        src={fileURL}
                        width={650}
                        zoomWidth={500}
                    />
                    <br></br>
                    <div>
                        <label htmlFor='customFile'>
                            <div style={{ maxHeight: 50, borderRadius: 4, maxWidth: 146, padding: 1, textAlign: 'center', cursor: 'pointer', border: '1px solid black' }}>
                                <p style={{ fontWeight: 500, fontSize: '0.875em', marginTop: 16, marginBottom: 16 }}>CHOOSE FILE</p>
                            </div>
                        </label>
                        <input
                            type='file'
                            id='customFile'
                            onChange={onChange}
                            style={{ display: 'none' }}
                        />
                    </div>
                    <ColorButton
                        onClick={onSubmit}
                        variant="contained"
                        color="primary"
                        disabled={processing || files.length === 0}
                    >Process
                    </ColorButton><br /><br />
                    {dataAvailable && apiResponse.result[0].ocr ? <h2>OCR Output</h2> : <div></div>}
                    {dataAvailable && apiResponse.result[0].ocr ? <ReactJson collapsed='true' displayDataTypes='false' src={apiResponse.result[0].ocr} /> : <div></div>}
                </Grid>
                <Grid item xs={6}>
                    {processing ? (
                        <div style={{ marginTop: 50 }}><h3>Processing..</h3><CircularProgress /></div>
                    ) : dataAvailable ? (
                        <div style={{ marginTop: 50, textAlign: 'left' }}><h2>Result</h2><br></br>Type: {apiResponse.result[0].type}<br></br>
                            Score: {apiResponse.result[0].score}<br></br>
                        </div>) :
                        <div></div>
                    }
                </Grid>
            </Grid>
        </div>
    )
}

TestClassifier.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {})(TestClassifier);
