import React, { useState, useEffect, useCallback } from "react";
import ImageZoom from "react-image-zoom-rotate";
import axios from "axios";
import { useSnackbar } from "notistack";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Button,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import { ArrowBack, ArrowForward, Flag } from "@material-ui/icons";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(grey[900]),
    backgroundColor: grey[900],
    "&:hover": {
      backgroundColor: grey[900],
    },
    height: 50,
    minWidth: 100,
  },
}))(Button);

function ViewAnnotation(props) {
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [annotation, setAnnotation] = useState([]);
  const [ocr, setOcr] = useState([]);
  const [flag, setFlag] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { state } = props.location;
  const { projectType, annotationList } = state;

  const handleDelete = () => {
    const imageId = state.imageKey.split("/")[3].split(".")[0];
    axios
      .post("/api/benchmarks/annotation/delete", {
        benchmark: state.benchmark,
        imageId,
      })
      .then((res) => {
        props.history.goBack();
      })
      .catch((e) => {
        enqueueSnackbar(e.response.data, {
          variant: "error",
        });
      });
  };

  const loadData = () => {
    const imageId = state.imageKey.split("/")[3].split(".")[0];
    setLoading(true);
    axios
      .post("/api/benchmarks/annotation/view", {
        benchmark: state.benchmark,
        imageId,
        imageKey: state.imageKey,
      })
      .then((res) => {
        setLoading(false);
        setImage(res.data.image);
        setAnnotation(res.data.annotationFileBody);
        setOcr(res.data.ocr);
        if (res.data.flags && res.data.flags[imageId] && res.data.flags[imageId].annotation) {
          setFlag(res.data.flags[imageId].annotation);
        } else {
          setFlag(false);
        }
      })
      .catch((e) => {
        setLoading(false);
        enqueueSnackbar('Error', {
          variant: "error",
        });
      });
  };
  const handleEdit = () => {
    const imageId = state.imageKey.split("/")[3].split(".")[0];
    props.history.push({
      pathname: "/benchmark/annotate",
      state: {
        ...state,
        benchmark: state.benchmark,
        benchmarkType: state.benchmarkType,
        id: state.id,
        imageId,
        imageKey: state.imageKey,
        projectType: state.projectType,
        type: "edit",
      },
    });
  };

  const handlePrevious = () => {
    const currentIndex = annotationList.indexOf(state.imageKey);
    let nextIndex = currentIndex - 1;
    if (nextIndex < 0) {
      nextIndex = annotationList.length - 1;
    }
    state.imageKey = annotationList[nextIndex];
    loadData();
  };

  const handleNext = () => {
    const currentIndex = annotationList.indexOf(state.imageKey);
    let nextIndex = currentIndex + 1;
    if (nextIndex > annotationList.length - 1) {
      nextIndex = 0;
    }
    state.imageKey = annotationList[nextIndex];
    loadData();
  };

  const handleFlag = () => {
    const imageId = state.imageKey.split("/")[3].split(".")[0];
    axios
      .post("/api/benchmarks/flags/flag", {
        benchmark: state.benchmark,
        imageId,
        value: !flag,
        type: 'annotation',
      })
      .then((res) => {
        loadData();
      })
      .catch((e) => {
        enqueueSnackbar('Error', {
          variant: "error",
        });
      });
  };


  const handleKeyNavigation = useCallback((event) => {
    if (!loading) {
      if (event.keyCode === 37) {
        handlePrevious();
      }
      if (event.keyCode === 39) {
        handleNext();
      }
    }
  }, []);

  const handleHome = () => {
    props.history.push({
      pathname: "/",
      state,
    });
  };

  const handleBack = () => {
    props.history.goBack();
  }

  useEffect(() => {
    loadData();
    document.addEventListener("keydown", handleKeyNavigation, false);
    return () => {
      document.removeEventListener("keydown", handleKeyNavigation, false);
    };
  }, []);

  return (
    <div style={{ paddingLeft: 10, paddingRight: 10 }}>
      <h1 style={{ fontSize: 20 }}>{state.taskId}</h1>
      <ColorButton
        onClick={handleHome}
        variant="contained"
        color="primary"
        style={{ float: "right", marginRight: "90px" }}
      >
        Home
      </ColorButton>
      <ColorButton
        onClick={handleBack}
        variant="contained"
        color="primary"
        style={{ float: "left", marginRight: "90px" }}
      >
        Go Back
      </ColorButton>
      <br></br>
      <br></br>
      <h1>Image: {state.imageKey.split('/')[3]}</h1>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <ImageZoom
            rotate={(clockwise) => {
              return (
                <div>
                  <Button
                    onClick={clockwise}
                    style={{ position: "absolute", right: 0, zIndex: 1 }}
                  >
                    ↻
                  </Button>
                </div>
              );
            }}
            src={`data:image/png;base64,${image}`}
            width={650}
            zoomWidth={500}
          />
        </Grid>
        <Grid item xs={6}>
          {state.benchmarkType === "group" ? (
            <TableContainer>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">GT</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell align="center">
                      {annotation.e2e_gt}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <>
              {projectType !== 'validation' && <>
                <TableContainer>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Labels</StyledTableCell>
                        <StyledTableCell align="center">e2e_gt</StyledTableCell>
                        <StyledTableCell align="center">ocr_gt</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {annotation.length === 0
                        ? ""
                        : Object.keys(annotation.e2e_gt).map((row, i) => (
                          <StyledTableRow key={row.name}>
                            <StyledTableCell component="th" scope="row">
                              {row}
                            </StyledTableCell>
                            <StyledTableCell align='center'>
                              {annotation.e2e_gt[row]}
                            </StyledTableCell>
                            <StyledTableCell align='center'>
                              {annotation.ocr_gt[row]}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer></>}
              {projectType !== 'extraction' && <>
                <h1>Validation</h1>
                <TableContainer>
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Labels</StyledTableCell>
                        <StyledTableCell align="center">validation</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {annotation.length === 0
                        ? ""
                        : Object.keys(annotation.validationAnnotation).map((key, index) => (
                          <StyledTableRow key={key}>
                            <StyledTableCell component="th" scope="row">
                              {key}
                            </StyledTableCell>
                            <StyledTableCell align='center'>
                              {annotation.validationAnnotation[key]}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>}
            </>
          )}
          <br></br>
          <br></br>
          <Button
            onClick={() => handleEdit()}
            variant="contained"
            color="primary"
            disabled={loading}
          >
            Reannotate
          </Button>{" "}
          <Button
            onClick={() => handleDelete()}
            variant="contained"
            color="secondary"
            disabled={loading}
          >
            Delete
          </Button>{" "}
          <Button
            onClick={handlePrevious}
            variant="contained"
            color="black"
            disabled={loading}
            startIcon={<ArrowBack />}
          >
            Previous
          </Button>{" "}
          <Button
            onClick={handleNext}
            variant="contained"
            color="black"
            disabled={loading}
            endIcon={<ArrowForward />}
          >
            Next
          </Button>{" "}
          <Button
            onClick={handleFlag}
            variant="contained"
            color="secondary"
            disabled={loading}
            endIcon={<Flag />}
          >
            {flag ? "Unflag" : "Flag"}
          </Button>
        </Grid>
      </Grid>
      <div style={{ paddingLeft: 30 }}>
        <h1 style={{ fontSize: 20 }}>OCR Output</h1>
        {ocr.map(function (line, i) {
          return (
            <p style={{ margin: 0 }}>
              {line}
              <br></br>
            </p>
          );
        })}
      </div>
      <br></br>
      <br></br>
    </div>
  );
}

export default ViewAnnotation;
