import { combineReducers } from "redux";
import authReducer from "./authReducers";
import errorReducer from "./errorReducers";
import filterReducer from "./filterReducer";
import pageReducer from "./pageReducer";

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  filter: filterReducer,
  page: pageReducer,
});
