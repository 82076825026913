import React, { useEffect } from 'react'
import LabelStudio from 'label-studio';
import axios from 'axios';

function Annotate(props) {
    const { annotation, project: projectId, id: taskId } = props.location.state;
    const buildAnnotation = (annotations) => {
        if (!annotations || annotations.length === 0) return [];
        const annotationStructure = {
            id: 1,
            createdBy: 1,
            createdDate: new Date(),
            result: annotations.map((item, index) => ({
                id: (index + 1).toString(),
                from_name: "label",
                to_name: "text",
                type: "labels",
                value: {
                    labels: [item.label],
                    text: item.value,
                    start: item.start,
                    end: item.end,
                }
            })),
            userGenerate: true,
        };
        return [annotationStructure];
    };

    useEffect(() => {
        axios.post("/api/projects/getSingleTask", {
            'projectId': projectId,
            'taskId': taskId
        })
            .then(function (response) {
                let { id, image, text } = response.data.task
                let config = response.data.config
                let prefix = `
                <View>
                    <Image name="image" value="$image" rotateControl="true" zoomControl="true" />
                    <Labels name="label" toName="text">`
                let suffix = `</Labels>
                <Text name="text" value="$text"/>
                </View>`
                const colors = ['orange', 'red', 'blue', 'green', 'black', 'yellow', 'orange', 'red', 'blue', 'green', 'black', 'yellow', 'blue', 'green', 'black', 'yellow', 'orange', 'red', 'blue', 'green', 'black', 'yellow', 'blue', 'green', 'black', 'yellow', 'orange', 'red', 'blue', 'green', 'black', 'yellow', 'blue', 'green', 'black', 'yellow']
                let configuration = ``
                config.forEach((field, i) => {
                    configuration += `<Label value="${field}" background="${colors[i]}"/> `
                })
                let final = prefix + configuration + suffix;
                image = 'data:image/png;base64,' + image
                const link = document.createElement('link');
                link.href = "https://unpkg.com/label-studio@1.0.1/build/static/css/main.css";
                link.rel = "stylesheet"

                document.body.appendChild(link);

                new LabelStudio('label-studio', {
                    config: final,
                    interfaces: [
                        "panel",
                        "submit",
                        "controls",
                        "side-column",
                        "annotations:menu",
                    ],
                    user: {
                        pk: 1,
                        firstName: "HyperVerge",
                        lastName: "HyperTuring"
                    },
                    task: {
                        annotations: buildAnnotation(annotation),
                        id: parseInt(id),
                        data: {
                            image: image,
                            text: text,
                        },
                    },
                    onLabelStudioLoad: function (LS) {
                        var c = LS.annotationStore.addAnnotation({
                            userGenerate: true
                        });
                        LS.annotationStore.selectAnnotation(c.id);
                    },
                    onSubmitAnnotation: function (ls, annotation) {
                        var raw = JSON.stringify(annotation);
                        axios.post("/api/projects/annotate", { raw, id: taskId, projectId }).then(result => {
                            props.history.push({
                                pathname: '/project',
                                state: {
                                    ...props.location.state,
                                }
                            })
                        }).catch(err => {
                            console.log(err)
                        })
                    }
                });
                return () => {
                    document.body.removeChild(link);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [])

    return (
        <div id="label-studio">
        </div>
    )
}

export default Annotate;
