import React from 'react';
import axios from 'axios';
import { useSnackbar } from "notistack";
import { Dialog, DialogActions, DialogContent, Button, TextField } from '@material-ui/core';


const ConfModal = (props) => {
    const { openConfThreshold, setOpenConfThreshold, handleConfThresholdClose, confThreshold, handleConfThreshold, project } = props;
    const { enqueueSnackbar } = useSnackbar();

    const saveConfThreshold = async () => {
        const invalid = Object.keys(confThreshold).some(field => {
            if (confThreshold[field] < 0 || confThreshold[field] > 1) {
                return true;
            }
            return false;
        });
        if (!invalid) {
            try {
                await axios.post("/api/projects/setConfThreshold", {
                    projectId: project,
                    confThreshold,
                });
                enqueueSnackbar("Conf Threshold updated", {
                    variant: "success",
                });
                setOpenConfThreshold(false);
            } catch (err) {
                enqueueSnackbar(err.response.data, {
                    variant: "error",
                });

            }
            setOpenConfThreshold(false);
        } else {
            enqueueSnackbar("Please enter threshold between 0-1", {
                variant: "error",
            });
        }

    };
    return (
        <Dialog
            open={openConfThreshold}
            onClose={handleConfThresholdClose}
            aria-labelledby="confThreshold"
            fullWidth={true}
            maxWidth="xs"
        >
            <DialogContent>
                <h2>Set Confidence Threshold</h2>
                {Object.keys(confThreshold).map(field =>
                    <TextField
                        name={field}
                        variant="outlined"
                        value={confThreshold[field]}
                        onChange={(event) => handleConfThreshold(event)}
                        label={field}
                        style={{ margin: '10px' }}
                        type="number"
                        inputProps={{ step: 0.1 }}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={saveConfThreshold}
                    variant="contained"
                    color="primary"
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConfModal