import React, { useEffect, useState, } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import {
    Grid,
    Dialog,
    Button,
    DialogActions,
    DialogContent,
    TextField,
    MenuItem,
    Checkbox,
    FormGroup,
    FormControlLabel,
    DialogTitle,
    TablePagination,
    Box,
    CircularProgress,
} from '@material-ui/core';
import {
    ArrowBack,
    Add,
    Replay,
} from '@material-ui/icons';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import { useDispatch, useSelector } from 'react-redux';
import { saveFilter } from "../../actions/filterActions";
import { savePage } from "../../actions/pageActions";
import ViewResult from './ViewResult';
import ImagesUpload from '../pages/Upload';
import TaskCard from './TaskCard';

const ColorButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(grey[900]),
        backgroundColor: grey[900],
        '&:hover': {
            backgroundColor: grey[900],
        },
        height: 50,
        minWidth: 100,
    },
}))(Button);

const useStyles = makeStyles({
    root: {
        minWidth: 350,
        marginBottom: 100,
    },
    cards: {
        marginTop: 50,
        marginLeft: 25,
    },
});

const OpenBenchmark = (props) => {
    const dispatch = useDispatch();
    const pageNumber = useSelector(
        (state) => state.page.pageNumber
    );
    const pageSize = useSelector(
        (state) => state.page.pageSize
    );
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState('');
    const [results, setResults] = useState({});
    const [validationResult, setValidationResult] = useState('');
    const [type, setType] = useState('extraction');
    const [openUpload, setOpenUpload] = useState(false);
    const [openResult, setOpenResult] = useState(false);
    const [openBenchmarkConfig, setOpenBenchmarkConfig] = useState(false);
    const [benchmarkConfig, setBenchmarkConfig] = useState({});
    const classes = useStyles();
    const [uploadType, setUploadType] = useState('');
    const [images, setImages] = useState([]);
    const [completedInference, setCompletedInference] = useState(0);
    const [completedOcr, setCompletedOcr] = useState(0);
    const [completedAnnotations, setCompletedAnnotations] = useState(0);
    const [customResult, setCustomResult] = useState({});
    const [totalSamples, setTotalSamples] = useState(0);
    const [flags, setFlags] = useState({});
    const [responseFlagType, setResponseFlagType] = useState('');
    const [annotationFlagType, setAnnotationFlagType] = useState('');
    const [modelName, setModelName] = useState('');
    const [modelNameOpen, setModelNameOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const handleAnnotate = (imageKey) => {
        const imageId = imageKey.split('/')[3].split('.')[0];
        props.history.push({
            pathname: "/benchmark/annotate",
            state: {
                ...props.location.state,
                benchmark: props.location.state.benchmark,
                benchmarkType: props.location.state.benchmarkType,
                id: props.location.state.id,
                imageId,
                imageKey,
                projectType: type,
                type: "new",
            },
        });
    };

    const handleViewAnnotation = (imageKey) => {
        const annotationList = [];
        images.forEach((obj) => {
            if (obj.annotation) {
                annotationList.push(obj.imageKey);
            }

        });
        props.history.push({
            pathname: "/benchmark/viewAnnotation",
            state: {
                benchmark: props.location.state.benchmark,
                benchmarkType: props.location.state.benchmarkType,
                id: props.location.state.id,
                imageKey,
                images,
                annotationList,
                projectType: type,
                type: "new",
            },
        });
    };

    const handleResponse = (imageKey) => {
        const inferenceList = [];
        images.forEach((obj) => {
            if (obj.inference) {
                inferenceList.push(obj.imageKey);
            }

        });
        props.history.push({
            pathname: "/benchmark/viewResponse",
            state: {
                benchmark: props.location.state.benchmark,
                benchmarkType: props.location.state.benchmarkType,
                imageKey,
                images,
                inferenceList,
                projectType: type,
            },
        });
    };

    const handleResponseFlagged = (flaggedImageType) => {
        setResponseFlagType(flaggedImageType);
    }

    const handleAnnotationFlagged = (flaggedImageType) => {
        setAnnotationFlagType(flaggedImageType);
    }

    //***   LOGIC FOR PAGINATION  ** */
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    useEffect(() => {
        setPage(pageNumber);
        setRowsPerPage(pageSize);
    }, [])
    const offset = page * rowsPerPage;
    const currentPageData = images
        .slice(offset, offset + rowsPerPage)
        .map((image, index) => {
            const imageId = image.imageKey.split('/')[3].split('.')[0];
            const toCheck = responseFlagType.length || annotationFlagType.length ? true : false;
            const card = <TaskCard
                image={image}
                index={index}
                handleAnnotate={handleAnnotate}
                handleViewAnnotation={handleViewAnnotation}
                handleResponse={handleResponse}
                projectType={type}
                getImages={getImages}
                flags={flags}
            />
            if (toCheck) {
                if (responseFlagType.length && annotationFlagType.length) {
                    if (flags[imageId] && flags[imageId][responseFlagType] && flags[imageId][annotationFlagType])
                        return card;
                    else return '';
                } else if (responseFlagType.length) {
                    if (flags[imageId] && flags[imageId][responseFlagType])
                        return card;
                    else return '';
                }
                else {
                    if (flags[imageId] && flags[imageId][annotationFlagType])
                        return card;
                    else return '';
                }
            } else {
                return card;
            }
        });


    const handleChangePage = (event, newPage) => {
        dispatch(savePage(newPage, rowsPerPage));
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        dispatch(savePage(page, event.target.value));
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    useEffect(() => {
        let inferenceCount = 0;
        let ocrCount = 0;
        let annotationCount = 0;
        images.forEach(image => {
            if (image.inference) {
                inferenceCount++;
            }
            if (image.ocr) {
                ocrCount++;
            }
            if (image.annotation) {
                annotationCount++;
            }
        });
        setCompletedInference(inferenceCount);
        setCompletedOcr(ocrCount);
        setCompletedAnnotations(annotationCount);
    }, [completedInference, completedAnnotations, completedOcr, images]);

    function getType() {
        axios
            .post('/api/projects/status', {
                projectId: props.location.state.id,
            })
            .then((res) => {
                if (res.data.projectType) {
                    setType(res.data.projectType);
                }
            })
            .catch((e) => {
                enqueueSnackbar(e.response.data, {
                    variant: 'error',
                });
            });
    }

    function getStatus() {
        axios
            .post('/api/benchmarks/status', {
                benchmark: props.location.state.benchmark,
            })
            .then((res) => {
                if (res.data.projectType) {
                    setType(res.data.projectType);
                }
                setStatus(res.data.status);
                setBenchmarkConfig(res.data.config);
                setModelName(res.data.default_model);
                if (res.data.results) {
                    setResults(res.data.results);
                    setCustomResult(res.data.results.custom);
                    setTotalSamples(res.data.results.total);
                }
                if (res.data.validationResult) {
                    setValidationResult(res.data.validationResult);
                }
            })
            .catch((e) => {
                enqueueSnackbar(e.response.data, {
                    variant: 'error',
                });
            });
    }

    function refreshData() {
        getImages();
        getStatus();
    }

    useEffect(() => {
        if (props.location !== undefined) {
            getStatus();
            if (props.location.state.benchmarkType !== 'group') {
                getType();
            }
        }
        dispatch(saveFilter({}, ''));
    }, []);

    async function getImages() {
        try {
            setLoading(true)
            const response = await axios.post("/api/benchmarks/images/list", {
                benchmarkId: props.location.state.benchmark,
            });
            if (response && response.data) {
                response.data.images.sort((a, b) => (Number(a.imageKey.split('/').pop().split('.')[0]) > Number(b.imageKey.split('/').pop().split('.')[0]) ? 1 : -1));
                setImages(response.data.images);
                setLoading(false);
            }
        } catch (e) {
            enqueueSnackbar('Error fetching images', {
                variant: "error",
            });
            setLoading(false);
        }
    };

    const getFlags = async () => {
        try {
            const flagsResponse = await axios.post('/api/benchmarks/flags/view', {
                benchmark: props.location.state.benchmark,
            });
            setFlags(flagsResponse.data);
        } catch (err) {
            console.log('Get flags error', err);
        }
    }

    useEffect(() => {
        if (props.location !== undefined) {
            getImages();
            getFlags();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let intervalId;
        if (status === 'model_generating') {
            intervalId = 0;
        }
        return () => {
            clearInterval(intervalId);
        };
    }, [status]);

    const handleHTModel = () => {
        axios
            .post('api/benchmarks/inference', {
                benchmark: props.location.state.benchmark,
                id: props.location.state.id,
                ocrType: props.location.state.ocrType,
                type: props.location.state.benchmarkType,
                modelName,
            })
            .then(function (response) {
                enqueueSnackbar(response.data, {
                    variant: 'success',
                });
            })
            .catch(function (error) {
                enqueueSnackbar('Error', {
                    variant: 'error',
                });
            });
    };


    const handleClickOpen = (type) => {
        setUploadType(type);
        setOpenUpload(true);
    };
    const handleClickOpenResult = () => {
        setOpenResult(true);
    };

    const handleClose = () => {
        setOpenUpload(false);
    };

    const handleCloseResult = () => {
        setOpenResult(false);
    };

    const handleOpenBenchmarkConfig = () => {
        setOpenBenchmarkConfig(true);
    };

    const handleCloseBenchmarkConfig = () => {
        setOpenBenchmarkConfig(false);
    };

    const handleConfigEditDistance = (event, field) => {
        setBenchmarkConfig({
            ...benchmarkConfig,
            [field]: { ...benchmarkConfig[field], editDistance: event.target.value },
        });
    };

    const handleConfigThreshold = (event, field) => {
        setBenchmarkConfig({
            ...benchmarkConfig,
            [field]: { ...benchmarkConfig[field], threshold: event.target.value },
        });
    };

    const handleConfigSpaces = (event, field) => {
        setBenchmarkConfig({
            ...benchmarkConfig,
            [field]: { ...benchmarkConfig[field], spaces: event.target.checked },
        });
    };

    const handleConfigCharacters = (event, field) => {
        setBenchmarkConfig({
            ...benchmarkConfig,
            [field]: { ...benchmarkConfig[field], characters: event.target.value },
        });
    };

    const { state } = props.location;
    if (state === undefined) {
        return <Redirect to='/' />;
    }

    const handleQc = () => {
        props.history.push({
            pathname: '/benchmark/qc/list',
            state: {
                ...state,
                type,
            }
        });
    };

    const handleGoBack = () => {
        props.history.goBack();
    };

    const handleBenchmark = () => {
        enqueueSnackbar('Running Benchmark', {
            variant: 'success',
        });
        setResults({});
        setValidationResult('');
        axios
            .post('api/benchmarks/run', {
                benchmark: props.location.state.benchmark,
                type,
            })
            .then(function (response) {
                getStatus();
            })
            .catch(function (error) {
                enqueueSnackbar('Error! Try later', {
                    variant: 'error',
                });
            });
    };

    const handleSetBenchmarkConfig = () => {
        axios
            .post('api/benchmarks/setConfig', {
                benchmark: props.location.state.benchmark,
                config: benchmarkConfig,
            })
            .then(function (response) {
                enqueueSnackbar('Config Updated', {
                    variant: 'success',
                });
                window.location.reload();
            })
            .catch(function (error) {
                enqueueSnackbar(error.response.data, {
                    variant: 'error',
                });
            });
    };

    const handleModelName = (event) => {
        setModelName(event.target.value);
    }

    const handleModelNameOpen = () => {
        setModelNameOpen(true);
    }

    const handleModelNameClose = () => {
        setModelNameOpen(false);
    }

    const saveModelName = async () => {
        try {
            await axios.post('api/benchmarks/setDefaultModel', {
                modelName,
                benchmark: props.location.state.benchmark,
            });
            enqueueSnackbar("Default model updated", {
                variant: "success",
            });
            setModelNameOpen(false);
        } catch (err) {
            enqueueSnackbar('Error setting default model', {
                variant: "error",
            });
        }
    }

    return (
        <div>
            <span>&nbsp;&nbsp;</span>
            <div style={{ margin: '0px 0px 20px 20px' }}>
                <b>Benchmark: </b> {props.location.state.name} ({props.location.state.benchmark})
            </div>
            <div style={{ margin: '0px 0px 20px 20px', position: 'absolute', top: '150px', right: '30px', fontSize: '16px' }}>
                <span>
                    <p>Inference : {completedInference}/{images.length}</p>
                    <p>OCR : {completedOcr}/{images.length}</p>
                    <p>Annotation : {completedAnnotations}/{images.length}</p>
                </span>
            </div>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <ColorButton
                startIcon={<ArrowBack />}
                onClick={handleGoBack}
                variant='contained'
                color='primary'
            >
                Go back
            </ColorButton>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <ColorButton
                startIcon={<Replay />}
                onClick={refreshData}
                variant="contained"
                color="primary"
            >
                Refresh
            </ColorButton>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <ColorButton
                startIcon={<Add />}
                onClick={() => handleClickOpen('folder')}
                variant='contained'
                color='primary'
            >
                Upload Folder
            </ColorButton>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <ColorButton
                startIcon={<Add />}
                onClick={() => handleClickOpen('files')}
                variant='contained'
                color='primary'
            >
                Upload Images
            </ColorButton>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <ColorButton
                onClick={() => annotationFlagType === 'annotation' ? handleAnnotationFlagged('') : handleAnnotationFlagged('annotation')}
                variant="contained"
                color="primary"
            >
                {annotationFlagType === 'annotation' ? 'View All' : 'Flagged Annotation'}
            </ColorButton>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <ColorButton
                onClick={() => responseFlagType === 'response' ? handleResponseFlagged('') : handleResponseFlagged('response')}
                variant="contained"
                color="primary"
            >
                {responseFlagType === 'response' ? 'View All' : 'Flagged Response'}
            </ColorButton>
            <p></p>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            {props.location.state.benchmarkType === 'project' && type !== 'validation' ? <>
                <ColorButton
                    onClick={handleModelNameOpen}
                    variant="contained"
                    color="primary"
                >
                    Set Default Model
                </ColorButton>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </> : ''}
            <ColorButton
                onClick={handleHTModel}
                variant='contained'
                color='primary'
            >
                RUN INFERENCE
            </ColorButton>
            {props.location.state.benchmarkType === 'project' ?
                <><span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <ColorButton
                        onClick={handleOpenBenchmarkConfig}
                        variant='contained'
                        color='primary'
                    >
                        EDIT BENCHMARK CONFIG
                    </ColorButton>
                </> : ''}
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <ColorButton
                onClick={handleBenchmark}
                variant='contained'
                color='primary'
            >
                RUN BENCHMARK
            </ColorButton>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <ColorButton
                onClick={handleClickOpenResult}
                variant='contained'
                color='primary'
                disabled={status !== 'benchmark_completed' || (validationResult.length === 0 && Object.keys(results).length === 0)}
            >
                VIEW RESULTS
            </ColorButton>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <ColorButton
                onClick={handleQc}
                variant='contained'
                color='primary'
                disabled={status !== 'benchmark_completed' || (validationResult.length === 0 && Object.keys(results).length === 0)}
            >
                VIEW QC
            </ColorButton>
            <p></p>
            <TablePagination
                component="div"
                count={images.length}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[10, 25, 50, 100, 150, 200, 250, 300, 400, 500]}
            />
            <Grid container className={classes.cards}>
                {loading ? <Box sx={{ display: 'flex' }}>
                    <CircularProgress />
                </Box> : images.length === 0
                    ? 'No Existing images'
                    : <>
                        {currentPageData}
                    </>
                }
            </Grid>
            <Dialog open={openUpload} onClose={handleClose} aria-labelledby='upload'>
                <ImagesUpload
                    projectId={props.location.state.id}
                    groupId={props.location.state.group}
                    type={uploadType}
                    ocrType={props.location.state.ocrType}
                    benchmark={props.location.state.benchmark}
                    benchmarkType={props.location.state.benchmarkType}
                    projectType={type}
                    docType={props.location.state.docType}
                    uploadData='benchmark'
                    getImages={getImages}
                    setOpenUpload={setOpenUpload}

                />
            </Dialog>
            {Object.keys(results).length || Object.keys(validationResult).length ? <Dialog
                open={openResult}
                onClose={handleCloseResult}
                aria-labelledby='benchmark'
                maxWidth='xl'
            >
                <DialogContent>
                    <ViewResult
                        results={results}
                        totalSamples={totalSamples}
                        validationResult={validationResult}
                        benchmarkType={state.benchmarkType}
                        benchmark={state.benchmark}
                        type={type}
                        getStatus={getStatus}
                        customResult={customResult}
                        setCustomResult={setCustomResult}
                    />
                </DialogContent>
            </Dialog> : ''
            }
            <Dialog
                open={openBenchmarkConfig}
                onClose={handleCloseBenchmarkConfig}
                aria-labelledby='benchmark-config'
                maxWidth='xl'
            >
                <DialogTitle>Edit Benchmark Config</DialogTitle>
                <DialogContent>
                    {benchmarkConfig &&
                        Object.keys(benchmarkConfig).map((field) => (
                            <div style={{ marginBottom: 10 }}>
                                <TextField
                                    name='field'
                                    label='Field Name'
                                    variant='outlined'
                                    value={field}
                                    disabled
                                />
                                <span>&nbsp;</span>
                                <TextField
                                    name='threshold'
                                    label='Set Threshold'
                                    variant='outlined'
                                    value={benchmarkConfig[field].threshold}
                                    onChange={(event) => handleConfigThreshold(event, field)}
                                />
                                <span>&nbsp;</span>
                                <TextField
                                    name='characters'
                                    label='Remove characters'
                                    variant='outlined'
                                    value={benchmarkConfig[field].characters}
                                    onChange={(event) => handleConfigCharacters(event, field)}
                                />
                                <span>&nbsp;</span>
                                <TextField
                                    select
                                    name={field}
                                    variant='outlined'
                                    value={benchmarkConfig[field].editDistance}
                                    onChange={(event) => handleConfigEditDistance(event, field)}
                                    label='Edit Distance'
                                    style={{ width: 250 }}
                                >
                                    <MenuItem value='0'>0</MenuItem>
                                    <MenuItem value='1'>1</MenuItem>
                                    <MenuItem value='2'>2</MenuItem>
                                    <MenuItem value='3'>3</MenuItem>
                                </TextField>
                                <span>&nbsp;</span>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={benchmarkConfig[field].spaces}
                                                onChange={(event) => handleConfigSpaces(event, field)}
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                                required
                                            />
                                        }
                                        label='Remove Spaces'
                                    />
                                </FormGroup>
                            </div>
                        ))}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseBenchmarkConfig}
                        variant='contained'
                        color='secondary'
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSetBenchmarkConfig}
                        variant='contained'
                        color='default'
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={modelNameOpen}
                onClose={handleModelNameClose}
                aria-labelledby="config"
            >
                <DialogContent>
                    <h2>Enter model</h2>
                    <TextField
                        name="modelName"
                        variant="outlined"
                        placeholder="model_1"
                        value={modelName}
                        onChange={(event) => handleModelName(event)}
                        label="Model Name"
                        style={{ width: 500 }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={saveModelName}
                        variant="contained"
                        color="primary"
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default OpenBenchmark;
