import React from 'react';
import { CopyBlock, dracula } from "react-code-blocks";
import { getCurrentInferenceDomain } from "../../../constants";
import {
    Dialog, DialogContent,
} from "@material-ui/core";

const TestURL = (props) => {
    const { openCurl, handleCurlClose, project } = props;

    const code = `curl --location --request POST 'https://${getCurrentInferenceDomain()}/v1/readOCR' \\
    --header 'appid: xxxxxx' \\
    --header 'appkey: xxxxxxxxxxxxxxxxxxxx' \\
    --form 'image=@"image.png"' \\
    --form 'project="${project}"'`;

    return (
        <Dialog
            open={openCurl}
            onClose={handleCurlClose}
            aria-labelledby="curl"
            maxWidth='md'
        >
            <DialogContent>
                <div style={{ textAlign: "center" }}>
                    <code>
                        Test the model using this curl request
                    </code>
                </div>
                <div style={{ width: "70%", margin: "auto" }}>
                    <CopyBlock
                        text={code}
                        language="javascript"
                        theme={dracula}
                        CopyBlock
                    />
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default TestURL