import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { useSnackbar } from "notistack";
import { Grid, Card, CardContent, Dialog, Button, IconButton, Tooltip, DialogActions, DialogContent, Switch, FormControlLabel } from "@material-ui/core";
import { Delete, ArrowBack, Add, CheckCircle, FindReplace, Replay } from '@material-ui/icons';
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import FileUpload from "./Upload";
import ImageContainer from "./ImageContainer";

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(grey[900]),
    backgroundColor: grey[900],
    "&:hover": {
      backgroundColor: grey[900],
    },
    height: 50,
    minWidth: 100,
  },
}))(Button);

const useStyles = makeStyles({
  root: {
    minWidth: 250,
    marginBottom: 100
  },
  cards: {
    marginTop: 50,
    marginLeft: 25
  },
});

function ClassificationProject(props) {
  const classes = useStyles();
  const [status, setStatus] = useState("");
  const [uploadType, setUploadType] = useState('')
  const [openUpload, setOpenUpload] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [tbDeleted, setTBDelete] = useState('');
  const [images, setImages] = useState([])
  const [loading, setLoading] = useState(false)
  const [pending, setPending] = useState(false)
  const { enqueueSnackbar } = useSnackbar();

  async function getImages() {
    try {
      setLoading(true)
      const response = await axios.post("/api/classification/images/list", {
        projectId: props.location.state.project,
        groupId: props.location.state.group,
        classificationType: props.location.state.classificationType,
      });
      if (response && response.data) {
        setImages(response.data.images);
        setStatus(response.data.status)
        setLoading(false);
      }
    } catch (e) {
      enqueueSnackbar('Error fetching images', {
        variant: "error",
      });
      setLoading(false);
    }
  }

  async function deleteImage() {
    try {
      const response = await axios.post("/api/classification/images/delete", {
        key: tbDeleted,
      });
      if (response && response.data) {
        enqueueSnackbar(response.data, {
          variant: "success",
        });
        handleCloseDelete();
        getImages();
      }
    } catch (e) {
      enqueueSnackbar('Could not delete image', {
        variant: "error",
      });
    }
  }

  async function refreshOcr() {
    try {
      const response = await axios.post("/api/classification/ocr/redo", {
        projectId: props.location.state.project,
        groupId: props.location.state.group,
        classificationType: props.location.state.classificationType,
      });
      if (response && response.data) {
        enqueueSnackbar('OCR refresh started', {
          variant: "success",
        });
      }
    } catch (e) {
      enqueueSnackbar('Error fetching images', {
        variant: "error",
      });
      setLoading(false);
    }
  }

  const handleClickOpen = (type) => {
    setUploadType(type);
    setOpenUpload(true);
  };

  const handleDeleteDialog = (key) => {
    setTBDelete(key);
    setOpenDelete(true);
  };

  const handleClose = () => {
    setOpenUpload(false);
  };

  const handleCloseDelete = () => {
    setTBDelete('')
    setOpenDelete(false);
  };

  const handleGoBack = () => {
    props.history.goBack();
  };
  const togglePending = (event) => {
    setPending(event.target.checked)
  };

  useEffect(() => {
    if (props.location !== undefined) {
      getImages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { state } = props.location;
  if (state === undefined) {
    return <Redirect to="/" />;
  }

  return (
    <div>
      <span>&nbsp;&nbsp;</span>
      <div style={{ margin: '0px 0px 20px 20px' }}><b>Project:</b> {state.name}</div>
      {status === "running_ocr" ?
        <div style={{ color: "red", margin: '0px 0px 20px 20px' }}>OCR Running...</div>
        : ""
      }
      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
      <ColorButton
        startIcon={<ArrowBack />}
        onClick={handleGoBack}
        variant="contained"
        color="primary"
      >
        Go back
      </ColorButton>
      <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
      <ColorButton
        startIcon={<Add />}
        onClick={() => handleClickOpen('folder')}
        variant="contained"
        color="primary"
      >
        Upload Folder
      </ColorButton>
      <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
      <ColorButton
        startIcon={<Add />}
        onClick={() => handleClickOpen('files')}
        variant="contained"
        color="primary"
      >
        Upload Images
      </ColorButton>

      {status === "running_ocr" ?
        <>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <ColorButton
            startIcon={<Replay />}
            onClick={refreshOcr}
            variant="contained"
            color="primary"
          >
            Redo Pending
          </ColorButton>
        </>
        : ""
      }
      {status === "running_ocr" ?
        <div style={{ float: 'right', marginRight: '90px' }}>
          <FormControlLabel control={<Switch
            checked={pending}
            onChange={togglePending}
            inputProps={{ 'aria-label': 'controlled' }}
            color="secondary" />} label="Only show pending" />
        </div>
        : ""
      }
      <Grid container className={classes.cards} >
        {loading ? <h3>Loading Images</h3> :
          images.length === 0
            ? <h3>No Images Available</h3>
            : images.map((image, index) => (
              image.ocr && pending ? <></> :
                <Grid container item xs={12} md={3} spacing={1} key={index}>
                  <Card className={classes.root}>
                    <Tooltip title="Delete">
                      <IconButton
                        onClick={() => handleDeleteDialog(image.key)}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                    {image.ocr ?
                      <Tooltip title="OCR Ready">
                        <IconButton>
                          <CheckCircle style={{ color: "green" }} />
                        </IconButton>
                      </Tooltip> : state.classificationType === 'image' ? '' :
                        <Tooltip title="OCR Pending">
                          <IconButton>
                            <FindReplace style={{ color: "orange" }} />
                          </IconButton>
                        </Tooltip>}
                    <CardContent>
                      <ImageContainer
                        src={image.key}
                        category='classification'
                      />
                    </CardContent>
                  </Card>
                </Grid>
            ))}
      </Grid>
      <Dialog open={openUpload} onClose={handleClose} aria-labelledby="upload">
        <FileUpload
          projectId={state.project}
          groupId={state.group}
          type={uploadType}
          uploadData='classification'
          classificationType={state.classificationType}
          getImages={getImages}
          setOpenUpload={setOpenUpload}
        />
      </Dialog>
      <Dialog open={openDelete} onClose={handleCloseDelete} aria-labelledby="delete">
        <DialogContent>
          Are you sure you want to delete ?
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteImage} variant="contained" color="secondary">
            Yes
          </Button>
          <Button onClick={handleCloseDelete} variant="contained" color="default">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ClassificationProject;
