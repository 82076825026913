import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { useSnackbar } from "notistack";
import { v4 as uuidv4 } from "uuid";
import RemoveIcon from "@material-ui/icons/Remove";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const RulesSection = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [saved, setSaved] = useState(false);
  const emptyRule = {
    id: uuidv4(),
    ruleType: "",
    replacedChar: "",
    newChar: "",
    index: "",
    startIndex: "",
    endIndex: "",
    keepOnly: "",
    outputRegex: "",
    list: "",
    threshold: "",
    phraseToBeChecked: "",
    phraseToBeReturned: "",
    fieldName: "",
    ocrRegex: "",
    confRegex: "",
    confValue: "",
  };
  const [rulesArray, setRulesArray] = useState([emptyRule]);

  const handleRulesInput = (id, event) => {
    setSaved(false);
    const newInputFields = rulesArray.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setRulesArray(newInputFields);
  };

  const handleAddRulesFields = () => {
    setRulesArray([...rulesArray, emptyRule]);
  };

  const handleRemoveRulesFields = (id) => {
    let values = [...rulesArray];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    if (values.length === 0) {
      values = [emptyRule];
    }
    setRulesArray(values);
  };

  const fetchRules = () => {
    let tempArray = [];
    const rules = props.rule;
    if (rules && rules.length) {
      rules.forEach((rule) => {
        tempArray.push({
          id: uuidv4(),
          ruleType: rule.rule,
          replacedChar: rule.replacedChar || "",
          newChar: rule.newChar || "",
          index: rule.index || "",
          startIndex: rule.startIndex || "",
          endIndex: rule.endIndex || "",
          keepOnly: rule.keepOnly || "",
          outputRegex: rule.outputRegex || "",
          list: rule.list || "",
          threshold: rule.threshold || "",
          phraseToBeChecked: rule.phraseToBeChecked || "",
          phraseToBeReturned: rule.phraseToBeReturned || "",
          fieldName: rule.fieldName || "",
          ocrRegex: rule.ocrRegex || "",
          confRegex: rule.confRegex || "",
          confValue: rule.confValue || "",
        });
      });
    } else {
      tempArray.push(emptyRule);
    }
    setRulesArray(tempArray);
  };

  useEffect(() => {
    fetchRules();
  }, [props.rule]);

  const handleSave = () => {
    console.log(rulesArray);
    setSaved(true);
    const data = {
      projectId: props.project,
      rules: rulesArray,
      field: props.field,
    };
    axios
      .post("/api/projects/updateRules", data)
      .then((res) => {
        enqueueSnackbar("Rules updated successfully", {
          variant: "success",
        });
        props.setOpenRules(false);
      })
      .catch((e) => {
        enqueueSnackbar(e.response.data, {
          variant: "error",
        });
      });
  };

  const handleOnDragEnd = (result) => {
    setSaved(false);
    if (!result.destination) return;
    const items = Array.from(rulesArray);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setRulesArray(items);
  };

  return (
    <form>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <ul>
          <Droppable droppableId="rules">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {rulesArray.map((inputField, index) => {
                  return (
                    <Draggable
                      key={inputField.id}
                      draggableId={inputField.id}
                      index={index}
                    >
                      {(provided) => (
                        <li
                          className="fas fa-bars"
                          key={index}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <FormControl
                            style={{ marginLeft: 30, marginTop: 15 }}
                          >
                            <TextField
                              required
                              select
                              name="ruleType"
                              variant="outlined"
                              value={inputField.ruleType}
                              onChange={(event) =>
                                handleRulesInput(inputField.id, event)
                              }
                              label="Rule Type"
                              style={{ width: 250 }}
                            >
                              <MenuItem value="replace">
                                output.replace
                              </MenuItem>
                              <MenuItem value="return">output.return</MenuItem>
                              <MenuItem value="keepOnly">
                                output.keepOnly
                              </MenuItem>
                              <MenuItem value="find">output.find</MenuItem>
                              <MenuItem value="closest">
                                output.closest
                              </MenuItem>
                              <MenuItem value="ifReturn">
                                output.ifReturn
                              </MenuItem>
                              <MenuItem value="equals">output.equals</MenuItem>
                              <MenuItem value="ocrFind">ocr.find</MenuItem>
                              <MenuItem value="confSet">conf.set</MenuItem>
                            </TextField>
                          </FormControl>
                          <span>&nbsp;</span>
                          <FormControl
                            style={{ marginLeft: 30, marginTop: 15 }}
                          >
                            {rulesArray[index]["ruleType"] === "replace" ? (
                              <div>
                                <TextField
                                  name="replacedChar"
                                  variant="outlined"
                                  value={inputField.replacedChar}
                                  onChange={(event) =>
                                    handleRulesInput(inputField.id, event)
                                  }
                                  label="Character to be replaced"
                                  style={{ width: 250 }}
                                />
                                <span>&nbsp;&nbsp;</span>
                                <TextField
                                  name="newChar"
                                  variant="outlined"
                                  value={inputField.newChar}
                                  onChange={(event) =>
                                    handleRulesInput(inputField.id, event)
                                  }
                                  label="New Character"
                                  style={{ width: 250 }}
                                />
                                <span>&nbsp;&nbsp;</span>
                                <TextField
                                  name="index"
                                  variant="outlined"
                                  value={inputField.index}
                                  onChange={(event) =>
                                    handleRulesInput(inputField.id, event)
                                  }
                                  label="At Index"
                                  style={{ width: 90 }}
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                            {rulesArray[index]["ruleType"] === "return" ? (
                              <div>
                                <TextField
                                  name="startIndex"
                                  variant="outlined"
                                  value={inputField.startIndex}
                                  onChange={(event) =>
                                    handleRulesInput(inputField.id, event)
                                  }
                                  label="Start Index"
                                  style={{ width: 250 }}
                                />
                                <span>&nbsp;&nbsp;</span>
                                <TextField
                                  name="endIndex"
                                  variant="outlined"
                                  value={inputField.endIndex}
                                  onChange={(event) =>
                                    handleRulesInput(inputField.id, event)
                                  }
                                  label="endIndex"
                                  style={{ width: 250 }}
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                            {rulesArray[index]["ruleType"] === "keepOnly" ? (
                              <TextField
                                name="keepOnly"
                                variant="outlined"
                                value={inputField.keepOnly}
                                onChange={(event) =>
                                  handleRulesInput(inputField.id, event)
                                }
                                label="num | alpha | alphanum | [characters]"
                                style={{ width: 350 }}
                              />
                            ) : (
                              <></>
                            )}
                            {rulesArray[index]["ruleType"] === "find" ? (
                              <TextField
                                name="outputRegex"
                                variant="outlined"
                                value={inputField.outputRegex}
                                onChange={(event) =>
                                  handleRulesInput(inputField.id, event)
                                }
                                label="regex"
                                style={{ width: 250 }}
                              />
                            ) : (
                              <></>
                            )}
                            {rulesArray[index]["ruleType"] === "closest" ? (
                              <div>
                                <textarea
                                  name="list"
                                  variant="outlined"
                                  value={inputField.list}
                                  onChange={(event) =>
                                    handleRulesInput(inputField.id, event)
                                  }
                                  placeholder="[List]"
                                  rows={3}
                                  column={1}
                                />
                                <span>&nbsp;&nbsp;</span>
                                <TextField
                                  name="threshold"
                                  variant="outlined"
                                  value={inputField.threshold}
                                  onChange={(event) =>
                                    handleRulesInput(inputField.id, event)
                                  }
                                  label="Threshold"
                                  style={{ width: 250 }}
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                            {rulesArray[index]["ruleType"] === "ifReturn" ? (
                              <div>
                                <TextField
                                  name="phraseToBeChecked"
                                  variant="outlined"
                                  value={inputField.phraseToBeChecked}
                                  onChange={(event) =>
                                    handleRulesInput(inputField.id, event)
                                  }
                                  label="Phrase to be checked"
                                  style={{ width: 250 }}
                                />
                                <span>&nbsp;&nbsp;</span>
                                <TextField
                                  name="phraseToBeReturned"
                                  variant="outlined"
                                  value={inputField.phraseToBeReturned}
                                  onChange={(event) =>
                                    handleRulesInput(inputField.id, event)
                                  }
                                  label="Phrase to be returned"
                                  style={{ width: 250 }}
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                            {rulesArray[index]["ruleType"] === "equals" ? (
                              <TextField
                                name="fieldName"
                                variant="outlined"
                                value={inputField.fieldName}
                                onChange={(event) =>
                                  handleRulesInput(inputField.id, event)
                                }
                                label="fieldName"
                                style={{ width: 250 }}
                              />
                            ) : (
                              <></>
                            )}
                            {rulesArray[index]["ruleType"] === "ocrFind" ? (
                              <TextField
                                name="ocrRegex"
                                variant="outlined"
                                value={inputField.ocrRegex}
                                onChange={(event) =>
                                  handleRulesInput(inputField.id, event)
                                }
                                label="regex"
                                style={{ width: 250 }}
                              />
                            ) : (
                              <></>
                            )}
                            {rulesArray[index]["ruleType"] === "confSet" ? (
                              <div>
                                <TextField
                                  name="confRegex"
                                  variant="outlined"
                                  value={inputField.confRegex}
                                  onChange={(event) =>
                                    handleRulesInput(inputField.id, event)
                                  }
                                  label="regex"
                                  style={{ width: 250 }}
                                />
                                <span>&nbsp;&nbsp;</span>
                                <TextField
                                  name="confValue"
                                  variant="outlined"
                                  value={inputField.confValue}
                                  onChange={(event) =>
                                    handleRulesInput(inputField.id, event)
                                  }
                                  label="value"
                                  style={{ width: 250 }}
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                          </FormControl>
                          <IconButton
                            onClick={() => {
                              handleRemoveRulesFields(inputField.id);
                            }}
                          >
                            <RemoveIcon />
                          </IconButton>
                          <IconButton onClick={handleAddRulesFields}>
                            <AddIcon />
                          </IconButton>
                        </li>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </ul>
      </DragDropContext>
      <Button
        disabled={saved}
        onClick={handleSave}
        size="medium"
        color="primary"
        variant="contained"
      >
        {saved ? "Saved" : "Save"}
      </Button>
    </form>
  );
};

export default RulesSection;
