import { useState, useEffect, useRef } from "react";
import axios from 'axios';
import ReactJson from 'react-json-view';
import { useSnackbar } from 'notistack';
import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography, Grid, Card, CardContent, CardHeader, FormControl, InputLabel, Select, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Switch, FormControlLabel } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100vh',
        padding: theme.spacing(2),
    },
    column: {
        flex: 1,
        padding: theme.spacing(2),
        maxHeight: '80vh',
        overflowY: 'auto',
    },
    card: {
        marginBottom: theme.spacing(2),
    },
    cardHeader: {
        fontSize: '0.875rem',
    },
    filterControl: {
        marginBottom: theme.spacing(3),
        minWidth: 200,
    },
    table: {
        marginTop: theme.spacing(3),
    },
    tableHeader: {
        fontWeight: 'bold',
    },
    refreshControl: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
    },
    refreshButton: {
        marginLeft: theme.spacing(2),
    },
}));

function Tasks() {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [jobCount, setJobCount] = useState({});
    const [jobs, setJobs] = useState({});
    const [statusFilter, setStatusFilter] = useState('waiting');
    const [autoRefreshInterval, setAutoRefreshInterval] = useState(0);
    const [detailedOutput, setDetailedOutput] = useState(false); // Added state for detailed output toggle
    const refreshIntervalRef = useRef(null);

    const intervals = [3000 ,5000, 10000, 30000, 60000]; // 5s, 10s, 30s, 1min

    const queueNameMap = {
        classificationQueue: 'Classification OCR Queue',
        benchmarkQueue: 'Benchmark OCR Queue',
        projectQueue: 'Project OCR Queue',
        benchmarkInferenceQueue: 'Benchmark Inference Queue',
    };

    const fetchJobsData = async () => {
        try {
            const jobCountsResponse = await axios.get('/api/tasks/jobCounts');
            setJobCount(jobCountsResponse.data);

            const jobsResponse = await axios.post('/api/tasks/jobs', {
                queueNames: ['classificationQueue', 'benchmarkQueue', 'projectQueue', 'benchmarkInferenceQueue'],
                filters: ['active', 'completed', 'failed', 'delayed', 'waiting'],
            });
            setJobs(jobsResponse.data);
        } catch (e) {
            console.error("Error: ", e.response.data);
            enqueueSnackbar(e.response.data, {
                variant: 'error',
            });
        }
    };

    useEffect(() => {
        fetchJobsData();
    }, []);

    useEffect(() => {
        if (autoRefreshInterval > 0) {
            refreshIntervalRef.current = setInterval(() => {
                fetchJobsData();
            }, autoRefreshInterval);
        } else if (refreshIntervalRef.current) {
            clearInterval(refreshIntervalRef.current);
            refreshIntervalRef.current = null;
        }
        return () => clearInterval(refreshIntervalRef.current);
    }, [autoRefreshInterval]);

    const handleRefresh = () => {
        fetchJobsData();
    };

    const handleIntervalChange = (event) => {
        setAutoRefreshInterval(Number(event.target.value));
    };

    const handleToggleChange = () => {
        setDetailedOutput(!detailedOutput);
    };

    const groupJobs = (queueName, jobs) => {
        const groupBy = {
            classificationQueue: job => job.data.groupId || 'ungrouped',
            projectQueue: job => job.data.project._id || 'ungrouped',
            benchmarkInferenceQueue: job => job.data.id || 'ungrouped',
            benchmarkQueue: job => job.data.benchmarkId || 'ungrouped',
        };
    
        const groupFunction = groupBy[queueName] || (() => 'all');
    
        return jobs.reduce((groups, job) => {
            const group = groupFunction(job);
            if (!groups[group]) {
                groups[group] = [];
            }
            groups[group].push(job);
            return groups;
        }, {});
    };

    const renderJobCard = (job) => (
        <Card className={classes.card} key={job.id || job.fileKey || job.imageKey}>
            <CardHeader title={job.fileKey || job.imageKey} subheader={job.type || job.docType || job.projectType} />
                <ReactJson src={job} collapsed={false} theme="monokai" />
        </Card>
    );

    const renderGroupedJobs = (queueName) => {
        const groupedJobs = groupJobs(queueName, jobs[queueName]?.[statusFilter] || []);
        return Object.keys(groupedJobs).map(group => (
            <Card className={classes.card} key={group}>
            <CardHeader
                title={`ID: ${group}`}
                subheader={`Count: ${groupedJobs[group].length}`}
                classes={{ title: classes.cardHeader, subheader: classes.cardHeader }}
            />
            {detailedOutput ? <CardContent>{groupedJobs[group].map(renderJobCard)}</CardContent> : null}
        </Card>
        ));
    };

    const renderJobCountTable = () => {
        if (!Object.keys(jobCount).length) {
            return <Typography variant="body1">No data available</Typography>;
        }

        return (
            <TableContainer component={Paper} className={classes.table}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {['Queue Name', 'Active', 'Completed', 'Failed', 'Delayed', 'Waiting'].map(header => (
                                <TableCell key={header} className={classes.tableHeader}>{header}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(jobCount).map((queueName) => (
                            <TableRow key={queueName}>
                                <TableCell>{queueNameMap[queueName]}</TableCell>
                                {['active', 'completed', 'failed', 'delayed', 'waiting'].map(status => (
                                    <TableCell key={status}>{jobCount[queueName][status]}</TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    return (
        <Container className={classes.root}>
            <Typography variant="h4" gutterBottom>Tasks</Typography>
            <div className={classes.refreshControl}>
                <Typography variant="h6" gutterBottom style={{ paddingRight: '16px' }}>
                    Auto Refresh Interval:
                </Typography>
                <FormControl variant="outlined" className={classes.filterControl}>
                    <InputLabel>Interval</InputLabel>
                    <Select
                        value={autoRefreshInterval}
                        onChange={handleIntervalChange}
                        label="Interval"
                    >
                        {intervals.map(interval => (
                            <MenuItem key={interval} value={interval}>
                                {`${interval / 1000} seconds`}
                            </MenuItem>
                        ))}
                        <MenuItem value={0}>Stopped</MenuItem>
                    </Select>
                </FormControl>
                <Button variant="contained" color="primary" onClick={handleRefresh} className={classes.refreshButton}>
                    Refresh Now
                </Button>
            </div>
            <Typography variant="h6" gutterBottom>Job Count</Typography>
            {renderJobCountTable()}
            <br /> <br />
            <Grid container spacing={2} alignItems="center">
                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                    <FormControl variant="outlined" className={classes.filterControl}>
                        <InputLabel>Status</InputLabel>
                        <Select
                            value={statusFilter}
                            onChange={(e) => setStatusFilter(e.target.value)}
                            label="Status"
                        >
                            {['active', 'completed', 'failed', 'delayed', 'waiting'].map(status => (
                                <MenuItem key={status} value={status}>{status}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                    <FormControlLabel
                        control={<Switch checked={detailedOutput} onChange={handleToggleChange} />}
                        label="Detailed Output"
                        style={{ margin: 0 }} // Ensure no extra margin is added
                    />
                </Grid>
            </Grid>
            
            <Grid container spacing={2}>
                {Object.keys(queueNameMap).map((queue) => (
                    <Grid item xs={3} className={classes.column} key={queue}>
                        <Typography variant="h6" gutterBottom>
                            {queueNameMap[queue]} ({jobCount[queue]?.[statusFilter]})
                        </Typography>
                        {renderGroupedJobs(queue)}
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}

export default Tasks;