import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from "@material-ui/core/styles";

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const Benchmark = (props) => {
    const benchmark = props.benchmark
    return (
        <div style={{ marginTop: 25, textAlign: 'left', paddingLeft: 50, paddingRight: 50, paddingBottom: 50 }}><h2>Test Set Metrics - OCR GT</h2><br></br>
            <TableContainer>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Key</StyledTableCell>
                            <StyledTableCell>F1 score</StyledTableCell>
                            <StyledTableCell>Precision</StyledTableCell>
                            <StyledTableCell>Recall</StyledTableCell>
                            <StyledTableCell>Support</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {benchmark && Object.keys(benchmark).map(key => (
                            <StyledTableRow key={key}>
                                <StyledTableCell component="th" scope="row">{key}</StyledTableCell>
                                <StyledTableCell align="left">{parseFloat(benchmark[key]['f1-score']).toPrecision(4)}</StyledTableCell>
                                <StyledTableCell align="left">{parseFloat(benchmark[key]['precision']).toPrecision(4)}</StyledTableCell>
                                <StyledTableCell align="left">{parseFloat(benchmark[key]['recall']).toPrecision(4)}</StyledTableCell>
                                <StyledTableCell align="left">{benchmark[key]['support']}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};
export default Benchmark;