import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { useSnackbar } from "notistack";
import {
    Container,
    Dialog,
    DialogActions,
    DialogTitle,
    Button,
    Grid,
    Box,
    CircularProgress,
    TablePagination,
    IconButton,
    Checkbox,
    FormControl,
    TextField,
    MenuItem,
    DialogContent
} from "@material-ui/core";
import { Replay, ArrowBack, Remove, Add } from '@material-ui/icons';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import FileUpload from "./Upload";
import Benchmark from "./Benchmark";
import ValidationConfig from "./ValidationConfig";
import Rules from "./Rules";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useDispatch, useSelector } from 'react-redux';
import { savePage } from "../../actions/pageActions";
import ImageCard from "./assets/ImageCard";
import TestURL from "./assets/TestURL";
import DefaultModel from "./assets/DefaultModel";
import ConfModal from "./assets/ConfModal";
import PostProcessingModal from "./assets/PostProcessingModal";
import Header from "./assets/Header";
import { v4 as uuidv4 } from "uuid";
import { ocrTypes } from "../../constants";

const ColorButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(grey[900]),
        backgroundColor: grey[900],
        "&:hover": {
            backgroundColor: grey[900],
        },
        height: 50,
        minWidth: 100,
    },
}))(Button);

const useStyles = makeStyles({
    cards: {
        marginTop: 50,
        marginLeft: 25,
    }
});

function Project(props) {
    const dispatch = useDispatch();
    const pageNumber = useSelector(
        (state) => state.page.pageNumber
    );
    const pageSize = useSelector(
        (state) => state.page.pageSize
    );

    const [recreate, setRecreate] = useState(false);
    const [isLocked, setIsLocked] = useState(false);
    const [tagToDelete, setTagToDelete] = useState("");
    const [tags, setTags] = useState([]);
    const [openIsLocked, setOpenIsLocked] = useState(false);
    const [status, setStatus] = useState("");
    const [name, setName] = useState("");
    const [fieldsForHallucinationCheck, setFieldsForHallucinationCheck] = useState([]);
    const [percent, setPercent] = useState(0);
    const [openUpload, setOpenUpload] = useState(false);
    const [openConfig, setOpenConfig] = useState(false);
    const [openPostProcessing, setOpenPostProcessing] = useState(false);
    const [openRules, setOpenRules] = useState(false);
    const [openBenchmark, setOpenBenchmark] = useState(false);
    const [openDeleteTag, setOpenDeleteTag] = useState(false);
    const [postProcessingURL, setPostProcessingURL] = useState("");
    const [confThreshold, setConfThreshold] = useState({});
    const [openConfThreshold, setOpenConfThreshold] = useState(false);
    const [modelGenerateDialog, setModelGenerateDialog] = useState(false);
    const [benchmark, setBenchmark] = useState({});
    const [validationConfig, setValidationConfig] = useState({});
    const [modelName, setModelName] = useState('');
    const [modelNameOpen, setModelNameOpen] = useState(false);
    const [uploadType, setUploadType] = useState('');
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [benchmarkLoading, setBenchmarkLoading] = useState(false);
    const [completedOcr, setCompletedOcr] = useState(0);
    const [completedAnnotations, setCompletedAnnotations] = useState(0);
    const [flags, setFlags] = useState({});
    const [flagged, setFlagged] = useState(false);
    const [openCurl, setOpenCurl] = useState(false);
    const [openClone, setOpenClone] = useState(false);
    const [ocr, setOCR] = useState("google");
    const [translate, setTranslate] = useState(false);
    const [sorting, setSorting] = useState(false);
    const [version, setVersion] = useState("3");
    const [modelVersion, setModelVersion] = useState("1");
    const [lexModel, setLexModel] = useState("openai:gpt-3.5-turbo");
    const [newLexModel, setNewLexModel] = useState("openai:gpt-3.5-turbo");
    const [newName, setNewName] = useState("");
    const [showUpdateMessage, setShowUpdateMessage] = useState(false);
    const [prompt, setPrompt] = useState("");
    const classes = useStyles();
    const projectType = props.location.state.projectType;
    const { enqueueSnackbar } = useSnackbar();

    const [newOCRType, setNewOCRType] = useState("");
    const [promptForClone, setPromptForClone] = useState("");
    const [promptSelect, setPromptSelect] = useState("prompt1");
    const [openLexNext, setOpenLexNext] = useState(false);
    const [inputFields, setInputFields] = useState([
        { id: uuidv4(), field: "", validationType: "", description: "", hallucinationCheck: true },
    ]);
    const [menuMode, setMenuMode] = useState("clone");

    async function modelPercent() {
        try {
            const response = await axios.post("/api/projects/progressCheck", {
                email: props.auth.user.email,
                projectId: props.location.state.project,
            });
            setPercent(response.data);
            if (response.data === 100) window.location.reload();
        } catch (err) {
            console.log(err);
        }
    }

    async function getStatus() {
        try {
            const response = await axios.post("/api/projects/status", {
                projectId: props.location.state.project,
            });
            const fieldsForHallucinationCheck = response.data.fieldConfigs.filter((item) => item.hallucinationCheck).map((item) => item.field);
            setInputFields(response.data.fieldConfigs.map((item) => {
                return {
                    id: uuidv4(),
                    field: item.field,
                    description: item.description,
                    hallucinationCheck: item.hallucinationCheck,
                }
            }));
            setStatus(response.data.status);
            setTags(response.data.tags);
            setName(response.data.name);
            setNewName(response.data.name + " clone");
            setModelName(response.data.default_model);
            setOCR(response.data.ocr);
            setNewOCRType(response.data.ocr);
            setTranslate(response.data.translate);
            setSorting(response.data.sorting);
            setModelVersion(response.data.version);
            setVersion(response.data.version);
            setPrompt(response.data.prompt);
            setPromptForClone(response.data.prompt);
            setLexModel(response.data.lexModel);
            setFieldsForHallucinationCheck(fieldsForHallucinationCheck);
            setIsLocked(response.data.isLocked);
            const updatedAt = new Date(response.data?.updatedAt);
            const diffInMins = Math.floor(((new Date() - updatedAt) / 1000) / 60);
            if (diffInMins < 15) {
                setShowUpdateMessage(true);
            }
            if (response.data.postProcessingURL)
                setPostProcessingURL(response.data.postProcessingURL);
            if (response.data.confThreshold)
                setConfThreshold(response.data.confThreshold);
            else {
                const fields = response.data.fieldConfigs.map((item) => item.field);
                const confFields = fields.reduce((a, v) => ({ ...a, [v]: 0.75 }), {})
                setConfThreshold(confFields);
            }
        } catch (err) {
            enqueueSnackbar(err.response.data, {
                variant: "error",
            });
        }
    }

    async function fetchConfig() {
        try {
            const response = await axios.post("/api/projects/fetchConfig", {
                projectId: props.location.state.project,
            });
            setValidationConfig(response.data);
        } catch (err) {
            enqueueSnackbar(err.response.data, {
                variant: "error",
            });
        }
    }

    const handleLock = async () => {
        try {
            setOpenIsLocked(false);
            const response = await axios.post("api/projects/lock", {
                projectId: props.location.state.project,
            });
            if (response) {
                enqueueSnackbar(response.data, {
                    variant: "success",
                });
            }
            getStatus()
        } catch (error) {
            enqueueSnackbar(error.response.data, {
                variant: "error",
            });
        }
    };

    const handleTranslate = async () => {
        try {
            const response = await axios.post('/api/projects/translation', {
                projectId: props.location.state.project,
                value: !translate,
            });
            if (response) {
                setTranslate(!translate);
                console.log(response);
                enqueueSnackbar(response.data, {
                    variant: "success",
                });
            }
        } catch (err) {
            console.log('Translate error', err);
            enqueueSnackbar(err.response.data, {
                variant: "error",
            });
        }
    }

    const deleteTagSetter = (tag) => {
        setTagToDelete(tag);
        setOpenDeleteTag(true);
    }

    const handleSorting = async () => {
        try {
            const response = await axios.post('/api/projects/sorting', {
                projectId: props.location.state.project,
                value: !sorting,
            });
            if (response) {
                setSorting(!sorting);
                console.log(response);
                enqueueSnackbar(response.data, {
                    variant: "success",
                });
            }
        } catch (err) {
            console.log('Sorting error', err);
            enqueueSnackbar(err.response.data, {
                variant: "error",
            });
        }
    }

    const handleModel = async () => {
        try {
            closeModelGenerateDialog(false);
            const response = await axios.post("api/projects/generate", {
                projectId: props.location.state.project,
                recreate,
            });
            if (response) {
                enqueueSnackbar("Running Model", {
                    variant: "success",
                });
            }
        } catch (error) {
            enqueueSnackbar(error.response.data, {
                variant: "error",
            });
        }
    };

    const handleClone = async () => {
        try {
            handleCloneClose();
            const response = await axios.post("api/projects/clone", {
                projectId: props.location.state.project,
                version,
                name: newName,
                inputFields,
                promptForClone,
                ocr: newOCRType,
                lexModel: newLexModel,
            });
            if (response) {
                enqueueSnackbar("Project cloned", {
                    variant: "success",
                });
            }
        } catch (error) {
            enqueueSnackbar(error.response.data, {
                variant: "error",
            });
        }
    };

    const handleUpdate = async () => {
        try {
            handleCloneClose();
            const response = await axios.post("api/projects/updateProject", {
                projectId: props.location.state.project,
                inputFields,
                promptForClone,
                ocr: newOCRType,
                lexModel: newLexModel,
            });
            if (response) {
                enqueueSnackbar("Project updated", {
                    variant: "success",
                });
                getStatus();
            }
        } catch (error) {
            enqueueSnackbar(error.response.data, {
                variant: "error",
            });
        }
    };

    const updatePromptValue = (fieldValues, promptStyle = promptSelect) => {
        let result;
        if (promptStyle === "prompt1") {
            result = fieldValues.map(({ field, description }) => `${field} (${description})`).join(', ');
        } else if (promptStyle === "prompt2") {
            result = fieldValues.map(({ field }) => `${field}`).join(', ');
        }
        if (result) {
            setPromptForClone(`Extract information as a JSON:\nExtract: ${result}\n\${ocr}`);
        }
    }

    const handleChangeLexInput = (id, event) => {
        const newInputFields = inputFields.map((i) => {
            if (id === i.id) {
                i[event.target.name] = event.target.value;
            }
            return i;
        });
        setInputFields(newInputFields);
        updatePromptValue(newInputFields);
    };

    const handlePromptSelect = (e) => {
        setPromptSelect(e.target.value);
        updatePromptValue(inputFields, e.target.value);
    };

    const handleHallucinationCheck = (id, e) => {
        const newInputFields = inputFields.map((i) => {
            if (id === i.id) {
                i[e.target.name] = e.target.checked;
            }
            return i;
        });
        setInputFields(newInputFields);
    }

    const handleRemoveFields = (id) => {
        const values = [...inputFields];
        values.splice(
            values.findIndex((value) => value.id === id),
            1
        );
        setInputFields(values);
        updatePromptValue(values);
    };

    const handleAddFields = () => {
        setInputFields([
            ...inputFields,
            { id: uuidv4(), field: "", validationType: "", description: "", hallucinationCheck: true },
        ]);
    };

    const handleChangeLexPrompt = (event) => {
        setPromptForClone(event.target.value);
    };

    const handleBack = () => {
        setOpenClone(true);
        setOpenLexNext(false);
    };

    const handleOpenNext = () => {
        setOpenLexNext(true);
        setOpenClone(false);
    };

    const handleOcrType = (e) => {
        setNewOCRType(e.target.value);
    };

    const handleBenchmark = async () => {
        try {
            setBenchmarkLoading(true);
            const response = await axios.post("api/projects/getBenchmarks", {
                projectId: props.location.state.project,
            });
            setBenchmark(response.data);
            setOpenBenchmark(true);
        } catch (err) {
            enqueueSnackbar(err.response.data, {
                variant: "error",
            });
        }
        setBenchmarkLoading(false);
    };

    const getImages = async () => {
        try {
            setLoading(true);
            const response = await axios.post('api/projects/images/list', {
                projectId: props.location.state.project,
            });
            response.data.images.sort((a, b) => (Number(a.key.split('/').pop().split('.')[0]) > Number(b.key.split('/').pop().split('.')[0]) ? 1 : -1));
            setImages(response.data.images);
        } catch (err) {
            enqueueSnackbar(err.response.data, {
                variant: "error",
            });
        }
        setLoading(false);
    }

    const getFlags = async () => {
        try {
            const flagsResponse = await axios.post('/api/projects/flags/view', {
                projectId: props.location.state.project,
            });
            setFlags(flagsResponse.data);
        } catch (err) {
            console.log('Get flags error', err);
        }
    }

    const refreshData = async () => {
        getStatus();
        getImages();
    }

    const handleGoBack = () => {
        props.history.push({
            pathname: '/',
            state: {
                type: props.location.state.docType,
            }
        })
    };

    const handleClickOpen = (type) => {
        setUploadType(type);
        setOpenUpload(true);
    };

    const handlCloseIsLocked = () => {
        setOpenIsLocked(false);
    };

    const handlOpenIsLocked = () => {
        setOpenIsLocked(true);
    };

    const handleClose = () => {
        setOpenUpload(false);
    };

    const handlePostProcessingOpen = () => {
        setOpenPostProcessing(true);
    };

    const handlePostProcessingClose = () => {
        setOpenPostProcessing(false);
    };

    const handleConfThresholdOpen = () => {
        setOpenConfThreshold(true);
    };

    const handleConfThresholdClose = () => {
        setOpenConfThreshold(false);
    };

    const handleConfigOpen = () => {
        setOpenConfig(true);
    };

    const handleCloseConfig = () => {
        setOpenConfig(false);
    };

    const handleOpenRules = () => {
        setOpenRules(true);
    };

    const handleCloseRules = () => {
        setOpenRules(false);
    };

    const handleCloseBenchmark = () => {
        setOpenBenchmark(false);
    };

    const openModelGenerateDialog = () => {
        setModelGenerateDialog(true);
    };

    const closeModelGenerateDialog = () => {
        setModelGenerateDialog(false);
    };

    const handlePostProcessingURL = (event) => {
        setPostProcessingURL(event.target.value);
    };

    const handleConfThreshold = (event) => {
        setConfThreshold({ ...confThreshold, [event.target.name]: parseFloat(event.target.value) });
    };

    const handleModelName = (event) => {
        setModelName(event.target.value);
    }

    const handleModelNameOpen = () => {
        setModelNameOpen(true);
    }

    const handleModelNameClose = () => {
        setModelNameOpen(false);
    }

    const handleCurlOpen = () => {
        setOpenCurl(true);
    }

    const handleCurlClose = () => {
        setOpenCurl(false);
    }

    const handleRecreate = (e) => {
        setRecreate(e.target.checked);
    };

    const handleCloneOpen = () => {
        setMenuMode("clone");
        setOpenClone(true);
    }

    const handleUpdateOpen = () => {
        setMenuMode("update");
        setOpenClone(true);
    }

    const handleCloneClose = () => {
        setOpenClone(false);
        setOpenLexNext(false);
    }

    const handleVersion = (e) => {
        setVersion(e.target.value)
    }

    const handleNewLexModel = (e) => {
        setNewLexModel(e.target.value)
    }

    const handleName = (e) => {
        setNewName(e.target.value);
    };

    useEffect(() => {
        let intervalId;
        if (status === "model_generating") {
            intervalId = setInterval(modelPercent, 10000);
        }
        return () => {
            clearInterval(intervalId);
        };
    }, [status]);

    useEffect(() => {
        if (props.location !== undefined) {
            getStatus();
            fetchConfig();
            getImages();
            getFlags();
        }
    }, []);

    useEffect(() => {
        let ocrCount = 0;
        let annotationCount = 0;
        images.forEach(image => {
            if (image.ocr) {
                ocrCount++;
            }
            if (image.annotation) {
                annotationCount++;
            }
        });
        setCompletedOcr(ocrCount);
        setCompletedAnnotations(annotationCount);
    }, [completedAnnotations, completedOcr, images]);

    //***   LOGIC FOR PAGINATION  ** */
    const handleAnnotate = (id) => {
        props.history.push({
            pathname: '/annotate',
            state: {
                ...state,
                project: state.project,
                id: id,
                type: 'new'
            }
        })
    };

    const handleViewAnnotation = (id) => {
        let taskList = [];
        images.forEach((obj) => {
            if (obj.annotation) {
                const imageId = obj.key.split('/')[2].split('.')[0];
                taskList.push(imageId);
            }

        });
        props.history.push({
            pathname: '/viewAnnotation',
            state: {
                ...state,
                project: state.project,
                id: id,
                taskList,
            }
        })
    }

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    useEffect(() => {
        setPage(pageNumber);
        setRowsPerPage(pageSize);
    }, [])
    const offset = page * rowsPerPage;
    const currentPageData = images
        .slice(offset, offset + rowsPerPage)
        .map((image, index) => {
            const imageId = image.key.split('/')[2].split('.')[0];
            const card = <ImageCard
                image={image}
                index={index}
                handleViewAnnotation={handleViewAnnotation}
                handleAnnotate={handleAnnotate}
                getImages={getImages}
                flags={flags}
                projectId={props.location.state.project}
            />
            if (flagged) {
                if (flags[imageId] === true)
                    return card;
                return '';
            }
            return card;
        })

    const handleChangePage = (event, newPage) => {
        dispatch(savePage(newPage, rowsPerPage));
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        dispatch(savePage(page, event.target.value));
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    //***************************** */

    const { state } = props.location;
    if (state === undefined) {
        return <Redirect to="/" />;
    }

    const handleTest = () => {
        props.history.push({
            pathname: "/test",
            state,
        });
    };

    return (
        <div>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <ColorButton
                startIcon={<ArrowBack />}
                onClick={handleGoBack}
                variant='contained'
                color='primary'
            >
                Go back
            </ColorButton>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <ColorButton
                startIcon={<Replay />}
                onClick={() => refreshData()}
                variant="contained"
                color="primary"
            >
                Refresh
            </ColorButton>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <ColorButton
                startIcon={isLocked ? <LockOpenIcon /> : <LockIcon />}
                onClick={handlOpenIsLocked}
                variant='contained'
                color='primary'
            >
                {isLocked ? "Unlock project" : "Lock project"}
            </ColorButton>
            <p></p>
            <Header
                name={name}
                project={props.location.state.project}
                ocr={ocr}
                completedOcr={completedOcr}
                completedAnnotations={completedAnnotations}
                images={images}
                version={modelVersion}
                prompt={prompt}
                lexModel={lexModel}
                fieldsForHallucinationCheck={fieldsForHallucinationCheck}
                isLocked={isLocked}
                tags={tags}
                deleteTagSetter={deleteTagSetter}
            />
            {showUpdateMessage ? (
                <>
                    <div style={{ color: "red", margin: '0px 0px 30px 30px' }}>The changes may take up to 15 minutes to reflect</div>
                </>
            ) : (
                <></>
            )}
            <p></p>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <IconButton style={{ padding: '0px' }}>
                <FiberManualRecordIcon style={{ color: "black", transform: 'rotate(90deg)' }} />
            </IconButton>
            <IconButton style={{ padding: '0px' }}>
                <Remove style={{ color: "black" }} />
            </IconButton>
            <ColorButton
                onClick={handleTranslate}
                variant="contained"
                color="primary"
            >
                {translate ? 'Disable translation' : 'Enable translation'}
            </ColorButton>
            <IconButton style={{ padding: '0px' }}>
                <Remove style={{ color: "black" }} />
            </IconButton>
            <ColorButton
                onClick={handleSorting}
                variant="contained"
                color="primary"
            >
                {sorting ? 'Disable sorting' : 'Enable sorting'}
            </ColorButton>
            <IconButton style={{ padding: '0px' }}>
                <Remove style={{ color: "black" }} />
            </IconButton>
            <ColorButton
                onClick={() => handleClickOpen('folder')}
                variant="contained"
                color="primary"
            >
                Upload folder
            </ColorButton>
            <IconButton style={{ padding: '0px' }}>
                <Remove style={{ color: "black" }} />
            </IconButton>
            <ColorButton
                onClick={() => handleClickOpen('files')}
                variant='contained'
                color='primary'
            >
                Upload Images
            </ColorButton>
            <IconButton style={{ padding: '0px' }}>
                <Remove style={{ color: "black" }} />
            </IconButton>
            <ColorButton
                onClick={() => handleClickOpen('zip')}
                variant='contained'
                color='primary'
            >
                Upload Zip
            </ColorButton>
            <IconButton style={{ padding: '0px' }}>
                <Remove style={{ color: "black" }} />
            </IconButton>
            <ColorButton
                onClick={() => setFlagged(!flagged)}
                variant="contained"
                color="primary"
                disabled={!images.length}
            >
                {flagged ? 'All images' : 'Flagged images'}
            </ColorButton>
            <p></p>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <IconButton style={{ padding: '0px' }}>
                <FiberManualRecordIcon style={{ color: "black", transform: 'rotate(90deg)' }} />
            </IconButton>
            {projectType !== 'validation' && <>
                <IconButton style={{ padding: '0px' }}>
                    <Remove style={{ color: "black" }} />
                </IconButton>
                <ColorButton
                    onClick={openModelGenerateDialog}
                    variant="contained"
                    color="primary"
                    disabled={status === 'model_generating'}
                >
                    {status !== "model_generated"
                        ? "Generate Model"
                        : "Retrain Model"}
                </ColorButton>
                <IconButton style={{ padding: '0px' }}>
                    <Remove style={{ color: "black" }} />
                </IconButton>
                <ColorButton
                    onClick={handleModelNameOpen}
                    variant="contained"
                    color="primary"
                    disabled={status !== 'model_generated'}
                >
                    Set Default Model
                </ColorButton>
            </>}
            <p></p>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <IconButton style={{ padding: '0px' }}>
                <FiberManualRecordIcon style={{ color: "black", transform: 'rotate(90deg)' }} />
            </IconButton>
            <IconButton style={{ padding: '0px' }}>
                <Remove style={{ color: "black" }} />
            </IconButton>
            <ColorButton
                onClick={handlePostProcessingOpen}
                variant="contained"
                color="primary"
                disabled={status !== 'model_generated' && projectType !== 'validation'}
            >
                {postProcessingURL === ""
                    ? "Set Post Processing URL"
                    : "Update Post Processing URL"}
            </ColorButton>
            <IconButton style={{ padding: '0px' }}>
                <Remove style={{ color: "black" }} />
            </IconButton>
            <ColorButton
                onClick={handleConfThresholdOpen}
                variant="contained"
                color="primary"
                disabled={status !== 'model_generated' && projectType !== 'validation'}
            >
                Set Conf Threshold
            </ColorButton>
            {projectType !== "validation" ? (
                <>
                    <IconButton style={{ padding: '0px' }}>
                        <Remove style={{ color: "black" }} />
                    </IconButton>
                    <ColorButton
                        onClick={handleOpenRules}
                        variant="contained"
                        color="primary"
                        disabled={status !== 'model_generated'}
                    >
                        Set/Edit Rules
                    </ColorButton>
                </>
            ) : (
                <></>
            )}
            {projectType && projectType !== "extraction" ? (
                <>
                    <IconButton style={{ padding: '0px' }}>
                        <Remove style={{ color: "black" }} />
                    </IconButton>
                    <ColorButton
                        onClick={handleConfigOpen}
                        variant="contained"
                        color="primary"
                    >
                        Edit Validation Config
                    </ColorButton>
                </>
            ) : (
                <></>
            )}
            {version === "lex" ? (
                <>
                    <IconButton style={{ padding: '0px' }}>
                        <Remove style={{ color: "black" }} />
                    </IconButton>
                    <ColorButton
                        onClick={handleUpdateOpen}
                        variant="contained"
                        color="primary"
                    >
                        Update Project Configuration
                    </ColorButton>
                </>
            ) : <></>}
            <p></p>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <IconButton style={{ padding: '0px' }}>
                <FiberManualRecordIcon style={{ color: "black", transform: 'rotate(90deg)' }} />
            </IconButton>
            <IconButton style={{ padding: '0px' }}>
                <Remove style={{ color: "black" }} />
            </IconButton>
            <ColorButton
                onClick={handleTest}
                variant="contained"
                color="primary"
                disabled={status !== "model_generated" && projectType !== "validation"}
            >
                Test Model
            </ColorButton>
            <IconButton style={{ padding: '0px' }}>
                <Remove style={{ color: "black" }} />
            </IconButton>
            <ColorButton
                onClick={handleCurlOpen}
                variant="contained"
                color="primary"
            >
                Testing URL
            </ColorButton>
            <IconButton style={{ padding: '0px' }}>
                <Remove style={{ color: "black" }} />
            </IconButton>
            <ColorButton
                onClick={handleCloneOpen}
                variant="contained"
                color="primary"
            >
                Clone
            </ColorButton>
            {projectType !== "validation" ? (
                <>
                    <IconButton style={{ padding: '0px' }}>
                        <Remove style={{ color: "black" }} />
                    </IconButton>
                    <ColorButton
                        onClick={handleBenchmark}
                        variant="contained"
                        color="primary"
                        disabled={
                            status !== "model_generated" || projectType === "validation"
                        }
                    >
                        {benchmarkLoading ? <CircularProgress /> : 'Test Set Metrics'}
                    </ColorButton>
                </>
            ) : (
                <></>
            )}
            <p></p>
            {status === "model_generating" ? (
                <>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <div style={{ color: "red", display: "inline-block" }}>
                        Generating Model...
                        <br /> Progress: {percent}%
                    </div>
                </>
            ) : (
                ""
            )}
            <p></p>
            <TablePagination
                component="div"
                count={images.length}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[10, 25, 50, 100, 150, 200, 250, 300, 400, 500]}
            />
            <Grid container className={classes.cards}>
                {loading ? <Box sx={{ display: 'flex' }}>
                    <CircularProgress />
                </Box> : images.length === 0
                    ? 'No Existing images'
                    : <>
                        {currentPageData}
                    </>
                }
            </Grid>
            <Dialog
                open={openIsLocked}
                onClose={handlCloseIsLocked}
                aria-labelledby="create-group"
                maxWidth='md'
            >
                <DialogTitle id="togglelock-project">
                    Are you sure you want to {isLocked ? "unlock" : "lock"} this project ?
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handlCloseIsLocked} variant="contained" color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleLock} variant="contained" color="default">
                        {isLocked ? "unlock" : "lock"} ({isLocked ? <LockOpenIcon /> : <LockIcon />})
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openUpload} onClose={handleClose} aria-labelledby="upload">
                <FileUpload
                    projectId={state.project}
                    type={uploadType}
                    uploadData='project'
                    setOpenUpload={setOpenUpload}
                    getImages={getImages}
                />
            </Dialog>
            <Dialog
                open={openBenchmark}
                onClose={handleCloseBenchmark}
                aria-labelledby="benchmark"
                fullWidth={true}
                maxWidth="lg"
            >
                <Benchmark benchmark={benchmark} />
            </Dialog>
            <Dialog
                open={openRules}
                onClose={handleCloseRules}
                aria-labelledby="config"
                fullWidth={true}
                maxWidth="lg"
            >
                <Rules project={state.project} setOpenRules={setOpenRules} />
            </Dialog>
            <Dialog
                open={openConfig}
                onClose={handleCloseConfig}
                aria-labelledby="config"
                fullWidth={true}
                maxWidth="lg"
            >
                <ValidationConfig config={validationConfig} project={state.project} />
            </Dialog>
            <PostProcessingModal
                openPostProcessing={openPostProcessing}
                setOpenPostProcessing={setOpenPostProcessing}
                handlePostProcessingClose={handlePostProcessingClose}
                postProcessingURL={postProcessingURL}
                handlePostProcessingURL={handlePostProcessingURL}
                project={props.location.state.project}
            />
            <ConfModal
                openConfThreshold={openConfThreshold}
                setOpenConfThreshold={setOpenConfThreshold}
                handleConfThresholdClose={handleConfThresholdClose}
                confThreshold={confThreshold}
                handleConfThreshold={handleConfThreshold}
                project={props.location.state.project}
            />
            <Dialog
                open={modelGenerateDialog}
                onClose={closeModelGenerateDialog}
                aria-labelledby="config"
            >
                <DialogTitle id="generate-model">
                    Are you sure you want to start model generation ?
                </DialogTitle>
                <DialogActions>
                    <Checkbox
                        checked={recreate}
                        onChange={handleRecreate}
                        inputProps={{ "aria-label": "primary checkbox" }}
                        required
                    />
                    <span>Recreate Completion files</span>
                    <ColorButton onClick={handleModel} variant="contained">
                        Confirm
                    </ColorButton>
                </DialogActions>
            </Dialog>
            <DefaultModel
                modelNameOpen={modelNameOpen}
                setModelNameOpen={setModelNameOpen}
                handleModelNameClose={handleModelNameClose}
                modelName={modelName}
                handleModelName={handleModelName}
                project={props.location.state.project}
            />
            <TestURL
                openCurl={openCurl}
                handleCurlClose={handleCurlClose}
                project={props.location.state.project}
            />
            <Dialog // Clone Dialog
                open={openClone}
                onClose={handleCloneClose}
                aria-labelledby="config"
            >
                <DialogTitle id="clone-project">
                    {menuMode === "clone" ? "Clone" : "Update Project Configuration"}
                </DialogTitle>
                <DialogContent>
                    <FormControl variant="outlined" style={{ marginTop: 14 }}>
                        {menuMode === "clone" ? <>
                            <TextField
                                required
                                autoFocus
                                margin="normal"
                                id="name"
                                label="Project Name"
                                type="text"
                                fullWidth
                                variant="outlined"
                                onChange={handleName}
                                value={newName}
                            />
                            <TextField
                                select
                                value={version}
                                onChange={handleVersion}
                                label="Version"
                                variant="outlined"
                                style={{ width: 250 }}
                            >
                                {!modelVersion.includes("lex") ? <MenuItem value="1">1</MenuItem> : <></>}
                                {!modelVersion.includes("lex") ? <MenuItem value="3">3</MenuItem> : <></>}
                                {modelVersion.includes("lex") ? <MenuItem value="lex">lex</MenuItem> : <></>}
                            </TextField>
                        </> : <></>}
                        {version === "lex" ? <TextField
                            value={newLexModel}
                            onChange={handleNewLexModel}
                            label="Lex Model Name"
                            variant="outlined"
                            style={{ width: 250, marginTop: 14 }}
                        /> : <></>}
                        {version === "lex" ? <TextField
                            select
                            value={newOCRType}
                            onChange={handleOcrType}
                            label="OCR type"
                            variant="outlined"
                            style={{ width: 250, marginTop: 14 }}
                        >
                            {Object.keys(ocrTypes).map((ocrType) => (
                                <MenuItem value={ocrType}>{ocrTypes[ocrType]}</MenuItem>
                            ))}
                        </TextField> : <></>}
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloneClose} variant="contained" color="secondary">
                        Cancel
                    </Button>
                    {version === "lex" ? <Button onClick={handleOpenNext} variant="contained" color="primary">
                        Next
                    </Button> : <Button onClick={handleClone} variant="contained" color="primary">
                        Clone
                    </Button>}
                </DialogActions>
            </Dialog>

            <Dialog // Configure Prompt for lex
                open={openLexNext}
                onClose={handleCloneClose}
                aria-labelledby="create-project"
                maxWidth="xl"
            >
                <DialogTitle id="configure-prompt" style={{ marginLeft: 20 }} >Configure Prompt</DialogTitle>
                <DialogContent>
                    <Container>
                        <TextField
                            select
                            value={promptSelect}
                            onChange={handlePromptSelect}
                            label="Prompt Select"
                            variant="outlined"
                            style={{ width: 250 }}
                        >
                            <MenuItem value="prompt1">Standard Prompt 1</MenuItem>
                            <MenuItem value="prompt2">Standard Prompt 2</MenuItem>
                            <MenuItem value="custom">Custom Prompt</MenuItem>
                        </TextField>
                        <form>
                            {inputFields.map((lexInputField) => (
                                <div key={lexInputField.id} style={{ marginBottom: 10 }}>
                                    <FormControl style={{ marginTop: 15 }}>
                                        <TextField
                                            name="field"
                                            label="Field Name*"
                                            variant="outlined"
                                            value={lexInputField.field}
                                            onChange={(event) =>
                                                handleChangeLexInput(lexInputField.id, event)
                                            }
                                        />
                                    </FormControl>
                                    <span>&nbsp;</span>
                                    <FormControl style={{ marginLeft: 30, marginTop: 15 }}>
                                        <TextField
                                            name="description"
                                            label="Field Desciption"
                                            variant="outlined"
                                            value={lexInputField.description}
                                            onChange={(event) =>
                                                handleChangeLexInput(lexInputField.id, event)
                                            }
                                        />
                                    </FormControl>
                                    <span>&nbsp;</span>
                                    <FormControl style={{ marginLeft: 30, marginTop: 15 }}>
                                        <div>
                                            <Checkbox
                                                name="hallucinationCheck"
                                                label="hallucinationCheck"
                                                variant="outlined"
                                                value={lexInputField.hallucinationCheck}
                                                checked={lexInputField.hallucinationCheck}
                                                onChange={(event) =>
                                                    handleHallucinationCheck(lexInputField.id, event)
                                                }
                                            />
                                            <span>Hallucination Check</span>
                                        </div>
                                    </FormControl>
                                    <span>&nbsp;</span>
                                    <IconButton
                                        disabled={inputFields.length === 1}
                                        onClick={() => handleRemoveFields(lexInputField.id)}
                                    >
                                        <Remove />
                                    </IconButton>
                                    <IconButton onClick={handleAddFields}>
                                        <Add />
                                    </IconButton>
                                </div>
                            ))}
                        </form>
                        <br></br>
                        {promptSelect === "custom" ? (<FormControl fullWidth style={{ marginTop: 15 }}>
                            <TextField
                                name="customPrompt"
                                label="Custom Prompt"
                                variant="outlined"
                                value={promptForClone}
                                multiline
                                onChange={(event) =>
                                    handleChangeLexPrompt(event)
                                }
                            />
                        </FormControl>) : (<code>{promptForClone}</code>)}
                    </Container>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloneClose} variant="contained" color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleBack} variant="contained" color="default">
                        Back
                    </Button>
                    {menuMode === "clone" ?
                        <Button onClick={handleClone} variant="contained" color="primary">
                            Clone
                        </Button> :
                        <Button onClick={handleUpdate} variant="contained" color="primary">
                            Update
                        </Button>}
                </DialogActions>
            </Dialog>

            <Dialog
                open={openDeleteTag}
                onClose={() => setOpenDeleteTag(false)}
                aria-labelledby="create-project"
                maxWidth="xs"
                fullWidth={true}
            >
                <DialogTitle id="create-project">Confirm deletetion</DialogTitle>
                <DialogContent>
                    Are you sure you want to delete {tagToDelete} for {name} ?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDeleteTag(false)} variant="contained" color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={() => {
                        setOpenDeleteTag(false);
                        axios.post('/api/projects/removeTagFromProject', {
                            projectId: props.location.state.project,
                            tag: tagToDelete,
                        }).then((response) => {
                            if (response) {
                                enqueueSnackbar(response.data, {
                                    variant: "success",
                                });
                                getStatus();
                            }
                        }).catch((err) => {
                            enqueueSnackbar(err.response.data, {
                                variant: "error",
                            });
                        });
                    }} variant="contained" color="default">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

Project.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {})(Project);
