import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import RulesSection from "./RulesSection";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "90%",
    paddingLeft: 30,
    paddingBottom: 30,
  },
}));

const Rules = (props) => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const [fields, setFields] = useState([]);
  const [rules, setRules] = useState({});

  const fetchFields = (id) => {
    axios
      .post("/api/projects/status", {
        projectId: id,
      })
      .then((res) => {
        const fields = res.data.fieldConfigs.map((item) => item.field);
        setFields(fields);
        if (res.data.rules) {
          setRules(res.data.rules);
        }
      })
      .catch((e) => {
        enqueueSnackbar(e.response.data, {
          variant: "error",
        });
      });
  };

  useEffect(() => {
    fetchFields(props.project);
  }, []);

  return (
    <div className={classes.root}>
      <h2>Set Rules</h2>
      <div>
        {rules &&
          fields.map((field, index) => (
            <Accordion key={index}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{field}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <RulesSection
                  project={props.project}
                  field={field}
                  rule={rules[field] || {}}
                  setOpenRules={props.setOpenRules}
                />
              </AccordionDetails>
            </Accordion>
          ))}
      </div>
    </div>
  );
};

export default Rules;
