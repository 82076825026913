import React, { useState, useEffect, useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Grid,
    Button,
    Box,
    CircularProgress,
} from "@material-ui/core";
import ImageZoom from 'react-image-zoom-rotate';
import { ArrowBack, ArrowForward, Flag } from "@material-ui/icons";
import axios from 'axios'
import { useSnackbar } from "notistack";


const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

function ViewProjectAnnotation(props) {
    const [image, setImage] = useState('')
    const [annotation, setAnnotation] = useState([])
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [flag, setFlag] = useState(false);
    const { state } = props.location;
    const taskList = state.taskList;

    const handleDelete = () => {
        let id = props.location.state.id;
        axios
            .post("/api/projects/deleteAnnotation", { projectId: props.location.state.project, file: id + '.json' })
            .then((res) => {
                props.history.push({
                    pathname: '/project',
                    state: {
                        project: props.location.state.project,
                        group: props.location.state.group,
                        projectType: props.location.state.projectType,
                        ocr: props.location.state.ocr,
                    }
                })
            })
            .catch((e) => {
                enqueueSnackbar(e.response.data, {
                    variant: "error",
                });
            });
    }

    const loadData = () => {
        setLoading(true);
        let id = props.location.state.id;
        axios
            .post("/api/projects/getSingleAnnotation", { projectId: props.location.state.project, id: id })
            .then((res) => {
                setImage(res.data.img)
                setAnnotation(res.data.annotation)
                setLoading(false);
                if (res.data.flags && res.data.flags[id]) {
                    setFlag(res.data.flags[id]);
                } else {
                    setFlag(false);
                }
            })
            .catch((e) => {
                enqueueSnackbar(e.response.data, {
                    variant: "error",
                });
                setLoading(false);
            });
    }
    const handleEdit = () => {
        props.history.push({
            pathname: '/annotate',
            state: {
                ...state,
                annotation: annotation,
                project: props.location.state.project,
                id: props.location.state.id,
                type: 'edit'
            }
        })
    }

    const handlePrevious = () => {
        const currentIndex = taskList.indexOf(state.id);
        let nextIndex = currentIndex - 1;
        if (nextIndex < 0) {
            nextIndex = taskList.length - 1;
        }
        state.id = taskList[nextIndex];
        loadData();
    };

    const handleNext = () => {
        const currentIndex = taskList.indexOf(state.id);
        let nextIndex = currentIndex + 1;
        if (nextIndex > taskList.length - 1) {
            nextIndex = 0;
        }
        state.id = taskList[nextIndex];
        loadData();
    };

    const handleFlag = () => {
        axios
            .post("/api/projects/flags/flag", {
                projectId: props.location.state.project,
                imageId: props.location.state.id,
                value: !flag,
            })
            .then((res) => {
                setFlag(res.data[props.location.state.id]);
            })
            .catch((e) => {
                enqueueSnackbar('Error', {
                    variant: "error",
                });
            });
    };

    const handleKeyNavigation = useCallback((event) => {
        if (!loading) {
            if (event.keyCode === 37) {
                handlePrevious();
            }
            if (event.keyCode === 39) {
                handleNext();
            }
        }
    }, []);

    useEffect(() => {
        loadData()
        document.addEventListener("keydown", handleKeyNavigation, false);
        return () => {
            document.removeEventListener("keydown", handleKeyNavigation, false);
        };
    }, [])

    return (
        <div style={{ paddingLeft: 30 }}>
            {!loading && <h1>Image ID: {state.id}</h1>}
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    {loading ? <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress />
                    </Box> :
                        <>
                            <ImageZoom
                                rotate={(clockwise) => {
                                    return (
                                        <div>
                                            <Button onClick={clockwise} style={{ position: 'absolute', right: 0, zIndex: 1 }}>
                                                ↻
                                            </Button>
                                        </div>
                                    );
                                }}
                                src={`data:image/png;base64,${image}`}
                                width={650}
                                zoomWidth={500}
                            />
                        </>}
                </Grid>
                <Grid item xs={6}>
                    <TableContainer>
                        <Table aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Labels</StyledTableCell>
                                    <StyledTableCell align="center">Value</StyledTableCell>
                                    <StyledTableCell align="center">Start Index</StyledTableCell>
                                    <StyledTableCell align="center">End Index</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {annotation.length === 0 ? '' : annotation.map((row) => (
                                    <StyledTableRow key={row.name}>
                                        <StyledTableCell component="th" scope="row">
                                            {row.label}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">{row.value}</StyledTableCell>
                                        <StyledTableCell align="center">{row.start}</StyledTableCell>
                                        <StyledTableCell align="center">{row.end}</StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br></br>
                    <Button
                        onClick={() => handleEdit()}
                        variant="contained"
                        color="primary"
                        disabled={loading}
                    >
                        Reannotate
                    </Button>{" "}<Button
                        onClick={() => handleDelete()}
                        variant="contained"
                        color="secondary"
                        disabled={loading}
                    >
                        Delete
                    </Button>{" "}
                    <Button
                        onClick={handlePrevious}
                        variant="contained"
                        color="black"
                        disabled={loading}
                        startIcon={<ArrowBack />}
                    >
                        Previous
                    </Button>{" "}
                    <Button
                        onClick={handleNext}
                        variant="contained"
                        color="black"
                        disabled={loading}
                        endIcon={<ArrowForward />}
                    >
                        Next
                    </Button>{" "}
                    <Button
                        onClick={handleFlag}
                        variant="contained"
                        color="secondary"
                        disabled={loading}
                        endIcon={<Flag />}
                    >
                        {flag ? "Unflag" : "Flag"}
                    </Button>
                    <br></br>
                    <br></br>
                </Grid>
            </Grid>
        </div>
    )

}

export default ViewProjectAnnotation