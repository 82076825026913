/* eslint-disable import/no-anonymous-default-export */
import { SAVE_PAGE } from "../actions/types";
const initialState = {
    pageNumber: 0,
    pageSize: 10,
};
export default function (state = initialState, action) {
    switch (action.type) {
        case SAVE_PAGE:
            return {
                ...state,
                pageNumber: action.payload.pageNumber,
                pageSize: action.payload.pageSize,
            };
        default:
            return state;
    }
}