import React from 'react';
import axios from 'axios';
import { useSnackbar } from "notistack";
import { Dialog, DialogActions, DialogContent, Button, TextField } from '@material-ui/core';

const DefaultModel = (props) => {
    const { modelNameOpen, setModelNameOpen, handleModelNameClose, modelName, handleModelName, project } = props;
    const { enqueueSnackbar } = useSnackbar();

    const saveModelName = async () => {
        try {
            await axios.post('api/projects/setDefaultModel', {
                modelName,
                projectId: project,
            });
            enqueueSnackbar("Default model updated", {
                variant: "success",
            });
            setModelNameOpen(false);
        } catch (err) {
            enqueueSnackbar('Error setting default model', {
                variant: "error",
            });
        }
    }
    return (
        <Dialog
            open={modelNameOpen}
            onClose={handleModelNameClose}
            aria-labelledby="config"
        >
            <DialogContent>
                <h2>Enter model</h2>
                <TextField
                    name="modelName"
                    variant="outlined"
                    placeholder="model"
                    value={modelName}
                    onChange={(event) => handleModelName(event)}
                    label="Model Name"
                    style={{ width: 500 }}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={saveModelName}
                    variant="contained"
                    color="primary"
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DefaultModel