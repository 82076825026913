import React, { useRef, useState } from "react";
import axios from "axios";
import { useSnackbar } from "notistack";
import { Dialog } from "@material-ui/core";
import useIntersectionObserver from "../../hooks/use-intersection-observer";

const ImageContainer = props => {
    const { enqueueSnackbar } = useSnackbar();
    const [image, setImage] = useState('')
    const [open, setOpen] = useState(false)
    const ref = useRef();

    useIntersectionObserver({
        target: ref,
        onIntersect: ([{ isIntersecting }], observerElement) => {
            if (isIntersecting) {
                viewImage()
                observerElement.unobserve(ref.current);
            }
        }
    });

    async function viewImage() {
        try {
            let url = '/api/classification/images/view';
            if (props.category === 'project') {
                url = '/api/projects/images/view';
            }
            const response = await axios.post(url, { key: props.src });
            if (response && response.data) setImage(response.data);
        } catch (e) {
            enqueueSnackbar('Error loading image', {
                variant: "error",
            });
        }
    }

    function handleClose() {
        setOpen(false)
    }

    return (
        <div ref={ref}>
            {image === '' ?
                <div style={{ height: '150px', width: '250px', textAlign: 'center' }} > <img className="image" src='/loading.gif' alt='loading' /> </div>
                :
                <img className="image" src={`data:image/png;base64,${image}`} alt={props.src} style={{ maxHeight: '250px', maxWidth: '250px' }} onClick={() => setOpen(true)} />}
            <Dialog open={open} onClose={handleClose} aria-labelledby="upload">
                <img className="image" src={`data:image/png;base64,${image}`} alt={props.src} />
            </Dialog>
        </div>

    );
};

export default ImageContainer;
