import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { googleOAuth2 } from "../../actions/authActions";
import GoogleLogin from "react-google-login";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import google from "../../g_logo.jpg";
import { blue } from "@material-ui/core/colors";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    backgroundColor: "4285F4",
    width: 450,
    height: 50,
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(blue[600]),
    backgroundColor: blue[600],
    "&:hover": {
      backgroundColor: blue[800],
    },
  },
}))(Button);

const styles = {
  display: "flex",
  alignItems: "center",
  // justifyContent: 'center',
  flexDirection: "column",
  marginTop: "15%",
};

function Login(props) {
  const [errors, seterrors] = useState({});

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (props.auth.isAuthenticated) {
      props.history.push("/");
    }
    if (props.errors.emailnotfound && !props.auth.isAuthenticated) {
      enqueueSnackbar(props.errors.emailnotfound, {
        variant: 'error',
      });
      seterrors(props.errors);
    }
    console.log("Errors:", errors)
  }, [props]);
  useEffect(() => {
    seterrors({});
  }, []);

  const classes = useStyles();

  return (
    <div style={styles}>
      <img src="/logo.png" width="220" height="220" alt="logo" />
      <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
        render={(renderProps) => (
          <ColorButton
            variant="contained"
            className={classes.button}
            onClick={renderProps.onClick}
            startIcon={
              <Avatar
                variant="rounded"
                style={{ marginRight: 20 }}
                src={google}
              />
            }
          >
            Log in with Google
          </ColorButton>
        )}
        theme="dark"
        style={{ height: 200 }}
        onSuccess={props.googleOAuth2}
        onFailure={props.googleOAuth2}
        cookiePolicy={"single_host_origin"}
      />
    </div>
  );
}

Login.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { googleOAuth2 })(Login);
