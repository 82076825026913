import React, { useState } from 'react';
import axios from 'axios';
import { useSnackbar } from "notistack";
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const FileUpload = (props) => {
  const {
    type, projectId, groupId, uploadData, benchmark, ocrType, docType, getImages, setOpenUpload, classificationType,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [files, setFile] = useState([]);
  const [uploadedFile, setUploadedFile] = useState({});
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const onChange = e => {
    setFile(e.target.files);
  };

  let url = '/api/projects/upload/';

  const onSubmit = async e => {
    e.preventDefault();
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('file' + i, files[i])
    }
    formData.append('projectId', projectId);
    if (uploadData === 'benchmark') {
      url = `/api/benchmarks/upload/${benchmark}`;
      formData.append("ocrType", ocrType);
      formData.append('docType', docType);
    } else {
      formData.append('groupId', groupId);
      if (uploadData === 'classification') {
        url = '/api/classification/upload/'
        formData.append('classificationType', classificationType);
      }
    }
    try {
      const res = await axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: progressEvent => {
          setUploadPercentage(
            parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            )
          );
        }
      });

      const { fileName, filePath } = res.data;

      setUploadedFile({ fileName, filePath });

      enqueueSnackbar('Folder uploaded', {
        variant: "success",
      });
      if (res.status === 201) {
        setUploadPercentage(0);
        setOpenUpload(false);
        await getImages();
      }

    } catch (err) {
      if (err.response.status === 500) {
        enqueueSnackbar('There was a problem with the server', {
          variant: "error",
        });
      } else {
        enqueueSnackbar(err.response.data, {
          variant: "error",
        });
      }
    }
  };

  return (
    <div style={{ padding: 50 }}>
      <form onSubmit={onSubmit}>
        <div className='custom-file mb-4'>
          {type === 'folder' ? <input
            type='file'
            className='custom-file-input'
            id='customFile'
            onChange={onChange}
            directory=""
            webkitdirectory=""
          /> :
          type ==='zip' ? <input
            type='file'
            accept="application/zip"
            className='custom-file-input'
            id='customFile'
            onChange={onChange}
          /> :
            <input
              type='file'
              className='custom-file-input'
              id='customFile'
              onChange={onChange}
              multiple
            />}
        </div>
        <LinearProgressWithLabel value={uploadPercentage} />
        <input
          type='submit'
          value='Upload'
          className='btn btn-primary btn-block mt-4'
        />
      </form>
      {uploadedFile ? (
        <div className='row mt-5'>
          <div className='col-md-6 m-auto'>
            <h3 className='text-center'>{uploadedFile.fileName}</h3>
            <img style={{ width: '100%' }} src={uploadedFile.filePath} alt='' />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default FileUpload;