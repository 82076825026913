import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { GET_ERRORS, SET_CURRENT_USER, USER_LOADING } from "./types";
import axios from 'axios';

// Google Login
export const googleOAuth2 = (googleResponse) => (dispatch) => {

  let config = {
    token: googleResponse.tokenId
  }

  axios.post("/api/users/login", config)
    .then((res) => {
      console.log(res)
      // Save to localStorage
      // Set token to localStorage
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);

      // Set token to Auth header
      setAuthToken(token);

      // Decode token to get user data
      const decoded = jwt_decode(token);
      console.log(decoded)
      // Set current user
      dispatch(setCurrentUser(decoded));
    })
    .catch((e) => {
      console.log(e.response.data)
      dispatch({
        type: GET_ERRORS,
        payload: e.response.data,
      })
    });
}

// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

// User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING,
  };
};

// Log user out
export const logoutUser = () => (dispatch) => {
  // Remove token from local storage
  localStorage.removeItem("jwtToken");

  // Remove auth header for future requests
  setAuthToken(false);

  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};

