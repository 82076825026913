import React, { useEffect } from "react";
import LabelStudio from "label-studio";
import axios from "axios";

function Annotate(props) {
    useEffect(() => {
        axios
            .post("/api/benchmarks/tasks/view", {
                benchmark: props.location.state.benchmark,
                imageId: props.location.state.imageId,
                imageKey: props.location.state.imageKey,
                benchmarkType: props.location.state.benchmarkType,
                id: props.location.state.id,
            })
            .then(function (response) {
                let final = "";
                let config = '';
                let validationConfig = {};
                let { imageId, image, text } = response.data.inferenceData;
                if (props.location.state.projectType !== 'validation') {
                    config = response.data.config;
                }
                if (props.location.state.projectType !== 'extraction') {
                    validationConfig = Object.keys(response.data.validationConfig);
                }
                const colors = [
                    "orange",
                    "red",
                    "blue",
                    "green",
                    "black",
                    "yellow",
                    "orange",
                    "red",
                    "blue",
                    "green",
                    "black",
                    "yellow",
                    "blue",
                    "green",
                    "black",
                    "yellow",
                    "orange",
                    "red",
                    "blue",
                    "green",
                    "black",
                    "yellow",
                    "blue",
                    "green",
                    "black",
                    "yellow",
                    "orange",
                    "red",
                    "blue",
                    "green",
                    "black",
                    "yellow",
                    "blue",
                    "green",
                    "black",
                    "yellow",
                ];
                if (props.location.state.benchmarkType === "project") {
                    let first = `
                <View>
                    <Image name="image" value="$image" rotateControl="true" zoomControl="true" />
                    <PolygonLabels name="polygon" toName="image"
                    strokeWimageIth="3" pointSize="small"
                    opacity="0.9">
                        <Label value="Card" background="red"/>
                    </PolygonLabels>
                    <Labels name="label" toName="text">`;

                    let second = ``;
                    config.forEach((field, i) => {
                        second += `<Label value="${field}" background="${colors[i]}"/> `;
                    });

                    let third = `</Labels>
                <Text name="text" value="$text"/>
                <View style="display: grimageI;  grid-template-columns: 1fr 1fr; max-height: 300px; width: 400px">`;

                    let fourth = ``;
                    if (config.length) {
                        config.forEach((field, i) => {
                            third += `<Text name="text_${field}" value="${field}"/> 
                        <TextArea name="${field}" toName="image" placeholder="${field}" editable="true" maxSubmissions="1"/>`;
                        });
                    }

                    if (validationConfig && Object.keys(validationConfig).length) {
                        third += `<Header value="Validation" />;`
                        validationConfig.forEach((field, i) => {
                            fourth += `<Text name="text_${field}_validation" value="${field}_validation"/> 
                        <TextArea name="${field}_validation" toName="image" placeholder="${field}_validation" editable="true" maxSubmissions="1"/>`;
                        });
                    }
                    let fifth = `</View></View>`;


                    final = first + second + third + fourth + fifth;
                } else {
                    let groupPrefix = `
            <View>
            <Image name="image" value="$image" rotateControl="true" zoomControl="true" />
            <Choices name="choice" toName="image" showInLine="true">
            <Choice value="OTHER" background="yellow"/>`;

                    let groupSuffix = `</Choices></View>`;

                    let groupConfiguration = ``;
                    config.forEach((project, i) => {
                        groupConfiguration += `<Choice value="${project.name.toUpperCase()}" background="${colors[i]
                            }"/>`;
                    });
                    final = groupPrefix + groupConfiguration + groupSuffix;
                }
                image = "data:image/png;base64," + image;
                const link = document.createElement("link");
                link.href =
                    "https://unpkg.com/label-studio@1.0.1/build/static/css/main.css";
                link.rel = "stylesheet";
                document.body.appendChild(link);

                var labelStudio = new LabelStudio("label-studio", {
                    config: `<View style="display: inline-block">${final}</View>`,
                    interfaces: [
                        "panel",
                        "submit",
                        "controls",
                        "side-column",
                        "annotations:menu",
                    ],
                    user: {
                        pk: 1,
                        firstName: "HyperVerge",
                        lastName: "HyperTuring",
                    },
                    task: {
                        annotations: [],
                        id: parseInt(imageId),
                        data: {
                            image: image,
                            text: text,
                        },
                    },
                    onLabelStudioLoad: function (LS) {
                        var c = LS.annotationStore.addAnnotation({
                            userGenerate: true,
                        });
                        LS.annotationStore.selectAnnotation(c.id);
                    },
                    onSubmitAnnotation: function (ls, annotation) {
                        var raw = JSON.stringify(annotation);
                        axios
                            .post("/api/benchmarks/annotation/annotate", {
                                raw,
                                benchmark: props.location.state.benchmark,
                                imageId: props.location.state.imageId,
                                benchmarkType: props.location.state.benchmarkType,
                                id: props.location.state.id,
                                // result,
                                // validation,
                            })
                            .then((result) => {
                                props.history.goBack();
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    },
                });
                return () => {
                    document.body.removeChild(link);
                };
            })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    return <div id="label-studio"></div>;
}

export default Annotate
