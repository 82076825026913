import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";
import { Provider } from "react-redux";
import store from "./store";
import axios from "axios";
import Login from "./components/auth/Login";
import PrivateRoute from "./components/private-route/PrivateRoute";
import Dashboard from "./components/dashboard/Dashboard";
import { SnackbarProvider } from 'notistack';
import { getSelectedRegion } from "./constants";
require('dotenv').config()

const region = getSelectedRegion();
axios.defaults.baseURL = `https://${region}-turing.hyperverge.co`;

// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);

  // Decode token and get user info and exp
  const decoded = jwt_decode(token);

  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds

  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());

    // Redirect to login
    window.location.href = "./login";
  }
}
function Status({ code, children }) {
  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) staticContext.status = code;
        return children;
      }}
    />
  );
}
function Aok() {
  return (
    <Status code={200}>
      <div>
        <h1>Aok!</h1>
      </div>
    </Status>
  );
}
function App() {
  return (
    <SnackbarProvider maxSnack={4}>
      <Provider store={store}>
        <Router>
          <div className="App">
            <Route exact path="/login" component={Login} />
            <Route exact path="/healthcheck" component={Aok} />
            <Switch>
              <PrivateRoute path="/" component={Dashboard} />
            </Switch>
          </div>
        </Router>
      </Provider>
    </SnackbarProvider>
  );
}

export default App;
