import React, { useState, useEffect, useCallback } from "react";
import ImageZoom from "react-image-zoom-rotate";
import axios from "axios";
import { useSnackbar } from "notistack";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Grid,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { ArrowBack, ArrowForward, Flag } from "@material-ui/icons";
import { withStyles } from "@material-ui/core/styles";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(grey[900]),
    backgroundColor: grey[900],
    "&:hover": {
      backgroundColor: grey[900],
    },
    height: 50,
    minWidth: 100,
  },
}))(Button);

function QcView(props) {
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState(false);
  const [annotation, setAnnotation] = useState({});
  const [ocr, setOcr] = useState([]);
  const [classifier, setClassifier] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const { state } = props.location;
  const taskList = state.taskList;

  const loadData = () => {
    let taskId = props.location.state.taskId;
    setLoading(true);
    axios
      .post("/api/benchmarks/qc/view", {
        benchmark: props.location.state.benchmark,
        taskId,
      })
      .then((res) => {
        setLoading(false);
        setImage(res.data.image);
        const annotation = res.data.annotation;
        delete annotation.e2e_match_overall;
        delete annotation.ocr_match_overall;
        delete annotation.validation_match_overall;
        if (annotation.flag !== undefined) {
          setFlag(annotation.flag);
        } else {
          setFlag(false);
        }
        delete annotation.flag;
        setAnnotation(annotation);
        setOcr(res.data.ocr);
        setClassifier(res.data.classifier);
      })
      .catch((e) => {
        setLoading(false);
        enqueueSnackbar(e.response.data, {
          variant: "error",
        });
      });
  };

  const handlePrevious = () => {
    const currentIndex = taskList.indexOf(state.taskId);
    let nextIndex = currentIndex - 1;
    if (nextIndex < 0) {
      nextIndex = taskList.length - 1;
    }
    state.taskId = taskList[nextIndex];
    loadData();
  };

  const handleNext = () => {
    const currentIndex = taskList.indexOf(state.taskId);
    let nextIndex = currentIndex + 1;
    if (nextIndex > taskList.length - 1) {
      nextIndex = 0;
    }
    state.taskId = taskList[nextIndex];
    loadData();
  };

  const handleFlag = () => {
    let taskId = props.location.state.taskId;
    axios
      .post("/api/benchmarks/qc/flag", {
        benchmark: props.location.state.benchmark,
        taskId,
        value: !flag,
      })
      .then((res) => {
        loadData();
      })
      .catch((e) => {
        enqueueSnackbar(e.response.data, {
          variant: "error flagging",
        });
      });
  };

  const handleKeyNavigation = useCallback((event) => {
    if (!loading) {
      if (event.keyCode === 37) {
        handlePrevious();
      }
      if (event.keyCode === 39) {
        handleNext();
      }
    }
  }, []);

  const handleHome = () => {
    props.history.goBack();
  };

  useEffect(() => {
    loadData();
    document.addEventListener("keydown", handleKeyNavigation, false);
    return () => {
      document.removeEventListener("keydown", handleKeyNavigation, false);
    };
  }, []);

  return (
    <div style={{ paddingLeft: 10, paddingRight: 10 }}>
      <h1 style={{ fontSize: 20 }}>{state.taskId}</h1>
      <ColorButton
        onClick={handleHome}
        variant="contained"
        color="primary"
        style={{ float: "right", marginRight: "90px" }}
      >
        Home
      </ColorButton>
      <br></br>
      <br></br>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <ImageZoom
            rotate={(clockwise) => {
              return (
                <div>
                  <Button
                    onClick={clockwise}
                    style={{ position: "absolute", right: 0, zIndex: 1 }}
                  >
                    ↻
                  </Button>
                </div>
              );
            }}
            src={`data:image/png;base64,${image}`}
            width={650}
            zoomWidth={500}
          />
        </Grid>
        <Grid item xs={6}>
          {state.benchmarkType === "group" ? (
            <TableContainer>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">GT</StyledTableCell>
                    <StyledTableCell align="center">Classiifer</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell align="center">
                      {annotation.e2e_gt}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {classifier}
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          ) : <></>}
          {state.benchmarkType === "project" && state.type !== 'validation' ? (
            <TableContainer>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Field</StyledTableCell>
                    <StyledTableCell align="center">e2e_gt</StyledTableCell>
                    <StyledTableCell align="center">ocr_gt</StyledTableCell>
                    <StyledTableCell align="center">
                      model_output
                    </StyledTableCell>
                    <StyledTableCell align="center">conf</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(annotation).length === 0
                    ? ""
                    : Object.keys(annotation).map((field, i) => (
                      <StyledTableRow key={field}>
                        <StyledTableCell component="th" scope="row">
                          {field}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: !annotation[field].e2e_match && annotation[field].e2e_gt.length
                              ? "#ff4040"
                              : "",
                          }}
                        >
                          {annotation[field].e2e_gt}
                        </StyledTableCell>
                        <StyledTableCell
                          style={{
                            backgroundColor: !annotation[field].ocr_match && annotation[field].ocr_gt.length
                              ? "#ff4040"
                              : "",
                          }}
                        >
                          {annotation[field].ocr_gt}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {annotation[field].model_output}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {annotation[field].conf}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : <></>}
          {state.benchmarkType === "project" && state.type !== 'extraction' ? (
            <>
              <h1>Validation</h1>
              <TableContainer>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Field</StyledTableCell>
                      <StyledTableCell>validation_gt</StyledTableCell>
                      <StyledTableCell>validation_match</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(annotation).length === 0
                      ? ""
                      : Object.keys(annotation).map((field, i) => (
                        typeof annotation[field].validation_gt === 'string' ?
                          <StyledTableRow key={field}>
                            <StyledTableCell component="th" scope="row">
                              {field}
                            </StyledTableCell>
                            <StyledTableCell
                              style={{
                                backgroundColor: !annotation[field].validation_match
                                  ? "#ff4040"
                                  : "",
                              }}
                            >
                              {annotation[field].validation_gt}
                            </StyledTableCell>
                            <StyledTableCell>
                              {annotation[field].validation_match ? 'true' : 'false'}
                            </StyledTableCell>
                          </StyledTableRow> : ''
                      ))}
                  </TableBody>
                </Table>
              </TableContainer></>
          ) : <></>}
          <br></br>
          <br></br>
          <Button
            onClick={handlePrevious}
            variant="contained"
            color="black"
            disabled={loading}
            startIcon={<ArrowBack />}
          >
            Previous
          </Button>{" "}
          <Button
            onClick={handleNext}
            variant="contained"
            color="black"
            disabled={loading}
            endIcon={<ArrowForward />}
          >
            Next
          </Button>{" "}
          <Button
            onClick={handleFlag}
            variant="contained"
            color="secondary"
            disabled={loading}
            endIcon={<Flag />}
          >
            {flag ? "Unflag" : "Flag"}
          </Button>
        </Grid>
      </Grid>
      <div style={{ paddingLeft: 30 }}>
        <h1 style={{ fontSize: 20 }}>OCR Output</h1>
        {ocr.map(function (line, i) {
          return (
            <p style={{ margin: 0 }}>
              {line}
              <br></br>
            </p>
          );
        })}
      </div>
      <br></br>
      <br></br>
    </div>
  );
}

export default QcView;
