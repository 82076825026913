import React, { useState } from "react";
import axios from "axios";
import download from "downloadjs";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  FormControl,
  TextField,
  MenuItem,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(grey[900]),
    backgroundColor: grey[900],
    "&:hover": {
      backgroundColor: grey[900],
    },
    height: 50,
    minWidth: 100,
  },
}))(Button);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    border: 0,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const FormattedTableCell = ({value, style}) => {
  const displayValue = isNaN(value) ? '-' : value;
  return (
    <StyledTableCell align="left" style={style}>
      {displayValue}
    </StyledTableCell>
  )
};

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const ViewResult = (props) => {
  const { results, totalSamples, benchmarkType, benchmark, validationResult, type, customResult, setCustomResult } = props;
  const [conf, setConf] = useState('');
  const tempResults = results;
  delete tempResults.total;
  const confs = Object.keys(tempResults);
  let validationTotal = 0;
  let validationJson = {};
  if (benchmarkType === 'project' && validationResult !== undefined && validationResult.length) {
    validationJson = JSON.parse(validationResult);
    validationTotal = validationJson.total;
    delete validationJson.total;
  }

  const handleConf = (e) => {
    setConf(e.target.value);
    let result = results[e.target.value];
    setCustomResult(result);
  }

  return (
    <div
      style={{
        marginTop: 25,
        textAlign: "left",
        paddingLeft: 50,
        paddingRight: 50,
        paddingBottom: 50,
      }}
    >
      {type !== "validation" ? (
        <>
          <div>
            <h2>Benchmark Results</h2>
            <h5>Total Samples: {totalSamples}</h5>
          </div>
          <br></br>
          {benchmarkType !== 'group' && type !== 'validation' && <FormControl>
            <TextField
              select
              label='CONF'
              id='conf'
              margin='normal'
              value={conf}
              onChange={handleConf}
              variant='outlined'
              style={{ width: 200 }}
            >
              {confs.map((key) => (
                <MenuItem value={key}>{key.toUpperCase()}</MenuItem>
              ))}
            </TextField>
          </FormControl>}
        </>
      ) : (
        <></>
      )}
      {benchmarkType === "group" ? (
        "Result: " + results.result + " %"
      ) : type !== "validation" ? (
        <TableContainer>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell colSpan={1}></StyledTableCell>
                <StyledTableCell
                  colSpan={8}
                  align="center"
                  style={{
                    borderLeft: "1px solid white",
                    borderBottom: "1px solid white",
                  }}
                >
                  E2E
                </StyledTableCell>
                <StyledTableCell
                  colSpan={8}
                  align="center"
                  style={{
                    borderLeft: "1px solid white",
                    borderBottom: "1px solid white",
                  }}
                >
                  OCR
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell align="center">Key</StyledTableCell>
                <StyledTableCell style={{ borderLeft: "1px solid white" }}>
                  Accuracy
                </StyledTableCell>
                <StyledTableCell>Precision</StyledTableCell>
                <StyledTableCell>Correct High Confidence %</StyledTableCell>
                <StyledTableCell>Total High Confidence %</StyledTableCell>
                <StyledTableCell>Correct High Confidence</StyledTableCell>
                <StyledTableCell>Total High Confidence</StyledTableCell>
                <StyledTableCell>Total Correct</StyledTableCell>
                <StyledTableCell>Total</StyledTableCell>
                <StyledTableCell style={{ borderLeft: "1px solid white" }}>
                  Accuracy
                </StyledTableCell>
                <StyledTableCell>Precision</StyledTableCell>
                <StyledTableCell>Correct High Confidence %</StyledTableCell>
                <StyledTableCell>Total High Confidence %</StyledTableCell>
                <StyledTableCell>Correct High Confidence</StyledTableCell>
                <StyledTableCell>Total High Confidence</StyledTableCell>
                <StyledTableCell>Total Correct</StyledTableCell>
                <StyledTableCell>Total</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customResult &&
                Object.keys(customResult).map((key) => (
                  <StyledTableRow key={key}>
                    <StyledTableCell component="th" scope="row">
                      {key}
                    </StyledTableCell>
                    <FormattedTableCell value={Number(customResult[key].e2e.accuracy * 100).toFixed(2)} style={{ borderLeft: "1px solid black" }} />
                    <FormattedTableCell value={Number(customResult[key].e2e.precision * 100).toFixed(2)} />
                    <FormattedTableCell value={Number(customResult[key].e2e.correct_high_confidence_percentage * 100).toFixed(2)} />
                    <FormattedTableCell value={Number(customResult[key].e2e.total_high_confidence_percentage * 100).toFixed(2)} />
                    <FormattedTableCell value={Number(customResult[key].e2e.correct_high_confidence)} />
                    <FormattedTableCell value={Number(customResult[key].e2e.total_high_confidence)} />
                    <FormattedTableCell value={Number(customResult[key].e2e.total_correct)} />
                    <FormattedTableCell value={Number(customResult[key].e2e.total)} />
                    <FormattedTableCell value={Number(customResult[key].ocr.accuracy * 100).toFixed(2)} style={{ borderLeft: "1px solid black" }} />
                    <FormattedTableCell value={Number(customResult[key].ocr.precision * 100).toFixed(2)} />
                    <FormattedTableCell value={Number(customResult[key].ocr.correct_high_confidence_percentage * 100).toFixed(2)} />
                    <FormattedTableCell value={Number(customResult[key].ocr.total_high_confidence_percentage * 100).toFixed(2)} />
                    <FormattedTableCell value={Number(customResult[key].ocr.correct_high_confidence)} />
                    <FormattedTableCell value={Number(customResult[key].ocr.total_high_confidence)} />
                    <FormattedTableCell value={Number(customResult[key].ocr.total_correct)} />
                    <FormattedTableCell value={Number(customResult[key].ocr.total)} />
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <></>
      )}
      {type === "extraction" ? (
        <></>
      ) : (
        <>
          <br></br>
          <h2>Field Validation Result</h2>
          <h5>Total Samples: {validationTotal}</h5>
          <TableContainer>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Fields</StyledTableCell>
                  <StyledTableCell>Validation</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {validationJson &&
                  Object.keys(validationJson).map((key) => (
                    <StyledTableRow key={key}>
                      <StyledTableCell component="th" scope="row">
                        {key}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {validationJson[key] + "%"}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      <br></br>
      <br></br>
      {type !== "validation" ? (
        <ColorButton
          onClick={async () => {
            const res = await axios({
              method: "post",
              url: "/api/benchmarks/download",
              data: {
                benchmarkType,
                benchmark,
              },
              responseType: "blob",
            });
            download(res.data, `${benchmark}.zip`);
          }}
          variant="contained"
          color="primary"
        >
          Download
        </ColorButton>
      ) : (
        <></>
      )}
    </div>
  );
};
export default ViewResult;
