/* eslint-disable import/no-anonymous-default-export */
import { SAVE_FILTER } from "../actions/types";
const initialState = {
    filteredFields: {},
    filterType: '',
};
export default function (state = initialState, action) {
    switch (action.type) {
        case SAVE_FILTER:
            return {
                ...state,
                filteredFields: action.payload.filteredFields,
                filterType: action.payload.filterType,
            };
        default:
            return state;
    }
}