import React, { useEffect, useState } from "react";
import {
  Chip,
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  Grid,
  DialogContent,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import { ArrowBack, ImageSearch } from "@material-ui/icons";
import axios from "axios";
import { useDispatch } from 'react-redux';
import { savePage } from "../../actions/pageActions";
import { useSnackbar } from "notistack";

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(grey[900]),
    backgroundColor: grey[900],
    "&:hover": {
      backgroundColor: grey[900],
    },
    marginLeft: 30,
    marginTop: 20,
    height: 50,
  },
}))(Button);

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    marginBottom: 100,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginTop: 12,
  },
  cards: {
    marginTop: 50,
    marginLeft: 25,
  },
});
function Group(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [projects, setProjects] = useState([]);
  const [tagToDelete, setTagToDelete] = useState('');
  const [openDeleteTag, setOpenDeleteTag] = useState(false);
  const state = props.location.state;


  const handleClassification = () => {
    props.history.push({
      pathname: "/classification",
      state,
    });
  };

  const getProjects = () => {
    axios
      .post("/api/groups/status", { groupId: props.location.state.group })
      .then((data) => {
        setProjects(data.data.projects);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (props.location !== undefined) {
      getProjects();
    }
    dispatch(savePage(0, 10));
  }, []);

  const handleGoBack = () => {
    props.history.push({
      pathname: '/',
    })
  };

  const deleteTagSetter = (tag) => {
    setTagToDelete(tag);
    setOpenDeleteTag(true);
  }

  const handleClick = (projectId) => {
    props.history.push({
      pathname: "/project",
      state: {
        project: projectId,
      },
    });
  };

  const handleBenchmarkClick = (id, name, docType, projectType) => {
    props.history.push({
      pathname: '/project/benchmarks',
      state: {
        id: id,
        name: name,
        docType,
        benchmarkCategory: 'project',
      },
    });
  };

  const handleGroupBenchmarkClick = () => {
    props.history.push({
      pathname: '/group/benchmarks',
      state: {
        id: state.group,
        name: state.groupName,
        docType: state.type,
        benchmarkCategory: 'group',
      },
    });
  }

  return (
    <div>
      <div
        style={{
          fontSize: 30,
          fontWeight: "bold",
          paddingLeft: 30,
          paddingBottom: 30,
        }}
      >
        Group: {props.location.state.groupName} ({props.location.state.group})
      </div>
      <div
        style={{
          fontSize: 20,
          paddingLeft: 30,
          paddingBottom: 30,
        }}>
        Tags : {props.location.state.tags.map((tag, index) => {
          return <Chip key={index} label={tag} style={{ margin: 5 }} onDelete={() => {
            deleteTagSetter(tag);
          }} />
        })}
      </div>
      <ColorButton
        startIcon={<ArrowBack />}
        onClick={handleGoBack}
        variant="contained"
        color="primary"
      >
        Go back
      </ColorButton>
      <ColorButton
        startIcon={<ImageSearch />}
        onClick={handleClassification}
        variant="contained"
        color="primary"
      >
        Classification
      </ColorButton>
      <ColorButton
        onClick={handleGroupBenchmarkClick}
        variant="contained"
        color="primary"
      >
        Group Benchmarks
      </ColorButton>
      <div
        style={{
          fontSize: 30,
          fontWeight: "bold",
          paddingLeft: 30,
          paddingTop: 30,
        }}
      >
        Existing Projects
      </div>
      <div style={{ paddingTop: 30, paddingLeft: 30 }}>
        <Grid container className={classes.cards}>
          {projects.length === 0
            ? "No Existing Projects"
            : projects.map((pro, index) => (
              <Grid container item xs={12} md={3} spacing={6} key={index}>
                <Card className={classes.root}>
                  <CardContent>
                    <Typography variant="h5" component="h2">
                      {pro.name}
                    </Typography>
                    <Typography className={classes.pos} color="textSecondary">
                      Status: {pro.status.toUpperCase().replace("_", " ")}
                    </Typography>
                    <Typography className={classes.pos} color="textSecondary">
                      Id: {pro._id}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="large"
                      onClick={() =>
                        handleClick(pro._id)
                      }
                    >
                      Open
                    </Button>
                    <Button
                      size='large'
                      onClick={() => handleBenchmarkClick(pro._id, pro.name, state.type, pro.projectType)}
                    >
                      Open Benchmarks
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
        </Grid>
      </div>

      <Dialog
        open={openDeleteTag}
        onClose={() => setOpenDeleteTag(false)}
        aria-labelledby="create-project"
        maxWidth="xs"
        fullWidth={true}
      >
        <DialogTitle id="create-project">Confirm deletetion</DialogTitle>
        <DialogContent>
          Are you sure you want to delete {tagToDelete} for {props.location.state.groupName} ?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteTag(false)} variant="contained" color="secondary">
            Cancel
          </Button>
          <Button onClick={() => {
            setOpenDeleteTag(false);
            axios.post('/api/groups/removeTagFromGroup', {
              groupId: props.location.state.group,
              tag: tagToDelete,
            }).then((response) => {
              if (response) {
                enqueueSnackbar(response.data, {
                  variant: "success",
                });
              }
            }).catch((err) => {
              enqueueSnackbar(err.response.data, {
                variant: "error",
              });
            });
          }} variant="contained" color="default">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Group;
