import React from 'react';
import axios from 'axios';
import { useSnackbar } from "notistack";
import {
    Dialog, DialogActions, DialogContent, Button, TextField, FormControl,
} from "@material-ui/core";

const PostProcessingModal = (props) => {
    const { openPostProcessing, setOpenPostProcessing, handlePostProcessingClose, postProcessingURL, handlePostProcessingURL, project } = props;
    const { enqueueSnackbar } = useSnackbar();

    const savePostProcessingURL = async () => {
        try {
            await axios.post("/api/projects/savePostProcessingURL", {
                projectId: project,
                url: postProcessingURL,
            });
            enqueueSnackbar("Post processing url saved", {
                variant: "success",
            });
            setOpenPostProcessing(false);
        } catch (err) {
            enqueueSnackbar(err.response.data, {
                variant: "error",
            });
        }
    };
    return (
        <Dialog
            open={openPostProcessing}
            onClose={handlePostProcessingClose}
            aria-labelledby="postProcessing"
            fullWidth={true}
            maxWidth="md"
        >
            <DialogContent>
                <FormControl>
                    <h2>Enter Post Processing URL</h2>
                    <TextField
                        name="postProcessing"
                        variant="outlined"
                        value={postProcessingURL}
                        onChange={(event) => handlePostProcessingURL(event)}
                        label="Post Processing URL"
                        style={{ width: 500 }}
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={savePostProcessingURL}
                    variant="contained"
                    color="primary"
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default PostProcessingModal