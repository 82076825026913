import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Card,
  CardActions,
  CardContent,
  Typography,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  Checkbox,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import axios from "axios";
import { ocrTypes } from "../../constants";
import { useSnackbar } from "notistack";
import { CopyBlock, dracula } from "react-code-blocks";
import Editor, { DiffEditor } from "@monaco-editor/react";
import { getCurrentInferenceDomain } from "../../constants";

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(grey[900]),
    backgroundColor: grey[900],
    "&:hover": {
      backgroundColor: grey[900],
    },
    marginLeft: 30,
    marginTop: 20,
    height: 50,
  },
}))(Button);

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    marginBottom: 100,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginTop: 12,
  },
  cards: {
    marginTop: 50,
    marginLeft: 25,
  },
});

function Classification(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [projects, setProjects] = useState([]);
  const [status, setStatus] = useState("");
  const [classificationType, setClassificationType] = useState("");
  const [currentClassificationType, setCurrentClassificationType] = useState("");
  const [tempClassificationType, setTempClassificationType] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [openKeywordConfig, setOpenKeywordConfig] = useState(false);
  const [openCompareKeywordConfig, setOpenCompareKeywordConfig] = useState(false);
  const [ocr, setOcr] = useState('google');
  const [tempOcr, setTempOcr] = useState('google');
  const [translate, setTranslate] = useState(false);
  const [tempTranslate, setTempTranslate] = useState(false);
  const [sorting, setSorting] = useState(false);
  const [tempSorting, setTempSorting] = useState(false);
  const [keywordClassifierConfig, setKeywordClassifierConfig] = useState("");
  const [tempKeywordClassifierConfig, setTempKeywordClassifierConfig] = useState("");

  const { state } = props.location;

  const getStatus = async () => {
    try {
      const response = await axios.post("/api/groups/status", { groupId: props.location.state.group });
      setStatus(response.data.status);
      setClassificationType(response.data.classificationType);
      setTempClassificationType(response.data.classificationType);
      setOcr(response.data.ocr);
      setTempOcr(response.data.ocr);
      setTranslate(response.data.translate);
      setTempTranslate(response.data.translate);
      setSorting(response.data.sorting);
      setTempSorting(response.data.sorting);
      setKeywordClassifierConfig(JSON.stringify(response.data.keywordClassifierConfig, null, 2));
      setTempKeywordClassifierConfig(JSON.stringify(response.data.keywordClassifierConfig, null, 2));

      const projectResponse = response.data.projects;
      projectResponse.push({ _id: 'OTHER', name: 'OTHER' })
      setProjects(projectResponse);
      if (response.data.classificationType === 'image') {
        setCurrentClassificationType('image')
      } else if (response.data.classificationType === 'keyword') {
        setCurrentClassificationType('keyword');
      } else {
        setCurrentClassificationType('text');
      }
    } catch (e) {
      enqueueSnackbar('Error fetching group status', {
        variant: "error",
      });
    };
  }

  const handleDataCreation = async () => {
    try {
      const data = {
        groupId: props.location.state.group,
        classificationType: currentClassificationType,
      }
      await axios.post("/api/classification/create", data);
      enqueueSnackbar('Database creation started', {
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbar('Error creating classification database', {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    if (props.location !== undefined) {
      getStatus();
    }
  }, []);

  const handleGoBack = () => {
    props.history.goBack();
  };

  const handleTempClassificationType = (e) => {
    setTempClassificationType(e.target.value);
  }

  const handleOcr = (e) => {
    setTempOcr(e.target.value);
  }

  const handleTranslate = (e) => {
    setTempTranslate(e.target.checked)
  }

  const handleSorting = (e) => {
    setTempSorting(e.target.checked)
  }

  const handleOpenDialog = () => {
    setOpenDialog(true);
  }

  const handleOpenKeywordConfig = () => {
    setOpenKeywordConfig(true);
  }

  const handleCloseDialog = () => {
    setTempClassificationType(classificationType);
    setTempOcr(ocr);
    setTempTranslate(translate);
    setTempSorting(sorting);
    setOpenDialog(false);
  }

  const handleCloseKeywordConfig = () => {
    setOpenKeywordConfig(false);
    setOpenCompareKeywordConfig(false);
  }

  function handleEditorChange(value, event) {
    setTempKeywordClassifierConfig(value);
  }

  const handleNext = () => {
    setOpenKeywordConfig(false);
    setOpenCompareKeywordConfig(true);
  }

  const handleBack = () => {
    setOpenCompareKeywordConfig(false);
    setOpenKeywordConfig(true);
  }

  const handleSave = async () => {
    try {
      const groupId = props.location.state.group;
      const data = {
        classificationType: tempClassificationType,
        ocr: tempOcr,
        translate: tempTranslate,
        sorting: tempSorting,
        keywordClassifierConfig: tempKeywordClassifierConfig,
        projects: projects.map(project => project._id),
      }
      await axios.put(`/api/groups/${groupId}`, data);
      setOpenDialog(false);
      setOpenCompareKeywordConfig(false);
      getStatus();
      enqueueSnackbar('Classification config updated', {
        variant: "success",
      });
    } catch (err) {
      if (err?.response?.status === 400) {
        enqueueSnackbar(err?.response?.data, {
          variant: "error",
        });
      } else {
        enqueueSnackbar('Error updating classification config', {
          variant: "error",
        });
      }
    }
  }

  const handleOpenProject = (id, name, projectType, ocr) => {
    props.history.push({
      pathname: "/classification/project",
      state: {
        project: id,
        name,
        group: props.location.state.group,
        projectType,
        ocr,
        classificationType: currentClassificationType,
      },
    });
  };

  const handleClassifierTest = () => {
    props.history.push({
      pathname: "/classification/test",
      state,
    });
  };

  const code = `curl --location --request POST 'https://${getCurrentInferenceDomain()}/v1/readClass' \\
    --header 'appid: xxxxxx' \\
    --header 'appkey: xxxxxxxxxxxxxxxxxxxx' \\
    --form 'image=@"image.png"' \\
    --form 'classification='classificationType' \\
    --form 'group="${props.location.state.group}"'`;

  return (
    <div>
      <ColorButton
        startIcon={<ArrowBackIcon />}
        onClick={handleGoBack}
        variant="contained"
        color="primary"
      >
        Go back
      </ColorButton>
      <ColorButton
        onClick={handleOpenDialog}
        variant="contained"
        color="primary"
      >
        Set Config
      </ColorButton>
      {currentClassificationType === 'keyword' ? (
        <ColorButton
          onClick={handleOpenKeywordConfig}
          variant="contained"
          color="primary"
        >
          Set Keyword Classifier Config
        </ColorButton>
      ) : ""}

      <div
        style={{
          fontSize: 20,
          fontWeight: "bold",
          paddingLeft: 30,
          paddingTop: 30,
        }}
      >
        {`Classification Type: ${currentClassificationType}`}
      </div>
      <div style={{ paddingTop: 30, paddingLeft: 30 }}>
        <Grid container className={classes.cards}>
          {projects.length === 0
            ? "No Existing Projects"
            : projects.map((pro, index) => (
              <Grid container item xs={12} md={3} spacing={6} key={index}>
                <Card className={classes.root}>
                  <CardContent>
                    <Typography variant="h5" component="h2">
                      {pro.name}
                    </Typography>
                    <Typography className={classes.pos} color="textSecondary">
                      Id: {pro._id}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="large"
                      onClick={() =>
                        handleOpenProject(pro._id, pro.name, pro.projectType, pro.ocr)
                      }
                    >
                      Open
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
        </Grid>
      </div>
      <ColorButton
        onClick={handleDataCreation}
        variant="contained"
        color="primary"
      >
        Create Database
      </ColorButton>
      {status === "creating_database" ? (
        <div style={{ color: "red", margin: '30px 0px 30px 30px' }}>Creating Database</div>
      ) : (
        ""
      )}
      {status === "database_created" ? (
        <>
          <ColorButton
            onClick={handleClassifierTest}
            variant="contained"
            color="primary"
          >
            Test Classifier
          </ColorButton>
          <br></br>
          <div style={{ textAlign: "center" }}>
            <code>
              <b>Or,</b> test the model using this curl request
            </code>
          </div>
          <div style={{ width: "70%", margin: "auto" }}>
            <CopyBlock
              text={code}
              language="javascript"
              theme={dracula}
              CopyBlock
            />
          </div>
        </>
      ) : (
        ""
      )}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="config"
      >
        <DialogContent>
          <h2>Update Classification Config</h2>
          <FormControl variant="outlined" style={{ marginTop: 20 }}>
            <InputLabel id="select-classification">Classification</InputLabel>
            <Select
              labelId="select-classification"
              id="select-classification-id"
              value={tempClassificationType}
              onChange={handleTempClassificationType}
              label="Classification"
              style={{ height: 50, width: 250 }}
            >
              <MenuItem value="text">Text</MenuItem>
              <MenuItem value="keyword">Keyword</MenuItem>
              <MenuItem value="image">Image</MenuItem>
            </Select>
          </FormControl>
          <br />
          <FormControl variant="outlined" style={{ marginTop: 20 }}>
            <InputLabel id="select-ocr">OCR Type</InputLabel>
            <Select
              labelId="select-ocr"
              id="select-ocr-id"
              value={tempOcr}
              onChange={handleOcr}
              label="OCR Type"
              style={{ height: 50, width: 150 }}
            >
              {Object.keys(ocrTypes).map((ocrType) => (
                <MenuItem value={ocrType}>{ocrTypes[ocrType]}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <br />
          <div>
            <Checkbox
              checked={tempTranslate}
              onChange={handleTranslate}
              inputProps={{ "aria-label": "primary checkbox" }}
              required
            />
            <span>Enable Translation</span>
          </div>
          <div>
            <Checkbox
              checked={tempSorting}
              onChange={handleSorting}
              inputProps={{ "aria-label": "primary checkbox" }}
              required
            />
            <span>Enable Sorting</span>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSave}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openKeywordConfig}
        onClose={handleCloseKeywordConfig}
        aria-labelledby="config"
        maxWidth="lg"
      >
        <DialogContent>
          <Editor
            height="90vh"
            width={window.innerWidth - 200}
            defaultLanguage="json"
            theme="vs-dark"
            defaultValue={tempKeywordClassifierConfig}
            onChange={handleEditorChange}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseKeywordConfig}
            variant="contained"
            color="secondary"
          >
            Close
          </Button>
          <Button
            onClick={handleNext}
            variant="contained"
            color="default"
          >
            Next
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openCompareKeywordConfig}
        onClose={handleCloseKeywordConfig}
        aria-labelledby="config"
        maxWidth="lg"
      >
        <DialogContent>
          <DiffEditor
            height="90vh"
            width={window.innerWidth - 200}
            defaultLanguage="json"
            theme="vs-dark"
            original={keywordClassifierConfig}
            modified={tempKeywordClassifierConfig}
            options={{ readOnly: true }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseKeywordConfig}
            variant="contained"
            color="secondary"
          >
            Close
          </Button>
          <Button
            onClick={handleBack}
            variant="contained"
            color="primary"
          >
            Back
          </Button>
          <Button
            onClick={handleSave}
            variant="contained"
            color="default"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Classification;
