import { useEffect, useState } from "react";
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography, TextField, Button, Select, MenuItem, Paper, Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100vh',
        padding: theme.spacing(2),
    },
    searchContainer: {
        marginBottom: theme.spacing(4),
    },
    userTile: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    userInfo: {
        display: 'flex',
        alignItems: 'center',
    },
    userDetails: {
        display: 'flex',
        flexDirection: 'column',
    },
    userId: {
        fontSize: '0.75rem',
        color: theme.palette.text.secondary,
    },
    userEmail: {
        fontWeight: 'bold',
        fontSize: '1rem',
    },
    userActions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        flex: 1,
    },
    selectRole: {
        marginRight: theme.spacing(2),
        minWidth: 150,
    },
    actionButton: {
        marginLeft: theme.spacing(2),
    },
    addUserContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(2),
    },
    addUserButton: {
        marginLeft: theme.spacing(2),
    },
}));

const availableRoles = ['annotator', 'admin', 'superAdmin'];

function Management() {
    const classes = useStyles();
    const [searchEmail, setSearchEmail] = useState('');
    const [newUserRole, setNewUserRole] = useState('annotator');
    const [users, setUsers] = useState([]);
    const [updatedUsers, setUpdatedUsers] = useState([]);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        getAllUsers();
    }, []);

    const getAllUsers = async () => {
        try {
            const response = await axios.get('/api/management/users');
            setUsers(response.data);
            setUpdatedUsers(response.data);
        } catch (error) {
            enqueueSnackbar('Failed to get all users', { variant: 'error' });
        }
    };

    const updateUserRole = async (userID, role) => {
        try {
            await axios.put(`/api/management/users/${userID}/${role}`);
            enqueueSnackbar('User role updated', { variant: 'success' });
            getAllUsers();
        } catch (error) {
            enqueueSnackbar('Failed to update user role', { variant: 'error' });
        }
    }

    const handleDeleteUser = (userID) => {
        setUserToDelete(userID);
        setDeleteDialogOpen(true);
    };

    const confirmDeleteUser = async () => {
        try {
            await axios.delete(`/api/management/users/${userToDelete}`);
            enqueueSnackbar('User deleted', { variant: 'success' });
            getAllUsers();
        } catch (error) {
            enqueueSnackbar('Failed to delete user', { variant: 'error' });
        }
        setDeleteDialogOpen(false);
        setUserToDelete(null);
        setSearchEmail('');
    };

    const handleAddUser = async () => {
        try {
            if (!searchEmail) {
                enqueueSnackbar('Please enter email', { variant: 'error' });
                return;
            }
            await axios.post(`/api/management/users`, { email: searchEmail, role: newUserRole });
            enqueueSnackbar(`User ${searchEmail} added as ${newUserRole}`, { variant: 'success' });
            setNewUserRole('');
            setSearchEmail('');
            getAllUsers();
        } catch (error) {
            enqueueSnackbar(error.response.data, { variant: 'error' });
        }
    };

    return (
        <Container className={classes.root}>
            <Typography variant="h4" gutterBottom>User Management</Typography>
            <div className={classes.searchContainer}>
                <TextField
                    label="Email"
                    variant="outlined"
                    value={searchEmail}
                    onChange={(e) => setSearchEmail(e.target.value)}
                    fullWidth
                    margin="normal"
                />
            </div>

            {searchEmail !== '' && users.filter((user) => user.user.email.includes(searchEmail)).length === 0 ? (
                <div>
                    <Typography variant="h6" gutterBottom>Add <strong>{searchEmail}</strong> as <strong>{newUserRole}</strong>?</Typography>
                    <Box className={classes.addUserContainer}>
                        <Select
                            value={newUserRole}
                            onChange={(e) => setNewUserRole(e.target.value)}
                            displayEmpty
                            variant="outlined"
                            className={classes.selectRole}
                        >
                            <MenuItem value="" disabled>Select User Role</MenuItem>
                            {availableRoles.map((role) => (
                                <MenuItem key={role} value={role}>{role}</MenuItem>
                            ))}
                        </Select>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAddUser}
                            className={classes.addUserButton}
                        >
                            Add User
                        </Button>
                    </Box>
                </div>
            ) :
                <div>

                    <Typography variant="h5" gutterBottom>All Users</Typography>
                    <Grid container spacing={2}>
                        {updatedUsers.sort((a, b) => a.user.email.localeCompare(b.user.email)).map((user) => {
                            if (searchEmail && !user.user.email.includes(searchEmail)) return null;

                            return <Grid item xs={12} key={user.user._id}>
                                <Paper className={classes.userTile}>
                                    <div className={classes.userInfo}>
                                        <div className={classes.userDetails}>
                                            <Typography className={classes.userEmail}>{user.user.email}</Typography>
                                            <Typography className={classes.userId}><strong>ID:</strong> {user.user._id}</Typography>
                                        </div>
                                    </div>
                                    <div className={classes.userActions}>
                                        <Select
                                            value={user.role}
                                            onChange={(e) => {
                                                setUpdatedUsers(updatedUsers.map((u) => {
                                                    if (u.user._id === user.user._id) {
                                                        return { user: u.user, role: e.target.value };
                                                    }
                                                    return u;
                                                }));
                                            }}
                                            displayEmpty
                                            variant="outlined"
                                            className={classes.selectRole}
                                        >
                                            <MenuItem value="" disabled>Select User Role</MenuItem>
                                            {availableRoles.map((role) => (
                                                <MenuItem key={role} value={role}>{role}</MenuItem>
                                            ))}
                                        </Select>
                                        <Button variant="contained" color="secondary" onClick={() => handleDeleteUser(user.user._id)}>
                                            Delete
                                        </Button>
                                        {user.role !== users.find(u => u.user._id === user.user._id)?.role && (
                                            <Button variant="contained" color="primary" className={classes.actionButton} onClick={() => updateUserRole(user.user._id, user.role)}>
                                                Update Role
                                            </Button>
                                        )}
                                    </div>
                                </Paper>
                            </Grid>
                        })}
                    </Grid>
                </div>}

            {/* Delete Confirmation Dialog */}
            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
            >
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete {users.find(u => u.user._id === userToDelete)?.user.email}?
                        This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={confirmDeleteUser} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

export default Management;