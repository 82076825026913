import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
    card: {
        minWidth: 170,
        margin: "auto",
        transition: "0.3s",
        boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
        "&:hover": {
            boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
        }
    },
    heading: {
        fontWeight: "bold"
    },
    subheading: {
        lineHeight: 1.8
    }
});

const StatusCard = ({ completedOcr, completedAnnotations, totalImages }) => {
    const classes = useStyles();
    return (
        <div style={{ position: 'absolute', top: '235px', right: '30px' }}>
            <Card className={classes.card}>
                <CardContent className={classes.content}>
                    <Typography
                        className={"MuiTypography--heading"}
                        variant={"h6"}
                        gutterBottom
                    >
                        Status
                    </Typography>
                    <Typography
                        className={"MuiTypography--subheading"}
                        variant={"caption"}
                    >
                        OCR: <span style={{ float: 'right' }}>{completedOcr}/{totalImages}</span>
                        <br />
                        Annotations: <span style={{ float: 'right' }}>{completedAnnotations}/{totalImages}</span>
                    </Typography>
                </CardContent>
            </Card>
        </div>
    );
}

export default StatusCard;
