import React, { useState, useEffect } from 'react';
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { useSnackbar } from "notistack";
import { withStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

const ColorButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(grey[900]),
        backgroundColor: grey[900],
        "&:hover": {
            backgroundColor: grey[900],
        },
        height: 50,
        minWidth: 100,
    },
}))(Button);

const ValidationConfig = (props) => {

    const { enqueueSnackbar } = useSnackbar();
    const [config, setConfig] = useState({});

    const handleSave = () => {
        const data = {
            config,
            projectId: props.project,
        };
        axios
            .post("/api/projects/updateConfig", data)
            .then((res) => {
                enqueueSnackbar("Config updated successfully", {
                    variant: "success",
                });
                window.location.reload();
            })
            .catch((e) => {
                enqueueSnackbar(e.response.data, {
                    variant: "error",
                });
            });
    };

    const handleChangeInput = (event) => {
        setConfig({ ...config, [event.target.name]: event.target.value });
    }

    useEffect(() => {
        setConfig(props.config);
    }, []);

    return (
        <div style={{ marginTop: 25, textAlign: 'left', paddingLeft: 50, paddingRight: 50, paddingBottom: 50 }}><h2>Edit Validation Config</h2><br></br>
            {Object.keys(config).map((key) => (
                <div>
                    <FormControl style={{ marginLeft: 30, marginTop: 15 }}>
                        <TextField
                            name="field"
                            label="Field Name"
                            variant="outlined"
                            value={key}
                            disabled
                        />
                    </FormControl>
                    <span>&nbsp;</span>
                    <FormControl style={{ marginLeft: 30, marginTop: 15 }}>
                        <TextField
                            select
                            name={key}
                            variant="outlined"
                            value={config[key]}
                            onChange={event => handleChangeInput(event)}
                            label="Validation Type"
                            style={{ width: 250 }}
                        >
                            <MenuItem value="strict">Strict</MenuItem>
                            <MenuItem value="fuzzy">Fuzzy Match</MenuItem>
                            <MenuItem value="editDistance1">Edit Distance 1</MenuItem>
                            <MenuItem value="editDistance2">Edit Distance 2</MenuItem>
                            <MenuItem value="editDistance3">Edit Distance 3</MenuItem>
                        </TextField>
                    </FormControl>
                </div>
            ))}
            <div>
                <ColorButton
                    style={{ marginTop: 50 }}
                    onClick={handleSave}
                    variant="contained"
                    color="primary"
                >
                    Save Config
                </ColorButton>
            </div>
        </div>
    );
};
export default ValidationConfig;