import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import {
    TextField,
    Button,
    Card,
    CardActions,
    CardContent,
    Typography,
    Grid,
    FormControl,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import { ocrTypes } from '../../constants';
import { useDispatch } from 'react-redux';
import { savePage } from "../../actions/pageActions";

const ColorButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(grey[900]),
        backgroundColor: grey[900],
        '&:hover': {
            backgroundColor: grey[900],
        },
        marginTop: 15,
        height: 60,
    },
}))(Button);

const useStyles = makeStyles({
    root: {
        width: 275,
        marginBottom: 100,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginTop: 12,
    },
    cards: {
        marginTop: 50,
        marginLeft: 25,
    },
});
function BenchmarkList(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [benchmarks, setBenchmarks] = useState([]);
    const [name, setName] = useState('');
    const [ocrType, setOCRType] = useState('');
    const [benchmarkFormat, setBenchmarkFormat] = useState('');
    const [openClone, setOpenClone] = useState(false);
    const [cloneName, setCloneName] = useState('');
    const [cloneId, setCloneId] = useState(props.location.state.id);
    const [cloneOCRType, setCloneOCRType] = useState('');
    const [benchmarkId, setBenchmarkId] = useState('');

    const getBenchmarks = () => {
        axios
            .get('/api/benchmarks/list', {
                params: {
                    benchmarkType: props.location.state.benchmarkCategory,
                    id: props.location.state.id,
                }
            })
            .then((data) => {
                if (data.data[0].benchmarks)
                    setBenchmarks(data.data[0].benchmarks);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    useEffect(() => {
        getBenchmarks();
        dispatch(savePage(0, 10));
    }, []);

    const handleCreate = () => {
        let format = benchmarkFormat;
        if (props.location.state.benchmarkCategory === 'group') {
            format = 'newData';
        }
        const data = {
            benchmark: {
                name,
                ocrType,
                benchmarkType: props.location.state.benchmarkCategory,
                benchmarkFormat: format,
                id: props.location.state.id,
                status: 'created',
            },
        };
        axios
            .post('/api/benchmarks/create', data)
            .then((res) => {
                enqueueSnackbar('Benchmark created successfully', {
                    variant: 'success',
                });
                setBenchmarks([]);
                setName('');
                setOCRType('');
                setBenchmarkFormat('');
                // setId('');
                getBenchmarks();
            })
            .catch((e) => {
                enqueueSnackbar('Error while creating benchmark', {
                    variant: 'error',
                });
            });
    };

    const handleName = (e) => {
        setName(e.target.value);
    };

    const handleOCRType = (e) => {
        setOCRType(e.target.value);
    };

    const handleCloneOCRType = (e) => {
        setCloneOCRType(e.target.value);
    };

    const handleBenchmarkFormat = (e) => {
        setBenchmarkFormat(e.target.value);
    };

    const handleClick = (name, benchmark, id, benchmarkType, ocrType, status, docType) => {
        props.history.push({
            pathname: '/benchmark',
            state: {
                name,
                benchmark,
                id,
                benchmarkType,
                ocrType,
                status,
                docType
            },
        });
    };

    const handleClone = (id) => {
        const benchmark = benchmarks.find((benchmark) => benchmark._id === id);
        setCloneName(benchmark.name + ' Clone');
        setCloneOCRType(benchmark.ocrType);
        setOpenClone(true);
        setBenchmarkId(id);
    }

    const handleCloseClone = () => {
        setOpenClone(false);
        setBenchmarkId('');
    }

    const handleCloneName = (e) => {
        setCloneName(e.target.value);
    }

    const handleCloneId = (e) => {
        setCloneId(e.target.value);
    }

    const handleCreateClone = () => {
        const data = {
            benchmarkId,
            name: cloneName,
            projectId: cloneId,
            ocrType: cloneOCRType,
        };
        axios
            .post('/api/benchmarks/clone', data)
            .then((res) => {
                enqueueSnackbar('Benchmark cloned successfully', {
                    variant: 'success',
                });
                setOpenClone(false);
                setBenchmarkId('');
                setCloneName('');
                setCloneId(props.location.state.id);
                getBenchmarks();
            })
            .catch((e) => {
                enqueueSnackbar('Error while cloning benchmark', {
                    variant: 'error',
                });
            });
    }

    return (
        <div>
            <div
                style={{
                    fontSize: 30,
                    fontWeight: "bold",
                    paddingLeft: 30,
                    paddingBottom: 30,
                }}
            >
                {props.location.state.benchmarkCategory.toUpperCase()}: {props.location.state.name} ({props.location.state.id})
            </div>
            <div
                style={{
                    fontSize: 30,
                    fontWeight: 'bold',
                    paddingLeft: 30,
                    paddingTop: 30,
                }}
            >
                New Benchmark
            </div>
            <div>
                <FormControl style={{ marginLeft: 30, marginTop: 15 }}>
                    <TextField
                        required
                        id='name'
                        label='Benchmark Name'
                        type='text'
                        fullWidth
                        variant='outlined'
                        onChange={handleName}
                        value={name}
                    />
                </FormControl>
                <span>&nbsp;&nbsp;</span>
                <FormControl>
                    <TextField
                        select
                        label='OCR Type'
                        id='ocr'
                        margin='normal'
                        value={ocrType}
                        onChange={handleOCRType}
                        variant='outlined'
                        style={{ width: 200 }}
                    >
                        {Object.keys(ocrTypes).map((ocrType) => (
                            <MenuItem value={ocrType}>{ocrTypes[ocrType]}</MenuItem>
                        ))}
                    </TextField>
                </FormControl>
                <span>&nbsp;&nbsp;</span>
                {props.location.state.benchmarkCategory === 'project' ? <FormControl>
                    <TextField
                        select
                        label='Benchmark Type'
                        id='benchmarkType'
                        margin='normal'
                        value={benchmarkFormat}
                        onChange={handleBenchmarkFormat}
                        variant='outlined'
                        style={{ width: 200 }}
                    >
                        <MenuItem value={'testSplit'}>Test Split</MenuItem>
                        <MenuItem value={'newData'}>New Data</MenuItem>
                    </TextField>
                </FormControl> : ''}

                <span>&nbsp;&nbsp;</span>
                <ColorButton onClick={handleCreate} variant='contained' color='primary'>
                    Create
                </ColorButton>
            </div>
            <div
                style={{
                    fontSize: 30,
                    fontWeight: 'bold',
                    paddingLeft: 30,
                    paddingTop: 30,
                }}
            >
                Your Benchmarks
            </div>
            <div style={{ paddingTop: 30, paddingLeft: 30 }}>
                <Grid container className={classes.cards}>
                    {benchmarks.length === 0
                        ? 'No Benchmarks'
                        : benchmarks.map((benchmark, index) => (
                            <Grid container item xs={12} md={3} spacing={2} key={index}>
                                <Card className={classes.root}>
                                    <CardContent>
                                        <Typography variant='h5' component='h2'>
                                            {benchmark.name}
                                        </Typography>
                                        <Typography className={classes.pos} color='textSecondary'>
                                            OCR Type: {benchmark.ocrType}
                                        </Typography>
                                        <Typography className={classes.pos} color='textSecondary'>
                                            Benchmark ID: {benchmark._id}
                                        </Typography>
                                        <Typography className={classes.pos} color='textSecondary'>
                                            Project ID: {benchmark.id} {benchmark.id === props.location.state.id ? '' : '🚩'}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            size='large'
                                            onClick={() =>
                                                handleClick(
                                                    benchmark.name,
                                                    benchmark._id,
                                                    benchmark.id,
                                                    benchmark.benchmarkType,
                                                    benchmark.ocrType,
                                                    benchmark.status,
                                                    props.location.state.docType,
                                                )
                                            }
                                        >
                                            Open
                                        </Button>
                                        <Button
                                            size='large'
                                            onClick={() =>
                                                handleClone(benchmark._id)
                                            }
                                        >
                                            Clone
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                </Grid>
            </div>
            <Dialog open={openClone} onClose={handleCloseClone} aria-labelledby="delete" fullWidth={true} >
                <DialogTitle id="clone">Clone Benchmark</DialogTitle>
                <DialogContent>
                    <FormControl>
                        <TextField
                            required
                            id='name'
                            label='Benchmark Name'
                            type='text'
                            style={{ width: '20em' }}
                            variant='outlined'
                            onChange={handleCloneName}
                            value={cloneName}
                        />
                        <br></br>
                        <TextField
                            required
                            id='name'
                            label='Project Id'
                            type='text'
                            style={{ width: '20em' }}
                            variant='outlined'
                            onChange={handleCloneId}
                            value={cloneId}
                        />
                        <br></br>
                        <TextField
                            select
                            label='OCR Type'
                            id='ocr'
                            margin='normal'
                            value={cloneOCRType}
                            onChange={handleCloneOCRType}
                            variant='outlined'
                            style={{ width: 200 }}
                        >
                            {Object.keys(ocrTypes).map((ocrType) => (
                                <MenuItem value={ocrType}>{ocrTypes[ocrType]}</MenuItem>
                            ))}
                        </TextField>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <ColorButton onClick={handleCreateClone} variant='contained' color='default' style={{ width: '10em' }}>
                        Create
                    </ColorButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default BenchmarkList;
