import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from "react-router-dom";
import axios from "axios";
import { useSnackbar } from "notistack";
import Grid from "@material-ui/core/Grid";
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { grey } from "@material-ui/core/colors";
import ImageZoom from 'react-image-zoom-rotate';
import ReactJson from 'react-json-view';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormData from "form-data";

const useStyles = makeStyles({
    table: {
        maxWidth: 500,
    },
});
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const ColorButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText(grey[900]),
        backgroundColor: grey[900],
        "&:hover": {
            backgroundColor: grey[900],
        },
        height: 50,
        minWidth: 150,
        marginTop: 20
    },
}))(Button);

function Test(props) {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [files, setFile] = useState('');
    const [apiResponse, setApiResponse] = useState({});
    const [processing, setProcessing] = useState(false)
    const [dataAvailable, setdataAvailable] = useState(false)
    const [fileURL, setFileURL] = useState('')
    const [validationConfig, setValidationConfig] = useState({});

    const projectType = props.location.state.projectType;

    function fetchConfig() {
        axios
            .post("/api/projects/fetchConfig", {
                projectId: props.location.state.project,
                groupId: props.location.state.group,
            })
            .then((res) => {
                const result = res.data;
                let config = {}
                Object.keys(result).map((data) => (
                    config[data] = ""
                ));
                setValidationConfig(config);
            })
            .catch((e) => {
                enqueueSnackbar(e?.response?.data, {
                    variant: "error",
                });
            });
    }

    const handleChangeInput = (event) => {
        setValidationConfig({ ...validationConfig, [event.target.name]: event.target.value });
    }

    useEffect(() => {
        if (props.location !== undefined) fetchConfig();
    }, []);

    const onChange = e => {
        setFile(e.target.files);
        setFileURL(URL.createObjectURL(e.target.files[0]))
    };

    const onSubmit = async e => {
        e.preventDefault();
        setProcessing(true)
        try {
            let data = new FormData();
            data.append('image', files[0])
            data.append('project', props.location.state.project);
            Object.keys(validationConfig).map((key) => (
                data.append(`validation.${key}`, validationConfig[key])
            ));
            const response = await axios.post('/api/projects/test', data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (response.data) {
                console.log(response.data)
                setApiResponse(response.data)
                setProcessing(false)
                setdataAvailable(true)
            }
        } catch (error) {
            console.log('Error onSubmit', error?.response?.data)
            setApiResponse(error);
            setProcessing(false)
            setdataAvailable(false)
            enqueueSnackbar(error?.response?.data, {
                variant: "error",
            });
        }
    };

    const { state } = props.location
    if (state === undefined) {
        return <Redirect to="/" />
    }
    return (
        <div style={{ marginLeft: 50 }}>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <h1>Test Model</h1>
                    <br></br>
                    {projectType && projectType !== 'extraction' ? (
                        <div style={{ marginTop: 25, textAlign: 'left', paddingRight: 50, paddingBottom: 50 }}><h2>Enter Validation Input</h2><br></br>
                            {Object.keys(validationConfig).map((key) => (
                                <div>
                                    <FormControl style={{ marginTop: 15 }}>
                                        <TextField
                                            name="field"
                                            label="Field Name"
                                            variant="outlined"
                                            value={key}
                                            disabled
                                        />
                                    </FormControl>
                                    <span>&nbsp;</span>
                                    <FormControl style={{ marginTop: 15 }}>
                                        <TextField
                                            name={key}
                                            variant="outlined"
                                            value={validationConfig[key]}
                                            onChange={event => handleChangeInput(event)}
                                            label={'Enter ' + key}
                                            style={{ width: 250 }}
                                        >
                                        </TextField>
                                    </FormControl>
                                </div>
                            ))}
                        </div>
                    ) : (<></>)}
                    <ImageZoom
                        rotate={(clockwise) => {
                            return (
                                <div>
                                    <Button onClick={clockwise} disabled={files.length === 0} style={{ position: 'absolute', right: 0, zIndex: 1 }}>
                                        {files.length === 0 ? '' : '↻'}
                                    </Button>
                                </div>
                            );
                        }}
                        src={fileURL}
                        width={650}
                        zoomWidth={500}
                    />
                    <br></br>
                    <div>
                        <label htmlFor='customFile'>
                            <div style={{ maxHeight: 50, borderRadius: 4, maxWidth: 146, padding: 1, textAlign: 'center', cursor: 'pointer', border: '1px solid black' }}>
                                <p style={{ fontWeight: 500, fontSize: '0.875em', marginTop: 16, marginBottom: 16 }}>CHOOSE FILE</p>
                            </div>
                        </label>
                        <input
                            type='file'
                            id='customFile'
                            onChange={onChange}
                            style={{ display: 'none' }}
                        />
                    </div>
                    <ColorButton
                        onClick={onSubmit}
                        variant="contained"
                        color="primary"
                        disabled={processing || files.length === 0}
                    >Process
                    </ColorButton><br /><br />
                    {dataAvailable && apiResponse?.result[0]?.ocr ? <h2>OCR Output</h2> : <div></div>}
                    {dataAvailable && apiResponse?.result[0]?.ocr ? <ReactJson collapsed='true' displayDataTypes='false' src={apiResponse.result[0].ocr} /> : <div></div>}
                </Grid>
                <Grid item xs={6}>
                    {processing ? (
                        <div style={{ marginTop: 50 }}><h3>Processing..</h3><CircularProgress /></div>
                    ) : dataAvailable && apiResponse?.result[0]?.details ? (
                        <div style={{ marginTop: 50, textAlign: 'left' }}><h2>Extraction Result</h2><br></br>
                            <TableContainer>
                                <Table className={classes.table} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>Key</StyledTableCell>
                                            <StyledTableCell align="left">Value</StyledTableCell>
                                            <StyledTableCell align="left">Conf</StyledTableCell>
                                            <StyledTableCell align="left"></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {apiResponse && Object.keys(apiResponse?.result[0]?.details).map(key => (<StyledTableRow key={key}>
                                            <StyledTableCell component="th" scope="row">
                                                {key}
                                            </StyledTableCell>
                                            <StyledTableCell align="left">{apiResponse?.result[0]?.details[key]['value']}</StyledTableCell>
                                            <StyledTableCell align="left">{apiResponse?.result[0]?.details[key]['conf']}</StyledTableCell>
                                            <StyledTableCell align="left">{apiResponse?.result[0]?.details[key]['toBeReviewed'] === 'yes' ? <CancelRoundedIcon color='secondary' /> : <CheckCircleRoundedIcon style={{ color: 'green' }} />}</StyledTableCell>
                                        </StyledTableRow>))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>) :
                        <div></div>
                    }
                    {processing ? (
                        <></>
                    ) : projectType && projectType !== 'extraction' && dataAvailable ? (
                        <div style={{ marginTop: 50, textAlign: 'left' }}><h2>Validation Result</h2><br></br>
                            <TableContainer>
                                <Table className={classes.table} aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>Key</StyledTableCell>
                                            <StyledTableCell align="left">Value</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {apiResponse && Object.keys(apiResponse.result[0].validation).map(key => (<StyledTableRow key={key}>
                                            <StyledTableCell component="th" scope="row">
                                                {key}
                                            </StyledTableCell>
                                            <StyledTableCell align="left">{apiResponse.result[0].validation[key]['value']}</StyledTableCell>
                                        </StyledTableRow>))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>) :
                        <div></div>
                    }
                </Grid>
            </Grid>
        </div>
    )
}

Test.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {})(Test);
