import {useState, useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import {
    AppBar,
    Tabs,
    Tab,
} from "@material-ui/core";
import Groups from "./Groups";
import Projects from "./Projects";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div>
                    {children}
                </div>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '-36px',
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));


function Home(props) {
    const classes = useStyles();
    const [value, setValue] = useState(0);

    useEffect(() => {
        const storedValue = localStorage.getItem('tabValue');
        if (storedValue !== null) {
            setValue(Number(storedValue));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('tabValue', value);
    }, [value]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static" style={{ backgroundColor: 'black' }}>
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    <Tab label="Projects" {...a11yProps(0)} />
                    <Tab label="Groups" {...a11yProps(1)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                {Projects(props)}
            </TabPanel>
            <TabPanel value={value} index={1}>
                {Groups(props)}
            </TabPanel>
        </div>
    );
}

export default Home
