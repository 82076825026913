import React, { useState } from 'react'
import {
    Grid,
    Card,
    CardContent,
    CardActions,
    Typography,
    Button,
    IconButton,
    Tooltip,
    Dialog,
    DialogActions,
    DialogContent,
} from '@material-ui/core';
import { CheckCircle, FindReplace, Delete, Flag } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from "notistack";
import axios from "axios";
import ImageContainer from "../ImageContainer";

const useStyles = makeStyles({
    root: {
        minWidth: 350,
        marginBottom: 100,
    },
    cards: {
        marginTop: 50,
        marginLeft: 25,
    }
});

const ImageCard = (props) => {
    const { image, index, handleViewAnnotation, handleAnnotate, getImages, flags, projectId } = props;
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [openDelete, setOpenDelete] = useState(false);
    const [tbDeleted, setTBDeleted] = useState('');
    const imageId = image.key.split('/')[2].split('.')[0];

    async function deleteImage() {
        try {
            const response = await axios.post("/api/projects/images/delete", {
                key: tbDeleted,
                projectId,
            });
            if (response && response.data) {
                enqueueSnackbar(response.data, {
                    variant: "success",
                });
                handleCloseDelete();
            }
        } catch (e) {
            enqueueSnackbar('Could not delete image', {
                variant: "error",
            });
        }
    }

    const handleDeleteDialog = (key) => {
        setTBDeleted(key);
        setOpenDelete(true);
    };
    const handleCloseDelete = () => {
        setTBDeleted('');
        setOpenDelete(false);
        getImages();
    };
    return (
        <>
            <Grid container item sm={12} md={3} spacing={2} key={index}>
                <Card className={classes.root}>
                    <div>
                        <CardContent>
                            <Typography variant='h5' color="textSecondary" gutterBottom>
                                Image ID: {imageId}
                                <Tooltip style={{ float: 'right' }} title="Delete">
                                    <IconButton
                                        onClick={() => handleDeleteDialog(image.key)}
                                    >
                                        <Delete />
                                    </IconButton>
                                </Tooltip>
                                {image.ocr ? <Tooltip style={{ float: 'right' }} title="OCR Ready">
                                    <IconButton>
                                        <CheckCircle style={{ color: "green" }} />
                                    </IconButton>
                                </Tooltip> : <Tooltip style={{ float: 'right' }} title="OCR Pending">
                                    <IconButton>
                                        <FindReplace style={{ color: "orange" }} />
                                    </IconButton>
                                </Tooltip>}
                            </Typography>
                            <ImageContainer
                                src={image.key}
                                category='project'
                            />
                        </CardContent>
                    </div>
                    <div>
                        <CardActions style={{ display: 'block' }}>
                            <br />
                            {image.annotation ? (
                                <Button
                                    size='large'
                                    onClick={() => handleViewAnnotation(imageId)}
                                >
                                    View Annotation
                                </Button>
                            ) : (
                                <Button
                                    size='large'
                                    onClick={() => handleAnnotate(imageId)}
                                    disabled={!image.ocr}
                                >
                                    Annotate
                                </Button>
                            )}
                            {flags[imageId] ?
                                <Flag style={{ color: "orange", float: 'right', font: '20px' }} /> : ''
                            }
                        </CardActions>
                    </div>
                </Card>
            </Grid>
            <Dialog open={openDelete} onClose={handleCloseDelete} aria-labelledby="delete">
                <DialogContent>
                    Are you sure you want to delete ?
                </DialogContent>
                <DialogActions>
                    <Button onClick={deleteImage} variant="contained" color="secondary">
                        Yes
                    </Button>
                    <Button onClick={handleCloseDelete} variant="contained" color="default">
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ImageCard