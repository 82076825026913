/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { useSnackbar } from "notistack";
import {
  Grid,
  Card,
  CardActions,
  CardContent,
  Typography,
  Button,
  Chip,
  Popover,
  Select,
  MenuItem,
} from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import { useDispatch, useSelector } from 'react-redux';
import { saveFilter } from "../../actions/filterActions";

const useStyles = makeStyles({
  root: {
    minWidth: 200,
    marginBottom: 100,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginTop: 12,
  },
  cards: {
    marginTop: 50,
    marginLeft: 25,
  },
});

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(grey[900]),
    backgroundColor: grey[900],
    "&:hover": {
      backgroundColor: grey[900],
    },
    height: 50,
    minWidth: 100,
  },
}))(Button);

function QcList(props) {

  const dispatch = useDispatch();
  const filteredFields = useSelector(
    (state) => state.filter.filteredFields
  );
  const filterType = useSelector(
    (state) => state.filter.filterType
  );

  const classes = useStyles();
  const [tasks, setTasks] = useState({});
  const [flaggedTasks, setFlaggedTasks] = useState({});
  const [validated, setValidated] = useState(false);
  const [allTasks, setAllTasks] = useState({});
  const [flagged, setFlagged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState({});
  const [matchType, setMatchType] = useState('e2e_match');
  const { enqueueSnackbar } = useSnackbar();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleFilterOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const applyFilters = (tempFields, tempMatchType) => {
    let tempTasks = allTasks;
    Object.entries(tempFields).forEach(([name, matchValue]) => {
      const filterFields = Object.entries(tempTasks).filter(([key, value]) => {
        if (matchValue === 'no_match' && ((value[name].e2e_gt.length === 0 || value[name].ocr_gt.length === 0) && value[name].model_output.length === 0))
          return false;
        if ((value[name][tempMatchType] === true && matchValue === 'match') ||
          (value[name][tempMatchType] === false && matchValue === 'no_match') ||
          matchValue === 'all')
          return true;
        return false;
      });
      tempTasks = Object.fromEntries(filterFields)
    })
    setTasks(tempTasks);
    dispatch(saveFilter(tempFields, tempMatchType));
  }
  const handleFilter = (event) => {
    dispatch(saveFilter({}, ''));
    const tempFields = fields;
    tempFields[event.target.name] = event.target.value;
    setFields(tempFields);
    applyFilters(tempFields, matchType);
    handleFilterClose();
  };

  const handleCriteria = (event) => {
    dispatch(saveFilter({}, ''));
    setMatchType(event.target.value)
    applyFilters(fields, event.target.value);
    handleFilterClose();
  };

  function getTasks() {
    setLoading(true);
    axios
      .post("/api/benchmarks/qc/list", {
        benchmark: props.location.state.benchmark,
      })
      .then((res) => {
        setTasks(res.data);
        setAllTasks(res.data);
        const keys = Object.keys(res.data);
        let tempFlaggedTasks = {};
        keys.forEach((key, i) => {
          if (res.data[key].flag) {
            tempFlaggedTasks = { ...tempFlaggedTasks, [key]: res.data[key] };
          }
          if (i === keys.length - 1) {
            setFlaggedTasks(tempFlaggedTasks);
          }
        });
        if (res.data && Object.keys(res.data).length > 0) {
          const fieldArray = Object.keys(res.data[Object.keys(res.data)[0]]);
          const fieldsObject = fieldArray.reduce((acc, curr) => (acc[curr] = 'all', acc), {});
          delete fieldsObject.e2e_match_overall;
          delete fieldsObject.ocr_match_overall;
          delete fieldsObject.validation_match_overall;
          setFields(fieldsObject);
        }
        setLoading(false);
      })
      .catch((e) => {
        enqueueSnackbar(e.response.data, {
          variant: "error",
        });
        setLoading(false);
      });
  }
  useEffect(() => {
    if (props.location !== undefined) {
      getTasks();
    }
  }, []);

  useEffect(() => {
    if (Object.keys(filteredFields).length)
      applyFilters(filteredFields, filterType);
  }, [anchorEl, loading]);

  const { state } = props.location;
  if (state === undefined) {
    return <Redirect to="/" />;
  }

  const handleGoBack = () => {
    props.history.goBack();
  };

  const handleFlagged = () => {
    if (flagged) {
      setTasks(allTasks);
    } else {
      setTasks(flaggedTasks);
    }
    setFlagged(!flagged);
  };

  const handleValidated = () => {
    if (validated) {
      setTasks(allTasks);
    } else {
      let tempTasks = tasks;
      tempTasks = Object.entries(tempTasks).filter(([key, val]) => {
        return val.validation_match_overall;
      });
      tempTasks = Object.fromEntries(tempTasks);
      setTasks(tempTasks);
    }
    setValidated(!validated);
  }

  const handleAnnotate = (id) => {
    const taskList = Object.keys(tasks);
    props.history.push({
      pathname: "/benchmark/qc/view",
      state: {
        benchmark: state.benchmark,
        benchmarkType: state.benchmarkType,
        type: state.type,
        id: state.id,
        taskId: id,
        taskList,
      },
    });
  };
  return (
    <div style={{ paddingTop: 30, marginLeft: 30 }}>
      <ColorButton
        onClick={handleGoBack}
        variant="contained"
        color="primary"
        style={{ float: "right", marginRight: "90px" }}
      >
        Go Back
      </ColorButton>
      <ColorButton
        onClick={handleFlagged}
        variant="contained"
        color="primary"
        style={{ float: "right", marginRight: "10px" }}
      >
        {flagged ? "View All" : "View Flagged"}
      </ColorButton>
      {state.benchmarkType === 'project' && state.type !== 'extraction' && <ColorButton
        onClick={handleValidated}
        variant="contained"
        color="primary"
        style={{ float: "right", marginRight: "10px" }}
      >
        {validated ? "View All" : "View Validated"}
      </ColorButton>}
      {state.benchmarkType === 'project' && state.type !== 'validation' && <ColorButton
        onClick={handleFilterOpen}
        variant="contained"
        color="primary"
        style={{ float: "right", marginRight: "10px" }}
      >
        Filters
      </ColorButton>}
      <h1>Benchmark QC</h1>
      <Grid container className={classes.cards}>
        {Object.keys(tasks).length === 0 ? (
          <h3>{loading ? "Loading..." : "No Benchmark Data"}</h3>
        ) : (
          Object.keys(tasks).map((key, index) => (
            <Grid container item xs={8} md={2} spacing={2} key={index}>
              <Card className={classes.root}>
                <CardContent>
                  <Typography variant="h5" component="h2">
                    {key}
                  </Typography>
                  {state.benchmarkType === "group" ? (
                    <Chip
                      label="result"
                      style={{
                        backgroundColor: tasks[key].result ? "green" : "red",
                      }}
                    />) : <></>}
                  {state.benchmarkType === "project" && state.type !== 'validation' ? (
                    <><span>&nbsp;&nbsp;</span>
                      <Chip
                        label="e2e"
                        color="primary"
                        style={{
                          backgroundColor: tasks[key].e2e_match_overall
                            ? "green"
                            : "red",
                        }}
                      />
                    </>
                  ) : <></>}
                  {state.benchmarkType === "project" && state.type !== 'validation' ? (
                    <>
                      <span>&nbsp;&nbsp;</span>
                      <Chip
                        label="ocr"
                        color="primary"
                        style={{
                          backgroundColor: tasks[key].ocr_match_overall
                            ? "green"
                            : "red",
                        }}
                      />
                    </>
                  ) : (
                    <></>
                  )}

                  {state.benchmarkType === "project" && state.type !== 'extraction' ? (
                    <>
                      <span>&nbsp;&nbsp;</span>
                      <Chip
                        label="validation"
                        color="primary"
                        style={{
                          backgroundColor: tasks[key].validation_match_overall
                            ? "green"
                            : "red",
                        }}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                  <span>&nbsp;&nbsp;</span>
                  {tasks[key].flag ? (
                    <Chip
                      label="flagged"
                      color="primary"
                      style={{ backgroundColor: "red" }}
                    />
                  ) : (
                    <></>
                  )}
                </CardContent>
                <CardActions>
                  <Button size="large" onClick={() => handleAnnotate(key)}>
                    Open
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))
        )}
      </Grid>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <table style={{ padding: 10 }}>
          <tr>
            <td style={{ paddingRight: 10 }}>Match Type</td>
            <td><Select
              labelId={`match-type-select-label`}
              id={`match-type-select-id`}
              value={filterType.length ? filterType : matchType}
              name='match-type'
              onChange={handleCriteria}
              style={{ width: 100 }}
            >
              <MenuItem value='e2e_match'>E2E</MenuItem>
              <MenuItem value='ocr_match'>OCR</MenuItem>
            </Select></td>
          </tr>
          {Object.keys(fields).map((key) =>
            <tr>
              <td style={{ paddingRight: 10 }}>{key}</td>
              <td><Select
                labelId={`filter-select-label-${key}`}
                id={`filter-select-id-${key}`}
                value={Object.keys(filteredFields).length ? filteredFields[key] : fields[key]}
                name={key}
                onChange={handleFilter}
                style={{ width: 100 }}
              >
                <MenuItem value='match'>Match</MenuItem>
                <MenuItem value='no_match'>No Match</MenuItem>
                <MenuItem value='all'>All</MenuItem>
              </Select></td>
            </tr>
          )}
        </table>
      </Popover>
    </div>
  );
}

export default QcList;
